import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useSearchParams } from "react-router-dom";
import Aos from "aos";

// pages
import Home from './pages/Home/Home'
import JoinAsExpert from './pages/join-as-expert/JoinAsExpert'
import JoinAsBrand from "./pages/join-as-brand/JoinAsBrand";
import JoinAsRetailer from "./pages/join-as-brand/JoinAsRetailer";
import Login from './pages/login/Login';
import AddProduct from "./pages/add-product/AddProduct";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";

import "./App.css"
import "aos/dist/aos.css";
import ManageSeller from "./pages/manage-seller/ManageSeller";
import Checkout from "./pages/join-as-brand/Checkout";
import InventoryDashboard from "./pages/add-product/InventoryDashboard";
import ThankYou from "./pages/join-as-brand/ThankYou";
import AskQuery from "./pages/join-as-brand/AskQuery";
import MasterHome from "./pages/Home/MasterHome";
import AddReview from "./pages/join-as-brand/AddReview";
import AddReviewContainer from "./pages/join-as-brand/AddReviewContainer";
import StoreLocator from "./pages/join-as-expert/StoreLocator";
import AnswerQuery from "./pages/join-as-brand/AnswerQuery";
import BrandStoreLocator from "./pages/join-as-expert/BrandStoreLocator";
import ViewReviews from "./pages/join-as-brand/ViewReviews";
import ValidatedReviews from "./pages/join-as-brand/ValidatedReviews";
import ViewStoreProducts from "./pages/join-as-brand/ViewStoreProducts";
import ViewStores from "./pages/join-as-brand/ViewStores";
import RevalidatedReviews from "./pages/join-as-brand/RevalidatedReviews";
import ModerateReviews from "./pages/join-as-brand/ModerateReviews";
import AddReview2 from "./pages/join-as-brand/AddReview2";
import SkillDiscovery from "./pages/join-as-expert/SkillDiscovery";
import StoreRoute from "./pages/join-as-brand/StoreRoute";

function ReferrerRedirect() {
  const [searchParams, setSearchParams]= useSearchParams()

  useEffect(() => {
    localStorage.setItem('rid',searchParams.get('rid'))
  },[])

    return window.location.href=searchParams.get('redirect');
}

export default ReferrerRedirect;
