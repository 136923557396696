import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { AppSearchQuery, getAllInfluencersPostsWithMentionsByEmail, getAllProductsOnUserId, getAllUniqueSearcheswithUserInfo, getAllUniqueWithFilterKeySearcheswithUserInfo, getInfluencerPosts, getPostsByTaggedStore, getProductDetails, getSingleStoreDetailsByUserIdWithOutMobileAndEmail, getSinlgeInfluencerPostsByEmail, getUniqueSearcheswithUserInfo, registerRetailer, saveOrder } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import SubmitPopover3 from "../../components/SubmitPopover/SubmitPopover3";
import styles1 from "../Home/index.module.css"
import BrandHeader from "./BrandHeader";
import StoreHeader from "./StoreHeader";
import InfluencerHeader from "./InfluencerHeader";
import ModalImage from "react-modal-image";
import {WhatsappShareButton,WhatsappIcon } from "react-share";
import { Carousel } from 'react-responsive-carousel';
import { Dropdown } from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
const ViewStoreProducts = (props) => {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [brandDetails, setBrandDetails] = useState({
    quantity:0,
    
    firstName: "",
    lastName: "",
    houseNumber:"",
    brandName: "",
    contactNum: "",
    emailId: "",
    street:"",
    ladnmark: "",
    city: "",
    state:"",
    country:"",
    pincode:""
  });
  const [error, setError] = useState({
    quantity:false,
    
    firstName: false,
    lastName:false,
    houseNumber:false,
    brandName: false,
    contactNum: false,
    emailId: false,
    street:false,
    ladnmark: false,
    city: false,
    state:false,
    country:false,
    pincode:false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product,setProduct] = useState()
  let {email,storeUserId,brandUserId,influencerId,validated,b2c}=useParams()
  const [orderLoading,setOrderLoading] = useState(false)
  const [searchKey,setSearchKey] = useState()
  const [queries,setQueries] = useState([])
  const [shopDetails,setShopDetails] = useState()
  const [posts,setPosts] =  useState([])
  const [influencerDetails,setInfluencerDetails] = useState()
  const [products,setProducts] = useState([])
  const [filteredProducts,setFilteredProducts] = useState([])
  const [models,setModels]=useState([])
  const [selectedModel,setSelectedModel] = useState()
  const loggedInUser = JSON.parse(localStorage.getItem("user-data"));

  useEffect(()=>{
    getAllProductsOnUserId(storeUserId)
      .then(res => {
        let arr= res.Data
        // console.log(res.Data)
        if(arr && arr.length>0){
          let arr1 =[]
          arr.map(i=>{
            let tagNameWithoutSize = i.prdct_attributes.brand + "-" + i.prdct_attributes.gender + "-" + i.prdct_attributes.model + "-" + i.prdct_attributes.colour // + "-" + i.prdct_attributes.size.toUpperCase()
            let incomingObjUniqueIdentifier = i.prdct_attributes.sellerSKU?
            i.prdct_attributes.brand+i.prdct_attributes.gender+i.prdct_attributes.model+i.prdct_attributes.colour+i.prdct_attributes.retail_original_price+i.prdct_attributes.sellerSKU
            :i.prdct_attributes.brand+i.prdct_attributes.gender+i.prdct_attributes.model+i.prdct_attributes.colour+i.prdct_attributes.retail_original_price
            let tempObj = null
            // console.log(i.tagName&&i.tagName.includes(tagNameWithoutSize))
            // console.log(i.tagName)
            // console.log(tagNameWithoutSize)
            if(i.prdct_attributes.tagName&&i.prdct_attributes.tagName.includes(tagNameWithoutSize)){
              tempObj=arr1[arr1.findIndex((p)=>p.uniqueIdentifier==tagNameWithoutSize)]
            }
            if(tempObj
              // &&(i.prdct_attributes.sellerSKU
              //   && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.brand)
              //   && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.gender)
              //   && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.model)
              //   && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.colour)
              //   // && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.size)
              //   && i.prdct_attributes.sellerSKU!=tempObj.sellerSKU
              //   )
                ){
              // let temp ={}
              // temp.uniqueIdentifier=i.uniqueIdentifier
              // temp.brand=i.prdct_attributes.brand,
              // tem
              // temp.colour=i.colour
              let arr2 = tempObj.sizeArray
              arr2.push({productId:i._id,size:i.prdct_attributes.size})
              // temp.sizeArray = arr2
              // temp.item = i
              // arr1[arr1.findIndex((p)=>p.uniqueIdentifier==i.uniqueIdentifier)] = temp
            }else{
              arr1.push({uniqueIdentifier:tagNameWithoutSize,
                sorter: i.prdct_attributes.brand+i.prdct_attributes.gender+i.prdct_attributes.model+i.prdct_attributes.colour,
                brand:i.prdct_attributes.brand,
                gender:i.prdct_attributes.gender,
                model:i.prdct_attributes.model,
                colour:i.prdct_attributes.colour,
                sellerSKU:i.prdct_attributes.sellerSKU,
                sizeArray:[{productId:i._id,size:i.prdct_attributes.size}],
                MRP:i.prdct_attributes.retail_original_price,
                SRP:i.prdct_attributes.retail_dsct_price,
                WholesaleCataloguePrice:i.prdct_attributes.original_price,
                WholesaleSellingPrice:i.prdct_attributes.dsct_price,
                media:i.prdct_attributes.media,
                sourceUserId:i.sourceUserId,
                sourceProductId:i.sourceProductId,
              })
                return
              if (i.prdct_attributes.sellerSKU
                && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.brand)
                && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.gender)
                && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.model)
                && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.colour)
                && !i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.size)
                //&&!i.prdct_attributes.sellerSKU.includes(i.prdct_attributes.tagName)
              ) {
                arr1.push({uniqueIdentifier:i.prdct_attributes.brand+i.prdct_attributes.gender+i.prdct_attributes.model+i.prdct_attributes.colour+i.prdct_attributes.retail_original_price+i.prdct_attributes.sellerSKU,
                  sorter: i.prdct_attributes.brand+i.prdct_attributes.gender+i.prdct_attributes.model+i.prdct_attributes.colour,
                  brand:i.prdct_attributes.brand,
                  gender:i.prdct_attributes.gender,
                  model:i.prdct_attributes.model,
                  colour:i.prdct_attributes.colour,
                  sellerSKU:i.prdct_attributes.sellerSKU,
                  sizeArray:[i.prdct_attributes.size],
                  MRP:i.prdct_attributes.retail_original_price,
                  SRP:i.prdct_attributes.retail_dsct_price,
                  WholesaleCataloguePrice:i.prdct_attributes.original_price,
                  WholesaleSellingPrice:i.prdct_attributes.dsct_price,
                  media:i.prdct_attributes.media,
                  SKU:i.prdct_attributes.sellerSKU,
                })
              }else 
              // if(i.prdct_attributes.tagName
              //   && i.prdct_attributes.tagName.includes(i.prdct_attributes.brand)
              //   && i.prdct_attributes.tagName.includes(i.prdct_attributes.gender)
              //   && i.prdct_attributes.tagName.includes(i.prdct_attributes.model)
              //   && i.prdct_attributes.tagName.includes(i.prdct_attributes.colour)
              //   && i.prdct_attributes.tagName.includes(i.prdct_attributes.size))
                {
                arr1.push({uniqueIdentifier:i.prdct_attributes.brand+i.prdct_attributes.gender+i.prdct_attributes.model+i.prdct_attributes.colour+i.prdct_attributes.retail_original_price,
                  sorter: i.prdct_attributes.brand+i.prdct_attributes.gender+i.prdct_attributes.model+i.prdct_attributes.colour,
                  brand:i.prdct_attributes.brand,
                  gender:i.prdct_attributes.gender,
                  model:i.prdct_attributes.model,
                  colour:i.prdct_attributes.colour,
                  sellerSKU:i.prdct_attributes.sellerSKU,
                  sizeArray:[i.prdct_attributes.size],
                  MRP:i.prdct_attributes.retail_original_price,
                  SRP:i.prdct_attributes.retail_dsct_price,
                  WholesaleCataloguePrice:i.prdct_attributes.original_price,
                  WholesaleSellingPrice:i.prdct_attributes.dsct_price,
                  media:i.prdct_attributes.media
                })
              }
            }
          })
          arr1.sort( (a, b) => a.sorter > b.sorter ? 1 : -1)
          // console.log(arr1)
          setProducts(arr1)
          setFilteredProducts(arr1)
          let arr3 = []
          arr3=arr1.map((item, index) => {
            return item.model
          });
          let uniqueModels = [...new Set(arr3)];//arr3.filter((value, index, array) => array.indexOf(value) === index);
          // console.log(uniqueModels)
          let arr4 = uniqueModels && uniqueModels!=[]&&uniqueModels.map((item, index)=>{
            return { key: index + 1, text: item, value: item };
          })
          if(arr4&&arr4!=[]){
            setModels(arr4)
          }
        }
      })
  },[])

  useEffect(()=>{
    if(storeUserId){
      getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId)
        .then(res => {
          if (res.Data) {
            setShopDetails(res.Data)
          }
        })
    }
  },[])

  return (
    <div className={styles.body}>
      <SubmitPopover3
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      {/* <div className={styles.header}>
        <Link to="/">
          <img
            className={styles.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
        </Link>
        <img
          className={styles.burgerMenu}
          src={isMenuOpen ? Cross : BurgerMenu}
          alt="burger-menu"
          style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
          onClick={() => setIsMenuOpen((prevState) => !prevState)}
        />
        <div
          className={styles.headerBtn + " " + styles.btn}
          style={{
            transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
          }}
          // onClick={}
        >
          <a href="#brand-form">Register Now</a>
        </div>
      </div> */}
      {loggedInUser&&storeUserId&&shopDetails&&shopDetails.user_id&&shopDetails.user_id.isBrand?<BrandHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} loggedInUser={loggedInUser} navigate={navigate}/>:
        loggedInUser&&storeUserId&&shopDetails&&shopDetails.user_id&&shopDetails.user_id?<StoreHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} loggedInUser={loggedInUser} navigate={navigate}/>:storeUserId&&shopDetails&&shopDetails.user_id&&shopDetails.user_id.isBrand?<BrandHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>:
        <StoreHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      <div className={styles.border}>
        <div></div>
      </div>
      <div className={styles.border}>
        <div></div>
      </div>
      <div style={{ maxHeight: height, overflowY: "scroll" }}>
        <div>
          {products.length == 0 ?
            <h2>Loading {shopDetails && shopDetails.name} ..</h2>
            :
            <h2>Showing {shopDetails && shopDetails.name} : {filteredProducts.length} products</h2>
          }
          <WhatsappShareButton
            url={b2c?(`${window.location.host}/viewstore/`+`${b2c}`+`/`+`${storeUserId}`):(`${window.location.host}/viewstore/`+`${storeUserId}`)}
            title={shopDetails && shopDetails.name}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
        <Dropdown
                value={selectedModel ? selectedModel : "Search Model"}
                placeholder={selectedModel ? selectedModel : "Search Model"}
                // disabled={selectedProduct?true:false}
                search
                selection
                options={models}
                onChange={(event, {value} ) => {
                  setSelectedModel(value)
                  let arr = products.filter(p=>{
                    if(p.model==value){
                      return p
                    }
                  })
                  setFilteredProducts(arr)
                }
                }
                // onSearchChange={(e) => onSearchBrand(e.target.value, "brand")}
              />
        {filteredProducts.map(product => {
          // console.log(product)
          return <div>
            {/* <Carousel> */}
            {/* <div style={{ maxHeight: height, overflowY: "scroll" }}></div> */}
            <div style={{ display:"flex", overflowX: "auto" }}>

              {product.media && product.media.length > 0 && product.media.map((i,index) => {
                return (
                  // <div>
                  <img
                    src={i.url}
                    style={{ maxHeight: height<=width?height/2:height, maxWidth: height<=width?width/2:width, }}
                  />
                  // </div>
                  // <ModalImage
                  //   small={i.url}
                  //   large={i.url}
                  //   alt={product.brand + " " + product.model}
                  // />
                )
              })}
            </div>
            {/* </Carousel> */}
            {product.brand && <h4>Brand: {product.brand}</h4>}
            {product.model && <h4>Model: {product.model}</h4>}
            {product.colour && <h4>Colour: {product.colour}</h4>}
            <div style={{ display:"flex", overflowX: "auto" }}>
            {product.sizeArray && <h4>Size: </h4>}
            {product.sizeArray && product.sizeArray.length>0 && product.sizeArray.map((i,index)=>{
              return(
                <a
                className={styles.signUpBeta + " " + styles.btn}
                target="_blank"
                href={b2c?("/checkout/" + i.productId):('/checkout/b2b/true/'+ i.productId)}
              >
                {} {i.size} {(product.sizeArray.length==(index+1))?"":" , "} 
              </a>

                // <h4>Size: {product.sizeArray.toString()}</h4>
              )})
              }
              {/* {product.sizeArray && <h4>Size: </h4>} */}
            </div>
            {/* {product.sellerSKU && <h4>SKU: {product.sellerSKU}</h4>} */}
            {b2c&&product.MRP && <h4>MRP: {product.MRP}</h4>}
            {b2c&&product.SRP && <h4>Selling: {product.SRP}</h4>}
            {!b2c&&product.SRP && <h4>SRP: {product.SRP}</h4>}
            {!b2c&&product.WholesaleCataloguePrice && <h4>Wholesale Catalogue Price: {product.WholesaleCataloguePrice}</h4>}
            {!b2c&&product.WholesaleSellingPrice && <h4>Wholesale Selling Price: {product.WholesaleSellingPrice}</h4>}
            {/* <button
            onClick={(e) => {
              e.preventDefault();
              getQuriesWithKey()
            }}
          >
            Fetch
          </button> */}
          {product.sourceUserId && product.sourceUserId!=storeUserId&&
                // <Link to={"/viewstore/" + product.sourceUserId}>
                  
                //   Explore Partner Brand
                // </Link>
                <a
                className={styles.signUpBeta + " " + styles.btn}
                target="_blank"
                href={b2c?(`/viewstore/`+`${b2c}`+`/`+`${product.sourceUserId}`)
              :(`/viewstore/`+`${product.sourceUserId}`)}
              >
                Explore Partner Brand
              </a>
                }
            
            {/* {post && <h3>Description: {post.desc && post.desc.length>0 && post.desc[0]&&post.desc[0] ? post.desc[0].value : null}</h3>} */}
            {/* {post&&(influencerId)&&<Link to={`/taginfluencerreview/${influencerId}/${post._id}`} className={styles1.btn}>
                  Tag with Post
                  </Link>}
            {post && (brandUserId) && <Link to={`/tagbrandreview/${post.mentionInfId}/${post._id}/${brandUserId}`} className={styles1.btn}>
              Tag with Post
            </Link>}
            {post && (storeUserId) && <Link to={`/tagstorereview/${post.mentionInfId}/${post._id}/${storeUserId}`} className={styles1.btn}>
              Tag with Post
            </Link>} */}
          </div>
        })}
      </div>
      <Footer />
    </div>
  );
}

export default ViewStoreProducts;