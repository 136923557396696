import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import styles1 from "../Home/index.module.css"
import { addReview, directs3upload, getAllInflncrCate, getAllProductsOnUserId, getProductDetails, getProductsOnBrandName, getSingleStoreDetailsByUserIdWithOutMobileAndEmail, registerRetailer, searchBrandNames, searchStoreOrBrand, searchTag } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import { Dropdown } from "semantic-ui-react";
import imageCompression from 'browser-image-compression';
import AddReview from "./AddReview";

const AddReviewContainer = (props) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError, setApiError] = useState('')
  const [reviewDetails, setReviewDetails] = useState({
    quantity: 0,

    firstName: "",
    lastName: "",
    houseNumber: "",
    brandName: "",
    contactNum: "",
    emailId: "",
    street: "",
    ladnmark: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    store: '',
    brand: '',
    images: [],
    videos: [],
    products: [],
    infleuncerCategories: [],
    tags: [],
    title: '',
    description: '',
    name: '',
    location: ''
  });
  const [error, setError] = useState({
    quantity: false,

    firstName: false,
    lastName: false,
    houseNumber: false,
    brandName: false,
    contactNum: false,
    emailId: false,
    street: false,
    ladnmark: false,
    city: false,
    state: false,
    country: false,
    pincode: false,
    store: false,
    brand: false,
    images: false,
    videos: false,
    products: false,
    infleuncerCategories: false,
    tags: false,
    title: false,
    description: false,
    name: false,
    location: false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product, setProduct] = useState()
  let { brandUserId, productId } = useParams()
  const [orderLoading, setOrderLoading] = useState(false)
  const [searchStores, setSearchStores] = useState([])
  const [searchTags, setSearchTags] = useState([])
  const [searchBrands, setSearchBrands] = useState([])
  const [loading, setLoading] = useState(false)
  const [productsOnBrand, setProductsOnBrand] = useState([])
  const [infleuncerCategories, setInfluencerCategories] = useState([])
  const [shopDetails,setShopDetails] = useState()



  useEffect(() => {
    // if(storeUserId){

    //   getShop(storeUserId)
    // }else 
    // if(productId){
    //   getProductInfo(productId)
    // }
    // else 
    if (brandUserId){
      getBrand(brandUserId)
    }
  }, [])
  const getShop = (storeUserId) => {
    getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId)
      .then(res => {
        if (res.status == 200) {
          handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
          // if(res.Data.user_id && res.Data.user_id.isBrand){
          //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
          // }else{

          //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
          // }
        }
      })
  }

  const getBrand = (storeUserId) => {
    getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId)
      .then(res => {
        if (res.status == 200) {
          setShopDetails(res.Data)
          handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
          getAllProductsById(res.Data.user_id._id)
          // if(res.Data.user_id && res.Data.user_id.isBrand){
          //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
          // }else{

          //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
          // }
        }
      })
  }
  const getProductInfo=()=>{
    getProductDetails(productId)
      .then(res => {
        if (res.Data) {

          //setProductsOnBrand(res.Data)
          // handleReviewDetails("products", { key: 1, text: res.Data.prdct_attributes.tagName, value: res.Data })
          // handleReviewDetails("brand", { key: 1, text: res.Data.prdct_attributes.brand, value: res.Data })
          // console.log("asdasdsdasdasdsd----------------", res.Data.prdct_attributes.brand)
          // handleReviewDetails("brand", { key: 1, text: res.Data.prdct_attributes.brand, value: res.Data.prdct_attributes.tagName })
          // getSingleStoreDetailsByUserIdWithOutMobileAndEmail(res.Data.userId._id)
          // .then(res1 => {
          //     // console.log("asdasdsdasdasdsd----------------", res1.Data.name)
          //     if (res1.Data.user_id) {
          //       handleReviewDetails("brand", { key: 1, text: res1.Data.name, value: res1.Data })
          //     }
          //   })
        }
      })
  }

  const getProductsonBrand = (name) => {
    // console.log(name)
    // return
    setLoading(true)
    getProductsOnBrandName(name)
      .then((res) => {
        setLoading(false)
        const data = res.Data;
        if (data && data.length > 0) {
          let arr = res.Data
          if (arr && arr.length > 0) {
            arr.map(i => {

              let arr2 = arr.map((item, index) => {
                // console.log(item)
                return { key: index + 1, text: item.prdct_attributes.tagName, value: item };
              });
              // setProducts([...[{key:-1,text:'Select Product',value:null}],...arr2]);
              arr2.sort((a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
              // setProducts(arr2);
              setProductsOnBrand(arr2);
              // setFilterProducts(arr2)
            })
          }
        }
      })
      .catch((err) => console.log("getProductsonBrand  err", err));
  };

  const getAllProductsById = (name) => {
    // console.log(name)
    // return
    setLoading(true)
    getAllProductsOnUserId(name)
      .then((res) => {
        setLoading(false)
        const data = res.Data;
        if (data && data.length > 0) {
          let arr = res.Data
          if (arr && arr.length > 0) {
            arr.map(i => {

              let arr2 = arr.map((item, index) => {
                // console.log(item)
                return { key: index + 1, text: item.prdct_attributes.tagName, value: item };
              });
              // setProducts([...[{key:-1,text:'Select Product',value:null}],...arr2]);
              arr2.sort((a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
              // setProducts(arr2);
              setProductsOnBrand(arr2);
              // setFilterProducts(arr2)
            })
          }
        }
      })
      .catch((err) => console.log("getProductsonBrand  err", err));
  };
  const handleReviewDetails = (name, val) => {
    setReviewDetails({
      ...reviewDetails,
      [name]: val,
    });
  };
  return (<AddReview /*product={productId}*/  brand={reviewDetails.brand} productsOnBrand={productsOnBrand} shopDetails={shopDetails}/>);
}

export default AddReviewContainer;