import { DB_URL_FLAG } from "./fetchBackend";

const APP_URL = DB_URL_FLAG
  ? "https://api.eyseapp.com/"
  : "http://localhost:9000/";

const s3accessKey = "AKIAY5GZX25EG6OLWYHK";
const s3secretKey = "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";

export {
    APP_URL,
    s3accessKey,
    s3secretKey
};