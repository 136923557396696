import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import styles1 from "../../pages/Home/index.module.css"
import { directs3upload, getAllCities, getAllCountries, getAllParentCategory, getAllStates, registerRetailer, singleStepOnboardSeller } from "../../api/ApiManager";
import { Dropdown, FormField, Form, Checkbox } from "semantic-ui-react";
import imageCompression from 'browser-image-compression';

const JoinAsRetailer = () => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [brandDetails, setBrandDetails] = useState({
    emailId: "",
    name: "",
    brandName: "",
    contactNum: "",
    products: "",
    link: "",
  });
  const [shopDetails, setshopDetails] = useState({
    companyName: "",
      storeName: "",
      businessType: "",
      GSTINnum: "",
      PANnum: "",
      sellCategory: "",
      age:"",
      pincode:"",
      //  [
      //   {
      //     "parentCategory": primaryCategory,
      //     "childCategory": childCategory
      //   }
      // ]
      year_of_establishment:"",
      area:"",
      isBrand:false,
      address: ""
      // {
      //   address: "",
      //   pinCode: "",
      //   city: "",
      //   state: "",
      //   country: "",
      //   city_id: "",
      //   country_id: "",
      //   state_id: "",
      //   location: {
      //     type: 'Point',
      //     coordinates: ["", ""],
      //   },
      // },
  });
  const [error, setError] = useState({
    emailId: false,
    name: false,
    brandName: false,
    contactNum: false,
    products: false,
    link: false,
    address: false,
    pincode: false,
    selectedCity: false,
    selectedState: false,
    selectedCountry: false,
    location: false,
    storeName: false,
    businessType: false,
    GSTINnum: false,
    PANnum: false,
    storeImages: false,
    selectedCategories: false,
    age: false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [parentCategory, setParentCategory] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [childCategory, setChildCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [selectedCountry,setSelectedCountry] = useState()
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [location,setLocation] = useState()
  const [navigationLink,setNavigationLink]=useState()
  const [searchModels, setSearchModels] = useState([]);
  const [sixMonthCheckBox, setSixMonthCheckBox] = useState(false);
  const [tenMonthCheckBox, setTenMonthCheckBox] = useState(false);
  const [memberDiscount, setMemberDiscount] = useState(false);
  const [products,setProducts] = useState([])
  const [selectedProduct,setSelectedProduct] = useState()
  const [modifyProduct,setModifyProduct] = useState(true)
  const [newProduct,setNewProduct] = useState(false)
  const [clone,setClone] = useState(false)
  const  [searchBrand,setSearchBrand] = useState()
  const [productsOnBrand,setProductsOnBrand] = useState([])
  const [filterProducts,setFilterProducts] = useState([])
  const [filterKey,setFilterKey] = useState('')
  const [loading,setLoading] = useState(false)
  const [cols,setCols] = useState()
  const [rows,setRows] = useState()
  const [storeImages,setStoreImages] = useState([])
  useEffect(()=>{
    handleLocationClick()
    getAllParentCategory()
    .then(res=>{
      // console.log(res)
      let arr =[]
      if(res.Data && res.Data.length>0){
        setParentCategories(res.Data)
        res.Data.map(parentcat=>{
          if(parentcat.childCategorys && parentcat.childCategorys.length>0){
            arr = ([...arr,...parentcat.childCategorys])
          }
        })
      }
      let arr2=[]
      arr.length>0&&arr.map((c,index)=>{
        arr2.push({ key: index + 1, text: c.childCategoryName, value: c })
      })
      setCategories(arr2)
    })
    getAllStates()
    .then(res => {
      // console.log(res.data);
      let arr= res.data
      let arr2=[]
      arr.length>0&&arr.map((c,index)=>{
        arr2.push({ key: index + 1, text: c.name, value: c })
      })
        arr2.sort( (a, b) => a.name > b.name ? 1 : -1)
      setStates(arr2);
    })

    getAllCities()
    .then(res => {
      console.log(JSON.stringify(res.data))
      let arr= res.data
      let arr2=[]
      arr.length>0&&arr.map((c,index)=>{
        arr2.push({ key: index + 1, text: c.name, value: c })
      })
        arr2.sort( (a, b) => a.name > b.name ? 1 : -1)
      setCities(arr2);
    })
    getAllCountries()
    .then(res => {
      // console.log(JSON.stringify(res.data))
      let arr= res.data
      let arr2=[]
      arr.length>0&&arr.map((c,index)=>{
        arr2.push({ key: index + 1, text: c.name, value: c })
      })
        arr2.sort( (a, b) => a.name > b.name ? 1 : -1)
      setCountries(arr2);
    })
  },[])
  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error1);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    let lt= "https://maps.google.com?q="+ latitude+","+longitude
    setLocation({lat:latitude,long:longitude})    
    setNavigationLink(lt)
  }

  function error1() {
    console.log("Unable to retrieve your location");
  }


  useEffect(() => {
    const typedH1 = new Typed(h1.current, {
      strings: ["Grow your Retail Store by connecting with a "],
      typeSpeed: 35,
      showCursor: false,
    });

    const typedAccentH1 = new Typed(accentH1.current, {
      strings: ["closed community of Brand Owners"],
      startDelay: 2000,
      typeSpeed: 35,
      showCursor: false,
    });

    return () => {
      typedH1.destroy();
      typedAccentH1.destroy();
    };
  }, []);

  useEffect(() => {
    if (width >= 834) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (brandDetails.emailId && brandDetails.name && brandDetails.contactNum && brandDetails.brandName && brandDetails.products && brandDetails.link) {
      setSubmitBtnDisabled(false);
    }
  }, [brandDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const handleBrandDetails = (name, val) => {
    setBrandDetails({
      ...brandDetails,
      [name]: val,
    });
  };

  const handleShopDetails = (name, val) => {
    setshopDetails({
      ...shopDetails,
      [name]: val,
    });
  };

  const handleEmailSubmit = (e) => {
    // e.preventDefault();

    // email validation
    console.log("asda")
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(businessEmail);

    testEmailId ? setBusinessEmailError(false) : setBusinessEmailError(true);

    if (testEmailId) {
      handleBrandDetails("emailId", businessEmail);
      window.location.replace("/dealer/join-as-retailer#brand-form");
    };
  }

  const handleSubmit = async (e,s3FileArray) => {
    e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(brandDetails.emailId);

    // name validation
    const regexName = /^[a-zA-Z ]+$/;
    const testName = brandDetails.name!=""?true:false//regexName.test(brandDetails.name);

    // brand name validation
    const testBrandName = brandDetails.brandName!=""?true:false//regexName.test(brandDetails.brandName);

    // contact number validation
    const regexContactNum = /^\d{10}$/;
    const testContactNum = regexContactNum.test(
      parseInt(brandDetails.contactNum)
    );

    //TODO: products and link validation remaining

    setError({
      emailId: !testEmailId,
      name: !testName,
      brandName: !testBrandName,
      contactNum: !testContactNum,
      address: !shopDetails.address ,
      pincode: !shopDetails.pincode,
      selectedCity: !selectedCity,
      selectedState: !selectedState,
      selectedCountry: !selectedCountry,
      // location: !location,
      storeName: !shopDetails.storeName,
      businessType: !shopDetails.businessType,
      GSTINnum: !shopDetails.GSTINnum,
      // PANnum: !shopDetails.PANnum,
      storeImages: storeImages==[]?true:false,
      selectedCategories: selectedCategories==[]?true:false,
      age: !shopDetails.age
    });
    let media1 = []

    if (!testEmailId || !testName || !testContactNum || !testBrandName
      || !shopDetails.address || !shopDetails.pincode || !selectedCity || !selectedState
      || !selectedCountry || !location || !brandDetails.brandName || !shopDetails.storeName
      || !shopDetails.businessType || !shopDetails.GSTINnum /*|| !shopDetails.PANnum*/
      || !(storeImages!=[]) || !(selectedCategories!=[]) || !shopDetails.age 
    ) {
      setShowError(true);
      setSubmitBtnDisabled(true);
    } else {
      setShowError(false);
      media1=s3FileArray//await getContentURL()
    }

    if (testEmailId && testName && testContactNum && testBrandName) {
      // final submit function here
      let body = {
        ownerName: brandDetails.name,
        email: brandDetails.emailId,
        mobile: brandDetails.contactNum,
        password: brandDetails.contactNum,
        companyName: brandDetails.brandName,
        sellCategory: brandDetails.products,
        websiteLink: brandDetails.link,
      };
      let primaryCat  = []
      console.log("selectedCategories",selectedCategories)
      console.log("categories",categories)
      selectedCategories.map(s1=>{
        parentCategories.map(parentcat=>{
          if(parentcat.childCategorys && parentcat.childCategorys.length>0){
            parentcat.childCategorys.map(s2=>{
              if(s2.childCategoryName==s1.childCategoryName){
                // if(primaryCat.findIndex(parentcat.parentCategory)==-1){
                  primaryCat.push({parentCategory:parentcat.parentCategory,childCategory:s2.childCategoryName})
                // }
              }
            })
          }
        })

        
      })
      let arr2=[]
      primaryCat.map(i1=>{
        if(arr2.findIndex(e1=>e1.parentCategory==i1.parentCategory)==-1){
          arr2.push({parentCategory:i1.parentCategory,childCategory:[]})
        }
      })
      let arr4 = []
      arr2.map(i2=>{
        let arr3=[]
        primaryCat.map(e2=>{
          if(e2.parentCategory==i2.parentCategory){
            arr3.push(e2.childCategory)
          }
        })
        arr4.push({parentCategory:i2.parentCategory,childCategory:arr3})
      })
      console.log(arr4)
      let addressDetails = {
        address: shopDetails.address,
        pinCode: shopDetails.pincode,
        city: selectedCity.name,//findArrayElementByTitle(cities, storeCity),
        state: selectedState.name,//findArrayElementByTitle(states, storeState),
        country: selectedCountry.name,//findArrayElementByTitle(countries, StoreCountry),
        city_id: selectedCity._id,
        country_id: selectedCountry._id,
        state_id: selectedState._id,
        location: {
          type: 'Point',
          coordinates: [location.lat, location.long],
        },
      }
      // return
      let apiBody = {
      registerUser: body,
      companyName: brandDetails.brandName,
      storeName: shopDetails.storeName,
      businessType: shopDetails.businessType,
      GSTINnum: shopDetails.GSTINnum,
      PANnum: shopDetails.PANnum,
      media: media1,
      sellCategory: arr4,//sellCategory,
      //  [
      //   {
      //     "parentCategory": primaryCategory,120200X 
      //     "childCategory": childCategory
      //   }
      // ]
      year_of_establishment:shopDetails.age,
      area:shopDetails.area,
      isBrand:false,
      address: addressDetails
    };
    console.log(JSON.stringify(apiBody))
    setLoading(true)
    singleStepOnboardSeller(apiBody)
      .then(res=>{
        setLoading(false)
        setShowPopover(true);
        console.log(JSON.stringify(res));
        if (res.status === 201 || res.status === 200) {
          setSubmitSuccess(true);
        } else {
          setSubmitSuccess(false);
        }
        setError({
          emailId: !testEmailId,
          contactNum: !testContactNum,
        });
        setApiError(res.message)
        setShowError(true);
      })
      setLoading(false)
    }
  };
  const getContentURL = async (e) => {
    e.preventDefault();
    setLoading(true)
    let imagesArr = [];
    let compressedImages = [];
    let videosArr = [];
    let s3Images = [];
    let s3Videos = [];
    let s3FileArray = [];
    for (let image of storeImages) {
      imagesArr.push(image);
    }
    // for (let video of brandDetails.videos) {
    //   videosArr.push(video);
    // }
    //compress images first
    await Promise.all(
      imagesArr.map(async(imageFile) =>{
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          console.log(JSON.stringify(compressedFile));
          return compressedFile
          //await uploadToServer(compressedFile); // write your own logic
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      })
      ).then((res) => {
      compressedImages.push(...res);
    });
    await Promise.all(
      compressedImages.map((element) => directs3upload(element, "IMAGE"))
    ).then((res) => {
      s3Images.push(...res);
    });
    await Promise.all(
      videosArr.map((element) => directs3upload(element, "VIDEO"))
    ).then((res) => {
      s3Videos.push(...res)
      // console.log(
      //   'video s3 array.....asdasda............ :' +
      //     JSON.stringify(s3FileArray),
      // );
      s3FileArray = [...s3Images, ...s3Videos];
      // uploadProductInfo(s3FileArray);
    });
    setLoading(false)
    handleSubmit(e,s3FileArray)
  };

  return loading?<div>Procecssing.....</div>:(
    <div className={styles.body}>
      <SubmitPopover
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      <div className={styles.header}>
        <Link to="/">
          <img
            className={styles.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
        </Link>
        <img
          className={styles.burgerMenu}
          src={isMenuOpen ? Cross : BurgerMenu}
          alt="burger-menu"
          style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
          onClick={() => setIsMenuOpen((prevState) => !prevState)}
        />
        <div
          className={styles.headerBtn + " " + styles.btn}
          style={{
            transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
          }}
          // onClick={}
        >
          <a href="#brand-form">Register Now</a>
        </div>
      </div>
      <div className={styles.sectionHead}>
        <div className={styles.pageHeadings}>
          <span className={styles.pageHeading} ref={h1}></span>{" "}
          <span
            className={styles.pageHeading + " " + styles.accent}
            ref={accentH1}
          ></span>
        </div>
        <div className={styles.emailInput}>
          <input
            type="text"
            placeholder="Enter your Business Email ID"
            style={{
              borderColor: businessEmailError ? colors.red : colors.blue,
            }}
            value={businessEmail}
            onChange={(e) => setBusinessEmail(e.target.value)}
          />
          <a
            className={styles.btn}
            // href="#brand-form"
            onClick={(e) => getContentURL(e)}
          >
            Schedule a Demo
          </a>
        </div>
      </div>
      <div className={styles.sectionInformation}>
        <div
          className={styles.info}
          //data-aos="fade-left"
          //data-aos-duration="2000"
        >
          <img
            src={require("../../assets/images/brand-info-1.png")}
            alt="information"
          />
          <div>
            <h3>
              <span className={styles.accentBlueImpt}>Collaborate</span> with
              like minded brands that share your values
            </h3>
            <p>
              <span className={styles.accentBlue}>
                Cross sell complimentary products
              </span>{" "}
              through power of collaboration and grow your network within your
              community.
            </p>
          </div>
        </div>
        <div
          className={styles.info}
          //data-aos="fade-right"
          //data-aos-duration="2000"
        >
          <img
            src={require("../../assets/images/brand-info-2.png")}
            alt="graphs"
          />
          <div>
            <h3>
              Get access to{" "}
              <span className={styles.accentBlueImpt}>analytics</span>, make
              business decisions based on data
            </h3>
            <p>
              <span className={styles.accentBlue}>Personal analytics</span> such
              as - sales, gross profits, new & repeat customers, &{" "}
              <span className={styles.accentBlue}>Market analytics</span>- such
              as trending products, top selling, area wise sales in market,
              guiding you to make better business decisions
            </p>
          </div>
        </div>
        <div
          className={styles.info}
          //data-aos="fade-left"
          //data-aos-duration="2000"
        >
          <img
            src={require("../../assets/images/brand-info-3.png")}
            alt="photos"
          />
          <div>
            <h3>
              <span className={styles.accentBlueImpt}>Content creation</span>{" "}
              and marketing for you brand by selected experts
            </h3>
            <p>
              Get{" "}
              <span className={styles.accentBlue}>
                premium video content created for selected products
              </span>{" "}
              by our social media experts. Drive traffic through testimonials.{" "}
            </p>
          </div>
        </div>
        <div
          className={styles.info}
          //data-aos="fade-right"
          //data-aos-duration="2000"
        >
          <img
            src={require("../../assets/images/brand-info-4.png")}
            alt="clothes"
          />
          <div>
            <h3>
              <span className={styles.accentBlueImpt}>
                Find dealership from brands and resell online
              </span>{" "}
              using the same app
            </h3>
            <p>
              Our integrated platform provides you with{" "}
              <span className={styles.accentBlue}>
                tools and access to both the worlds of both B2B & B2C.
              </span>{" "}
              Find potential brands and resellers using a single app & manage
              your logistics seamlessly using a single platform.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.border}>
        <div></div>
      </div>
      <div className={styles.sectionForm} id="brand-form">
        <h1>Tell us a Bit About your Retail</h1>
        <p>
          NOTE: We only onboard brands and retailers whose mission & values align with our
          own. The <span className={styles.accent}>access is exclusive</span>.
          Signing up doesn’t garantee access to the app.
        </p>
        {submitSuccess ? (
          <div className={styles.signedUp}>
            <img src={CheckCircle} alt="check-mark" />
            <h3>Thank you for signing up!</h3>
            <p>Keep an eye out for our beta launch mail!</p>
          </div>
        ) : (
          <form action="" className={styles.brandForm}>
            <div>
              <label htmlFor="emailId">Business Email ID</label>
              <input
                type="text"
                name="emailId"
                placeholder="Enter Business Email ID"
                style={{
                  borderColor: error.emailId ? colors.red : colors.blue,
                }}
                value={brandDetails.emailId}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="">Full Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                style={{ borderColor: error.name ? colors.red : colors.blue }}
                value={brandDetails.name}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="">Retail/Company Name</label>
              <input
                type="text"
                name="brandName"
                placeholder="Enter Retail/Company name"
                style={{
                  borderColor: error.brandName ? colors.red : colors.blue,
                }}
                value={brandDetails.brandName}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="">Contact Number</label>
              <input
                type="number"
                name="contactNum"
                placeholder="Enter contact number"
                value={brandDetails.contactNum}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.contactNum ? colors.red : colors.blue,
                }}
              />
            </div>
            {/* <div>
              <label htmlFor="">What do you sell?</label>
              <input
                type="text"
                name="products"
                placeholder="What do you sell?"
                value={brandDetails.products}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.products ? colors.red : colors.blue,
                }}
              />
            </div> */}
            <div>
              <label htmlFor="">Link to website or social media</label>
              <input
                type="text"
                name="link"
                placeholder="Link to website or social media"
                value={brandDetails.link}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.link ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">Store Name</label>
              <input
                type="text"
                name="storeName"
                placeholder="Store Name"
                value={shopDetails.storeName}
                onChange={(e) =>
                  handleShopDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.storeName ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">PAN</label>
              <input
                type="text"
                name="PANnum"
                placeholder="PAN"
                value={shopDetails.PANnum}
                onChange={(e) =>
                  handleShopDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.PANnum ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">GSTINnum</label>
              <input
                type="text"
                name="GSTINnum"
                placeholder="GSTINnum"
                value={shopDetails.GSTINnum}
                onChange={(e) =>
                  handleShopDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.GSTINnum ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">Address</label>
              <input
                type="text"
                name="address"
                placeholder="address"
                value={shopDetails.address}
                onChange={(e) =>
                  handleShopDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.address ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">Age(in years)</label>
              <input
                type="number"
                name="age"
                placeholder="age"
                value={shopDetails.age}
                onChange={(e) =>
                  handleShopDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.age ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">Area</label>
              <input
                type="number"
                name="area"
                placeholder="area"
                value={shopDetails.area}
                onChange={(e) =>
                  handleShopDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.link ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">Pincode</label>
              <input
                type="number"
                name="pincode"
                placeholder="pincode"
                value={shopDetails.pincode}
                onChange={(e) =>
                  handleShopDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.pincode ? colors.red : colors.blue,
                }}
              />
            </div>
            <label htmlFor="state">State</label>
            <Dropdown
              value={selectedState ? selectedState : "Select State"}
              placeholder={"Select State"}
              // disabled={selectedProduct?true:false}
              search
              selection
              options={states}
              onChange={(event, { value }) => {
                console.log(event)
                setSelectedState(value)
              }
              }
            />
            <label htmlFor="City">City</label>
            <Dropdown
              value={selectedCity ? selectedCity : "Select City"}
              placeholder={"Select City"}
              // disabled={selectedProduct?true:false}
              search
              selection
              options={cities}
              onChange={(event, { value }) => {
                console.log(event)
                setSelectedCity(value)
              }
              }
            />
            <label htmlFor="Country">Country</label>
            <Dropdown
              value={selectedCountry ? selectedCountry : "Select Country"}
              placeholder={"Select Country"}
              // disabled={selectedProduct?true:false}
              search
              selection
              options={countries}
              onChange={(event, { value }) => {
                console.log(event)
                setSelectedCountry(value)
              }
              }
            />
            <div>
          <label htmlFor="businessType">Type Of Business</label>
          <select
            name="businessType"
            value={shopDetails.businessType}
            onChange={(e) =>
              handleShopDetails(e.target.name, e.target.value)
            }
            style={{
              // borderWidth: 0,
              backgroundColor: "#0000",
            }}
          >
            <option>Choose Business Category</option>
            <option value="Public Limited Company">Public Limited Company</option>
            <option value="Private Limited Company">Private Limited Company</option>
            <option value="Joint-Venture Company">Joint-Venture Company</option>
            <option value="Partnership Firm">Partnership Firm</option>
            <option value="One Person Company">One Person Company</option>
            <option value="Sole Proprietorship">Sole Proprietorship</option>
            <option value="Branch Office">Branch Office</option>
            <option value="Non-Government Organization (NGO)">Non-Government Organization (NGO)</option>
          </select>
        </div>
        <div>
              <label htmlFor="images">Add Store Images</label>
              <input
                type="file"
                name="storeImages"
                accept="images/*"
                multiple={true}
                onChange={(e) =>
                  setStoreImages(e.target.files)
                }
              />
            </div>
        <label htmlFor="category">Category</label>
            <Dropdown
              value={selectedCategories ? selectedCategories : "Search Category"}
              placeholder={"Search Category"}
              // disabled={selectedProduct?true:false}
              search
              selection
              multiple
              options={categories}
              onChange={(event, { value }) => {
                console.log(event)
                setSelectedCategories(value)
              }
              }
            />
            <div>
              <p style={{ display: showError ? "initial" : "none" }}>
                Error detected-{apiError} Please correct and submit again
              </p>
              <button
                type="submit"
                style={{
                  backgroundColor: submitBtnDisabled ? "#677890" : "#001E46",
                  cursor: submitBtnDisabled ? "default" : "pointer",
                }}
                // disabled={submitBtnDisabled}
                onClick={(e) => getContentURL(e)}
              >
                Schedule a Demo
              </button>
            </div>
          </form>
        )}
      </div>
      <div className={styles.border}>
        <div></div>
      </div>
      <div className={styles1.sectionFive}>
      {/* <h2>Join the revolution!</h2>
          <a href="https://play.google.com/store/apps/details?id=com.eyse">
        <div className={styles.storeLogos}>

          <img
            src={require("../../assets/icons/playstore.png")}
            alt="playstore-icon"
          />
        </div>
          </a> */}
        <h2>Join the revolution!</h2>
        <a href="https://play.google.com/store/apps/details?id=com.eyse.dealer">
        <div className={styles1.storeLogos}>
        <img
            src={require("../../assets/icons/dealer-logo.jpg")}
            alt="playstore-icon"
          />
          <img
            src={require("../../assets/icons/playstore.png")}
            alt="playstore-icon"
          />
          {/* <img
            src={require("../../assets/icons/appstore.png")}
            alt="appstore-icon"
          /> */}
        </div>
        </a>
        {/* <h3>Partner with us</h3>
        <div className={styles.joinOptns}>
          <Link to="/joinasexpert" className={styles.btn}>
            Join as Expert
          </Link>
          <Link to="/joinasbrand" className={styles.btn}>
            Join as Brand
          </Link>
        </div>
        <p className={styles.signUpBeta + " " + styles.btn}>
          <a href="#sign-up-beta">Sign up for Beta</a>
        </p> */}
      </div>
      <Footer />
    </div>
  );
}

export default JoinAsRetailer;