import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aos from "aos";

// pages
import Home from './pages/Home/Home'
import JoinAsExpert from './pages/join-as-expert/JoinAsExpert'
import JoinAsBrand from "./pages/join-as-brand/JoinAsBrand";
import JoinAsRetailer from "./pages/join-as-brand/JoinAsRetailer";
import Login from './pages/login/Login';
import AddProduct from "./pages/add-product/AddProduct";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";

import "./App.css"
import "aos/dist/aos.css";
import ManageSeller from "./pages/manage-seller/ManageSeller";
import Checkout from "./pages/join-as-brand/Checkout";
import InventoryDashboard from "./pages/add-product/InventoryDashboard";
import ThankYou from "./pages/join-as-brand/ThankYou";
import AskQuery from "./pages/join-as-brand/AskQuery";
import MasterHome from "./pages/Home/MasterHome";
import AddReview from "./pages/join-as-brand/AddReview";
import AddReviewContainer from "./pages/join-as-brand/AddReviewContainer";
import StoreLocator from "./pages/join-as-expert/StoreLocator";
import AnswerQuery from "./pages/join-as-brand/AnswerQuery";
import BrandStoreLocator from "./pages/join-as-expert/BrandStoreLocator";
import ViewReviews from "./pages/join-as-brand/ViewReviews";
import ValidatedReviews from "./pages/join-as-brand/ValidatedReviews";
import ViewStoreProducts from "./pages/join-as-brand/ViewStoreProducts";
import ViewStores from "./pages/join-as-brand/ViewStores";
import RevalidatedReviews from "./pages/join-as-brand/RevalidatedReviews";
import ModerateReviews from "./pages/join-as-brand/ModerateReviews";
import AddReview2 from "./pages/join-as-brand/AddReview2";
import SkillDiscovery from "./pages/join-as-expert/SkillDiscovery";
import StoreRoute from "./pages/join-as-brand/StoreRoute";
import ReferrerRedirect from "./ReferrerRedirect";

function App() {

  useEffect(() => {
    Aos.init();
  },[])

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/refer" element={<ReferrerRedirect/>}/>
          <Route path="/joinasenduser" element={<Home />} />
          <Route path="/joinasexpert" element={<JoinAsExpert />} />
          <Route path="/skilldiscovery" element={<SkillDiscovery />} />
          <Route path="/storelocator" element={<StoreLocator />} />
          <Route path="/storelocator/:storeUserId" element={<BrandStoreLocator />} />
          <Route path="/manageseller" element={<ManageSeller />} />
          <Route path="/joinasbrand" element={<JoinAsBrand />} />
          <Route exact path="/askquery" element={<AskQuery />} />
          <Route exact path="/answerquery" element={<AnswerQuery />} />
          <Route path="/viewreviews/" element={<ViewReviews />} />
          <Route path="/validatedreviews/" element={<ValidatedReviews />} />
          <Route exact path="/checkout/:storeUserId/:productId" element={<Checkout />} />
          <Route path="/thankyou/:mentionInfId/:mentionPostId/:storeUserId/:productId" element={<ThankYou />} />
          <Route exact path="/checkout/:productId" element={<Checkout />} />
          <Route exact path="/checkout/b2b/:b2b/:productId" element={<Checkout />} />
          <Route path="/thankyou/:mentionInfId/:mentionPostId/:productId" element={<ThankYou />} />
          {/* <Route exact path="/checkout/:mentionInfId/:mentionPostId/:storeUserId" element={<Checkout />} /> */}
          <Route exact path="/addreview" element={<AddReview />} />
          <Route exact path="/addskill" element={<AddReview2 />} />
          <Route exact path="/addskill/:mentionInfId/:mentionPostId" element={<AddReview2 />} />
          <Route exact path="/addskill/:mentionInfId/:mentionPostId/:influencerId" element={<AddReview2 />} />
          <Route exact path="/addreview/:queryId" element={<AddReview />} />
          <Route exact path="/tagreview/:mentionInfId/:mentionPostId" element={<AddReview />} />
          <Route exact path="/webpost/:mentionInfId/:mentionPostId/:brandUserId" element={<StoreRoute />} />
          <Route exact path="/tagbrandpost/:mentionInfId/:mentionPostId/:brandUserId" element={<AddReviewContainer />} />
          <Route exact path="/tagstorepost/:mentionInfId/:mentionPostId/:storeUserId" element={<AddReview />} />
          <Route exact path="/brandaskquery/:brandUserId" element={<AskQuery />} />
          <Route exact path="/brandanswerquery/:brandUserId" element={<AnswerQuery />} />
          <Route exact path="/addbrandreview/:brandUserId" element={<AddReviewContainer />} />
          <Route exact path="/addbrandreview/:brandUserId/:queryId" element={<AddReviewContainer />} />
          <Route exact path="/addbrandreview/:brandUserId/:queryId/:productId" element={<AddReviewContainer />} />
          <Route exact path="/buyfrombrandreview/:mentionInfId/:mentionPostId/:storeUserId" element={<Checkout />} />
          <Route exact path="/buyfrombrandreview/:mentionInfId/:mentionPostId/:storeUserId/:productId" element={<Checkout />} />
          <Route exact path="/tagbrandreview/:mentionInfId/:mentionPostId/:brandUserId" element={<AddReviewContainer />} />
          <Route exact path="/tagbrandreview/:mentionInfId/:mentionPostId/:brandUserId/:productId" element={<AddReviewContainer />} />
          {/* <Route exact path="/addproductreview/:productId" element={<AddReviewContainer />} /> */}
          <Route path="/viewbrandreviews/:brandUserId/:email" element={<ViewReviews />} />
          <Route path="/moderatereviews" element={<ModerateReviews />} />
          <Route path="/validatedbrandreviews/:brandUserId/:email" element={<ValidatedReviews />} />
          <Route path="/brandstorelocator/:brandUserId" element={<BrandStoreLocator />} />
          <Route exact path="/storeaskquery/:storeUserId" element={<AskQuery />} />
          <Route exact path="/storeanswerquery/:storeUserId" element={<AnswerQuery />} />
          <Route exact path="/addstorereview/:storeUserId" element={<AddReview />} />
          <Route exact path="/addstorereview/:storeUserId/:queryId" element={<AddReview />} />
          <Route exact path="/addstorereview/:storeUserId/:queryId/:productId" element={<AddReview />} />
          <Route exact path="/tagstorereview/:mentionInfId/:mentionPostId/:storeUserId" element={<AddReview />} />
          <Route exact path="/tagstorereview/:mentionInfId/:mentionPostId/:storeUserId/:productId" element={<AddReview />} />
          <Route path="/viewstorereviews/:storeUserId/:email" element={<ViewReviews />} />
          <Route path="/validatedstorereviews/:storeUserId/:email" element={<ValidatedReviews />} />

          <Route exact path="/sharereview/:shareFeedback" element={<AddReview />} />
          <Route exact path="/sharereview/:shareFeedback/:isServiceProvider" element={<AddReview />} />
          <Route exact path="/sharereview/:shareFeedback/:mentionInfId/:mentionPostId" element={<AddReview />} />
          <Route exact path="/addinfluencerreview/:influencerId" element={<AddReview />} />
          
          <Route exact path="/serviceaskquery/:spId" element={<AskQuery />} />
          <Route exact path="/serviceanswerquery/:spId" element={<AnswerQuery />} />
          <Route exact path="/addservicereview/:spId" element={<AddReview />} />
          <Route exact path="/addservicereview/:spId/:queryId" element={<AddReview />} />
          <Route exact path="/tagservicereview/:mentionInfId/:mentionPostId" element={<AddReview />} />
          <Route exact path="/tagservicereview/:mentionInfId/:mentionPostId/:spId" element={<AddReview />} />
          <Route exact path="/tagservicereview/:mentionInfId/:mentionPostId/:spId/:productId" element={<AddReview />} />
          <Route path="/viewservicereviews/:spId/:email" element={<ViewReviews />} />
          <Route path="/validatedservicereviews/:spId/:email" element={<ValidatedReviews />} />
          <Route path="/revalidatedservicereviews/:revalidated/:spId/:email" element={<RevalidatedReviews />} />
          
          <Route exact path="/influenceraskquery/:influencerId" element={<AskQuery />} />
          <Route exact path="/influenceranswerquery/:influencerId" element={<AnswerQuery />} />
          <Route exact path="/addinfluencerreview/:influencerId" element={<AddReview />} />
          <Route exact path="/addinfluencerreview/:influencerId/:queryId" element={<AddReview />} />
          <Route exact path="/taginfluencerreview/:mentionInfId/:mentionPostId" element={<AddReview />} />
          <Route exact path="/taginfluencerreview/:mentionInfId/:mentionPostId/:influencerId" element={<AddReview />} />
          <Route exact path="/taginfluencerreview/:mentionInfId/:mentionPostId/:influencerId/:productId" element={<AddReview />} />
          <Route path="/viewinfluencerreviews/:influencerId/:email" element={<ViewReviews />} />
          <Route path="/validatedinfluencerreviews/:influencerId/:email" element={<ValidatedReviews />} />
          <Route path="/revalidatedinfluencerreviews/:revalidated/:influencerId/:email" element={<RevalidatedReviews />} />

          {/* <Route exact path="/answerquery/:influencerId" element={<AnswerQuery />} /> */}
          <Route path="/dealer/join-as-retailer" element={<JoinAsRetailer />} />
          <Route path="/adminlogin" element={<Login admin={true}/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/teamlogin" element={<Login team={true}/>} />
          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/thankyou/:type" element={<ThankYou />} />

          <Route path="/viewstore/:b2c/:storeUserId" element={<ViewStoreProducts />} />
          <Route path="/viewstore/:storeUserId" element={<ViewStoreProducts />} />
          <Route path="/viewstores/:b2c" element={<ViewStores />} />
          <Route path="/viewstores" element={<ViewStores />} />
        </Routes>
      </BrowserRouter>
    );
}

export default App;
