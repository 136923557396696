import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import styles1 from "../../pages/Home/index.module.css"
import { registerRetailer } from "../../api/ApiManager";

const JoinAsBrand = () => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [brandDetails, setBrandDetails] = useState({
    emailId: "",
    name: "",
    brandName: "",
    contactNum: "",
    products: "",
    link: ""
  });
  const [error, setError] = useState({
    emailId: false,
    name: false,
    brandName: false,
    contactNum: false,
    products: false,
    link: false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };

  useEffect(() => {
    const typedH1 = new Typed(h1.current, {
      strings: ["Grow your Brand Offline by connecting with a "],
      typeSpeed: 35,
      showCursor: false,
    });

    const typedAccentH1 = new Typed(accentH1.current, {
      strings: ["closed community of Retailers"],
      startDelay: 2000,
      typeSpeed: 35,
      showCursor: false,
    });

    return () => {
      typedH1.destroy();
      typedAccentH1.destroy();
    };
  }, []);

  useEffect(() => {
    if (width >= 834) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (brandDetails.emailId && brandDetails.name && brandDetails.contactNum && brandDetails.brandName && brandDetails.products && brandDetails.link) {
      setSubmitBtnDisabled(false);
    }
  }, [brandDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const handleBrandDetails = (name, val) => {
    setBrandDetails({
      ...brandDetails,
      [name]: val,
    });
  };

  const handleEmailSubmit = (e) => {
    // e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(businessEmail);

    testEmailId ? setBusinessEmailError(false) : setBusinessEmailError(true);

    if (testEmailId) {
      handleBrandDetails("emailId", businessEmail);
      window.location.replace("/joinasbrand#brand-form");
    };
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(brandDetails.emailId);

    // name validation
    const regexName = /^[a-zA-Z ]+$/;
    const testName = regexName.test(brandDetails.name);

    // brand name validation
    const testBrandName = regexName.test(brandDetails.brandName);

    // contact number validation
    const regexContactNum = /^\d{10}$/;
    const testContactNum = regexContactNum.test(
      parseInt(brandDetails.contactNum)
    );

    //TODO: products and link validation remaining

    setError({
      emailId: !testEmailId,
      name: !testName,
      brandName: !testBrandName,
      contactNum: !testContactNum,
    });

    if (!testEmailId || !testName || !testContactNum || !testBrandName) {
      setShowError(true);
      setSubmitBtnDisabled(true);
    } else {
      setShowError(false);
    }

    if (testEmailId && testName && testContactNum && testBrandName) {
      // final submit function here
      let body = {
        ownerName: brandDetails.name,
        email: brandDetails.emailId,
        mobile: brandDetails.contactNum,
        password: brandDetails.contactNum,
        companyName: brandDetails.brandName,
        sellCategory: brandDetails.products,
        websiteLink: brandDetails.link,
      };
  
      registerRetailer(body)
      .then(res=>{
        setShowPopover(true);
        console.log(JSON.stringify(res));
        if (res.status === 201) {
          setSubmitSuccess(true);
        } else {
          setSubmitSuccess(false);
        }
        setApiError(res.message)
        setShowError(true);
      })
    }
  };

  return (
    <div className={styles.body}>
      <SubmitPopover
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      <div className={styles.header}>
        <Link to="/">
          <img
            className={styles.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
        </Link>
        <img
          className={styles.burgerMenu}
          src={isMenuOpen ? Cross : BurgerMenu}
          alt="burger-menu"
          style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
          onClick={() => setIsMenuOpen((prevState) => !prevState)}
        />
        <div
          className={styles.headerBtn + " " + styles.btn}
          style={{
            transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
          }}
          // onClick={}
        >
          <a href="#brand-form">Register Now</a>
        </div>
      </div>
      <div className={styles.sectionHead}>
        <div className={styles.pageHeadings}>
          <span className={styles.pageHeading} ref={h1}></span>{" "}
          <span
            className={styles.pageHeading + " " + styles.accent}
            ref={accentH1}
          ></span>
        </div>
        <div className={styles.emailInput}>
          <input
            type="text"
            placeholder="Enter your Business Email ID"
            style={{
              borderColor: businessEmailError ? colors.red : colors.blue,
            }}
            value={businessEmail}
            onChange={(e) => setBusinessEmail(e.target.value)}
          />
          <a
            className={styles.btn}
            // href="#brand-form"
            onClick={(e) => handleEmailSubmit(e)}
          >
            Schedule a Demo
          </a>
        </div>
      </div>
      <div className={styles.sectionInformation}>
        <div
          className={styles.info}
          //data-aos="fade-left"
          //data-aos-duration="2000"
        >
          <img
            src={require("../../assets/images/brand-info-1.png")}
            alt="information"
          />
          <div>
            <h3>
              <span className={styles.accentBlueImpt}>Collaborate</span> with
              retailers creating the best experience for your brand
            </h3>
            <p>
              <span className={styles.accentBlue}>
                Let years of retail experitise
              </span>{" "}
              guide curious consumers to become loyal customers of your brand.
            </p>
          </div>
        </div>
        <div
          className={styles.info}
          //data-aos="fade-right"
          //data-aos-duration="2000"
        >
          <img
            src={require("../../assets/images/brand-info-2.png")}
            alt="graphs"
          />
          <div>
            <h3>
              Get access to{" "}
              <span className={styles.accentBlueImpt}>analytics</span>, make
              business decisions based on data
            </h3>
            <p>
              <span className={styles.accentBlue}>Personal analytics</span> such
              as - sales, gross profits, new & repeat customers, &{" "}
              <span className={styles.accentBlue}>Market analytics</span>- such
              as trending products, top selling, area wise sales in market,
              guiding you to make better business decisions
            </p>
          </div>
        </div>
        <div
          className={styles.info}
          //data-aos="fade-left"
          //data-aos-duration="2000"
        >
          <img
            src={require("../../assets/images/brand-info-3.png")}
            alt="photos"
          />
          <div>
            <h3>
              <span className={styles.accentBlueImpt}>Content creation</span>{" "}
              and marketing for you brand by selected experts
            </h3>
            <p>
              Get{" "}
              <span className={styles.accentBlue}>
                premium video content created for selected products
              </span>{" "}
              by our social media experts. Drive traffic through testimonials.{" "}
            </p>
          </div>
        </div>
        <div
          className={styles.info}
          //data-aos="fade-right"
          //data-aos-duration="2000"
        >
          <img
            src={require("../../assets/images/brand-info-4.png")}
            alt="clothes"
          />
          <div>
            <h3>
              <span className={styles.accentBlueImpt}>
                Sell directly online and through retailers
              </span>{" "}
              using the same app
            </h3>
            <p>
              Our integrated platform provides you with{" "}
              <span className={styles.accentBlue}>
                tools and access to both the worlds of both B2B & B2C.
              </span>{" "}
              Find potential buyers and resellers using a single app & manage
              your logistics seamlessly using a single platform.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.border}>
        <div></div>
      </div>
      <div className={styles.sectionForm} id="brand-form">
        <h1>Tell us a Bit About your Brand</h1>
        <p>
          NOTE: We only onboard brands whose mission & values align with our
          own. The <span className={styles.accent}>access is exclusive</span>.
          Signing up doesn’t garantee access to the app.
        </p>
        {submitSuccess ? (
          <div className={styles.signedUp}>
            <img src={CheckCircle} alt="check-mark" />
            <h3>Thank you for signing up!</h3>
            <p>Keep an eye out for our beta launch mail!</p>
          </div>
        ) : (
          <form action="" className={styles.brandForm}>
            <div>
              <label htmlFor="emailId">Business Email ID</label>
              <input
                type="text"
                name="emailId"
                placeholder="Enter Business Email ID"
                style={{
                  borderColor: error.emailId ? colors.red : colors.blue,
                }}
                value={brandDetails.emailId}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="">Full Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                style={{ borderColor: error.name ? colors.red : colors.blue }}
                value={brandDetails.name}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="">Brand/Company Name</label>
              <input
                type="text"
                name="brandName"
                placeholder="Enter Brand/Company name"
                style={{
                  borderColor: error.brandName ? colors.red : colors.blue,
                }}
                value={brandDetails.brandName}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="">Contact Number</label>
              <input
                type="number"
                name="contactNum"
                placeholder="Enter contact number"
                value={brandDetails.contactNum}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.contactNum ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">What do you sell?</label>
              <input
                type="text"
                name="products"
                placeholder="What do you sell?"
                value={brandDetails.products}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.products ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">Link to website or social media</label>
              <input
                type="text"
                name="link"
                placeholder="Link to website or social media"
                value={brandDetails.link}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.link ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <p style={{ display: showError ? "initial" : "none" }}>
                Error detected-{apiError} Please correct and submit again
              </p>
              <button
                type="submit"
                style={{
                  backgroundColor: submitBtnDisabled ? "#677890" : "#001E46",
                  cursor: submitBtnDisabled ? "default" : "pointer",
                }}
                disabled={submitBtnDisabled}
                onClick={(e) => handleSubmit(e)}
              >
                Schedule a Demo
              </button>
            </div>
          </form>
        )}
      </div>
      <div className={styles.border}>
        <div></div>
      </div>

      <div className={styles1.sectionFive}>
      {/* <h2>Join the revolution!</h2>
          <a href="https://play.google.com/store/apps/details?id=com.eyse">
        <div className={styles.storeLogos}>

          <img
            src={require("../../assets/icons/playstore.png")}
            alt="playstore-icon"
          />
        </div>
          </a> */}
        <h2>Join the revolution!</h2>
        <a href="https://play.google.com/store/apps/details?id=com.eyse.dealer">
        <div className={styles1.storeLogos}>
        <img
            src={require("../../assets/icons/dealer-logo.jpg")}
            alt="playstore-icon"
          />
          <img
            src={require("../../assets/icons/playstore.png")}
            alt="playstore-icon"
          />
          {/* <img
            src={require("../../assets/icons/appstore.png")}
            alt="appstore-icon"
          /> */}
        </div>
        </a>
        {/* <h3>Partner with us</h3>
        <div className={styles.joinOptns}>
          <Link to="/joinasexpert" className={styles.btn}>
            Join as Expert
          </Link>
          <Link to="/joinasbrand" className={styles.btn}>
            Join as Brand
          </Link>
        </div>
        <p className={styles.signUpBeta + " " + styles.btn}>
          <a href="#sign-up-beta">Sign up for Beta</a>
        </p> */}
      </div>
      <Footer />
    </div>
  );
}

export default JoinAsBrand;