import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { AppSearchQuery, getAllUniqueSearcheswithUserInfo, getAllUniqueWithFilterKeySearcheswithUserInfo, getSinlgeInfluencerDetailsWithOutMobileAndEmail, getProductDetails, getSingleStoreDetailsByUserIdWithOutMobileAndEmail, getUniqueSearcheswithUserInfo, registerRetailer, saveOrder, getPostsByQueryId, getPostsByText, reachoutCreator } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import SubmitPopover3 from "../../components/SubmitPopover/SubmitPopover3";
import styles1 from "../Home/index.module.css"
import BrandHeader from "./BrandHeader";
import StoreHeader from "./StoreHeader";
import InfluencerHeader from "./InfluencerHeader";
import IndependentHeader from "./IndependentHeader";
import { rediectLink } from "../../api/fetchBackend";

const AnswerQuery = (props) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [brandDetails, setBrandDetails] = useState({
    quantity:0,
    
    firstName: "",
    lastName: "",
    houseNumber:"",
    brandName: "",
    contactNum: "",
    emailId: "",
    street:"",
    ladnmark: "",
    city: "",
    state:"",
    country:"",
    pincode:""
  });
  const [error, setError] = useState({
    quantity:false,
    
    firstName: false,
    lastName:false,
    houseNumber:false,
    brandName: false,
    contactNum: false,
    emailId: false,
    street:false,
    ladnmark: false,
    city: false,
    state:false,
    country:false,
    pincode:false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product,setProduct] = useState()
  let {storeUserId, brandUserId,influencerId}=useParams()
  const [orderLoading,setOrderLoading] = useState(false)
  const [searchKey,setSearchKey] = useState()
  const [queries,setQueries] = useState([])
  const [posts,setPosts] = useState([])
  const [shopDetails,setShopDetails] = useState()
  const [influencerDetails,setInfluencerDetails] = useState()
  const [showPostDiv,setShowPostDiv] = useState()
  const [showReachOutDiv,setShowReachOutDiv] = useState(false)
  const [reachOutNote,setReachOutNote] = useState()
  const [requestee,setRequestee] = useState()

  useEffect(()=>{
    if(storeUserId||brandUserId){
      getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId?storeUserId:brandUserId)
        .then(res => {
          if (res.status == 200) {
            setShopDetails(res.Data)
            // getQuriesWithKey(res.Data.name)
            setSearchKey(res.Data.name)
            let body = {
              "pagination": {
                "page": 1,
                "limit": 1000
              },
              "search": res.Data.name,
              "boosted": false
          
            }
            getAllUniqueWithFilterKeySearcheswithUserInfo(body)
            .then(res1=>{
              console.log(res1)
              if(res1.status==200&&res1.data.length>0){
                setQueries(res1.data)
              }else{
                setQueries([])
              }
            })
          }
        })
    }
    else if(influencerId){
      getSinlgeInfluencerDetailsWithOutMobileAndEmail(influencerId)
        .then(res => {
          // console.log(res)
          if (res.Data) {
              setInfluencerDetails(res.Data)
              setSearchKey(res.Data.name)
              let body = {
                "pagination": {
                  "page": 1,
                  "limit": 1000
                },
                "search": res.Data.name,
                "boosted": false
            
              }
              getAllUniqueWithFilterKeySearcheswithUserInfo(body)
              .then(res1=>{
                console.log(res1)
                if(res1.status==200&&res1.data.length>0){
                  setQueries(res1.data)
                }else{
                  setQueries([])
                }
              })
          }
            // })
          // }
        })
    }else{
      getQuries()
    }
  },[])

  const getQuries = () => {
    let body = {
      "pagination": {
        "page": 1,
        "limit": 1000
      }
    }
    getAllUniqueSearcheswithUserInfo(body)
    .then(res=>{
      console.log(res)
      if(res.status==200&&res.data.length>0){
        setQueries(res.data)
      }else{
        setQueries([])
      }
    })
  };
  
  const getQuriesWithKey = () => {
    let body = {
      "pagination": {
        "page": 1,
        "limit": 1000
      },
      "search": searchKey,
      "boosted": false
  
    }
    getAllUniqueWithFilterKeySearcheswithUserInfo(body)
    .then(res=>{
      console.log(res)
      if(res.status==200&&res.data.length>0){
        setQueries(res.data)
      }else{
        setQueries([])
      }
    })
  };

  const getPostsByQuery = (queryId) => {
    let body = {
      "pagination": {
        "page": 1,
        "limit": 10
      },
      "queryId": queryId,
  
    }
    getPostsByQueryId(body)
    .then(res=>{
      // console.log(res.data[0])
      if(res.status==200&&res.data.length>0){
        setPosts(res.data)
      }else{
        alert('No results found!')
        setPosts([])
      }
    })
  };

  const getPostsByQueryText = (search) => {
    let body = {
      "pagination": {
          "page": 1,
          "limit": 10
      },
      // "hashtags": [
      //     "gdfgfdkr"
      // ],
      "search": search,
      "type": "or",
      "postType": "",
      "inflcrRlt": "",
      "userId": "",
      "inflcrId": "",
      "skip":true
  }
    getPostsByText(body)
    .then(res=>{
      // console.log(res.Data[0])
      if(res.status==200&&res.Data && res.Data.length>0){
        setPosts(res.Data)
      }else{
        alert('No results found!')
        setPosts([])
      }
    })
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

// useEffect(()=>{
//   console.log(productId)
//   getProductDetails(productId)
//       .then(res => {
//         // console.log(res.Data)
//         // let arr= res.Data
//         // if(arr && arr.length>0){
//           // arr.map(i=>{
            
//             // let arr2 = arr.map((item, index) => {
//               // console.log(item)
//               // return { key: index + 1, text: item.prdct_attributes.prdct_name, value: item };
//             // });
//             // setProducts([...[{key:-1,text:'Select Product',value:null}],...arr2]);
//             // arr2.sort( (a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
//             setProduct(res.Data);
//           // })
//         // }
//       })
// },[])

  return (
    <div className={styles.body}>
      <SubmitPopover3
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      {!influencerId&&!brandUserId&&!storeUserId&&<IndependentHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {influencerId && influencerDetails && <InfluencerHeader influencerId={influencerId} influencerDetails={influencerDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {brandUserId && shopDetails && <BrandHeader storeUserId={brandUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {storeUserId && shopDetails && <StoreHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {/* <div className={styles.sectionHead}>
      <div style={{ alignSelf: "center" }}>
        <Link to="/">
          <img
            className={styles1.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
          </Link>
        </div>
        <div className={styles.pageHeadings}>
          <span className={styles.pageHeading} ref={h1}></span>{" "}
        </div>
      </div> */}
      {/* <div className={styles.border}>
        <div></div>
      </div> */}
      <div className={styles.sectionForm} id="brand-form">
        <h1>Answer a Query</h1>
        <div>
          <label htmlFor="filterProducts">Search by Key</label>
          <input
            type="text"
            name="searchKey"
            placeholder="Filter"
            value={searchKey}
            // value={}
            onChange={(e) =>
              {
                setSearchKey(e.target.value)
              }
            }
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              getQuriesWithKey()
            }}
          >
            Fetch
          </button>
        </div>
      </div>
      <div className={styles.border}>
        <div></div>
      </div>
      <div style={{ maxHeight: height, overflowY: "scroll" }}>
        <h5>Showing: {queries.length} Queries</h5>
        {queries.map(query => {
          // console.log(query)
          return query && query.searchHist && query.searchHist.search && (
            <div>
              <div>
              <div style={{ display: "flex", overflowX: "scroll" }} >
                {/* <h5>{query&& query.searchHist&&query.searchHist.search?query&& query.searchHist&&query.searchHist.search:""}  </h5> */}
                <p>
                  {query && query.searchHist && query.searchHist.search ? query && query.searchHist && query.searchHist.search.replace(`Query on ${influencerDetails ? influencerDetails.name : shopDetails ? shopDetails.name : ''}: `, "") : ""}
                </p>
              </div>
              {brandUserId && <Link to={`/addbrandreview/${brandUserId}/${query && query.searchHist && query.searchHist._id}`} className={styles1.btn}>
                Answer Now
              </Link>}
              {storeUserId && <Link to={`/addstorereview/${storeUserId}/${query && query.searchHist && query.searchHist._id}`} className={styles1.btn}>
                Answer Now
              </Link>}
              {influencerId && <Link to={`/addinfluencerreview/${influencerId}/${query && query.searchHist && query.searchHist._id}`} className={styles1.btn}>
                Answer Now
              </Link>}
              {!brandUserId && !storeUserId && !influencerId && <Link to={`/addreview/${query && query.searchHist && query.searchHist._id}`} className={styles1.btn}>
                Answer Now
              </Link>}
              </div>
              
          <button
            onClick={(e) => {
              e.preventDefault();
              getPostsByQueryText(query.searchHist.search)
              setShowPostDiv(query.searchHist._id)
            }}
          >
            Similar Responses
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              getPostsByQuery(query.searchHist._id)
              setShowPostDiv(query.searchHist._id)
            }}
          >
            Direct Responses
          </button>
          <div id={query.searchHist._id} hidden ={query.searchHist._id==showPostDiv?false:true}>
          {posts.map(post => {
          console.log(post)
          return <div>
            <div style={{ display:"flex", overflowX: "auto" }}>
              {post.postData && post.postData.data && post.postData.data.map(i => {
                return (
                  <img
                    src={post.type == 'VIDEO' ? i.thumbnail : i.url}
                    // style={{ height: '100px', width: '100px' }}
                    // style={{ maxHeight: height, maxWidth: width }}
                    style={{ maxHeight: height<=width?height/2:height, maxWidth: height<=width?width/2:width, }}

                  />
                )
              })}
            </div>
            {post && <h3>Title: {post.postData.caption &&post.postData.caption.length>0 && post.postData.caption[0]&&post.postData.caption[0].value ? post.postData.caption[0].value : null}</h3>}
            {post && <h3>Description: {post.postData.desc && post.postData.desc.length>0 && post.postData.desc[0]&&post.postData.desc[0] ? post.postData.desc[0].value : null}</h3>}
              {<a href={`${APP_URL}post/${post._id}/${post.postData._id}`} target="_blank" className={styles1.btn}>
                  {/* Explore Details */}
                  {false&&post.postData.caption &&post.postData.caption.length>0 && post.postData.caption[0]&&post.postData.caption[0].value ? post.postData.caption[0].value:"Explore Details"}
                  </a>}
            {post.postData && post.postData.links && post.postData.links[0] && post.postData.links[0].data && post.postData.links[0].data.length > 0 && post.postData.links[0].data.map(i => {

              console.log(i)
              return (

                <a href={`${rediectLink(i.url)}`} target="_blank" className={styles1.btn}>
                  {i.name}
                </a>
              )
              // return i.map(l=>{
              // })


            })}

            <button
            onClick={(e) => {
              e.preventDefault();
              // getPostsByQuery(query.searchHist._id)
              setShowReachOutDiv(post.postData._id)
            }}
          >
            Reachout
          </button>
              <div id={post.postData._id} hidden ={post.postData._id==showReachOutDiv?false:true}>
              <label htmlFor="reachOutForm">ReachOut Note</label>
              <input
                type="text"
                name="reachOutForm"
                placeholder="Sharing event information like date of event to check availability"
                value={reachOutNote}
                // value={}
                onChange={(e) => {
                  setReachOutNote(e.target.value)
                }
                }
              />
              <input
                type="text"
                name="requesteeContact"
                placeholder="Your Registered Email"
                value={requestee}
                // value={}
                onChange={(e) => {
                  setRequestee(e.target.value)
                }
                }
              />
              <button
                onClick={async(e) => {
                  e.preventDefault();
                  let res = await reachoutCreator({note:reachOutNote,email:requestee,influencerId:post._id})
                  if(res.status ==200||res.status ==201){
                    alert("message sent successfully!")
                  }else{
                    alert("message not able to send! wrong mobile!")
                  }
                }}
              >Send Reachout Message</button>
            </div>
            <div>
              {/* {post && (influencerId) && <Link to={`/taginfluencerreview/${influencerId}/${post._id}/${influencerId}`} className={styles1.btn}>
                Tag with Post
              </Link>}
              {post && (brandUserId) && <Link to={`/tagbrandreview/${post.mentionInfId}/${post._id}/${brandUserId}`} className={styles1.btn}>
                Tag with Post
              </Link>}
              {post && (storeUserId) && <Link to={`/tagstorereview/${post.mentionInfId}/${post._id}/${storeUserId}`} className={styles1.btn}>
                Tag with Post
              </Link>}
              {post && !storeUserId && !influencerId && !brandUserId && <Link to={`/tagreview/${post.mentionInfId}/${post._id}`} className={styles1.btn}>
                Tag with Post
              </Link>} */}
            </div>
          </div>
        })}
          </div>
            </div>
          )
        }
        )
        }
      </div>
      <Footer />
    </div>
  );
}

export default AnswerQuery;