import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams, useSearchParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { AppSearchQuery, getAllInfluencersWithOutMobileAndEmail, getAllShopsDetailsWithoutUserInfo, getAllUniqueWithFilterKeySearcheswithUserInfo, getSinlgeInfluencerDetailsWithOutMobileAndEmail, getInfluencerPosts, getProductDetails, getSingleStoreDetailsByUserIdWithOutMobileAndEmail, registerRetailer, saveOrder, getAllInfluencers, getAllUniqueSearcheswithUserInfo } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import SubmitPopover3 from "../../components/SubmitPopover/SubmitPopover3";
import styles1 from "../Home/index.module.css"
import BrandHeader from "./BrandHeader";
import StoreHeader from "./StoreHeader";
import InfluencerHeader from "./InfluencerHeader";
import IndependentHeader from "./IndependentHeader";
import { Dropdown, FormField, Form, Checkbox } from "semantic-ui-react";

const AskQuery = (props) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [brandDetails, setBrandDetails] = useState({
    quantity:0,
    
    firstName: "",
    lastName: "",
    houseNumber:"",
    brandName: "",
    contactNum: "",
    emailId: "",
    street:"",
    ladnmark: "",
    city: "",
    state:"",
    country:"",
    pincode:""
  });
  const [error, setError] = useState({
    quantity:false,
    
    firstName: false,
    lastName:false,
    houseNumber:false,
    brandName: false,
    contactNum: false,
    emailId: false,
    street:false,
    ladnmark: false,
    city: false,
    state:false,
    country:false,
    pincode:false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product,setProduct] = useState()
  let {storeUserId, brandUserId,influencerId}=useParams()
  const [searchParams, setSearchParams]= useSearchParams()
  const [orderLoading,setOrderLoading] = useState(false)

  const [shopDetails,setShopDetails] = useState()
  const [influencerDetails,setInfluencerDetails] = useState()
  const [allBrandShops,setAllBrandShops] = useState([])
  const [allStoreShops,setAllStoreShops] = useState([])
  const [allInfluencers,setAllInfluencers] = useState([])
  const [queries,setQueries] = useState([])
  const [value,setValue] = useState('anything')
  const [value2,setValue2] = useState()
  const [referenceDetails,setReferenceDetails] = useState()

  useEffect(()=>{
    // console.log("rid",searchParams.get("rid"))
    let rid = localStorage.getItem('rid')//searchParams.get('id')
    if(rid){
      getSinlgeInfluencerDetailsWithOutMobileAndEmail(rid)
        .then(res => {
          // console.log(res)
          if (res.Data) {
              setReferenceDetails(res.Data)
          }
            // })
          // }
        })
    } 
  },[])

  useEffect(()=>{
    if(!(storeUserId||brandUserId || influencerId)){

      if(!(storeUserId||brandUserId)){

        getAllShopsDetailsWithoutUserInfo()
            .then(res => {
              console.log(res)
              if (res.Data) {
                  let arr = res.Data
                  let brandArr = []
                  let storeArr = []
                  arr.sort((a,b)=>a.name>b.name?1:-1)
                  arr.map(i=>{
                    if(i.user_id.isBrand){
                      brandArr.push(i)
                    }else{
                      storeArr.push(i)
                    }
                  })
                  // setAllBrandShops(brandArr)
                  // setAllStoreShops(storeArr)
                  let arr2 = []
                  brandArr.length > 0 && brandArr.map((c, index) => {
                    arr2.push({ key: index + 1, text: c.name, value: c })
                  })
                  setAllBrandShops(arr2)
                  let arr3 = []
                  storeArr.length > 0 && storeArr.map((c, index) => {
                    arr3.push({ key: index + 1, text: c.name, value: c })
                  })
                  setAllStoreShops(arr3)
              }
                // })
              // }
            })
      }
    }

  },[])

  useEffect(()=>{
    if(!(storeUserId||brandUserId || influencerId)){
      console.log("setAllInfluencers")
      getAllInfluencers()
      .then(res => {
        console.log("setAllInfluencers",res)
        if (res.Data && res.Data.length>0) {
            let arr = res.Data
            arr.sort((a,b)=>a.name>b.name?1:-1)
            let arr3 = []
                  arr.length > 0 && arr.map((c, index) => {
                    arr3.push({ key: index + 1, text: c.name, value: c })
                  })
            setAllInfluencers(arr3)
        }
          // })
        // }
      })
    }

  },[])

  const getQuries = () => {
    let body = {
      "pagination": {
        "page": 1,
        "limit": 5
      }
    }
    getAllUniqueSearcheswithUserInfo(body)
    .then(res=>{
      console.log(res)
      if(res.status==200&&res.data.length>0){
        setQueries(res.data)
      }else{
        setQueries([])
      }
    })
  };

  useEffect(()=>{
  },[])



  useEffect(()=>{
    if(storeUserId||brandUserId){
      getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId?storeUserId:brandUserId)
        .then(res => {
          // console.log(res.Data)
          if (res.status == 200) {
            setShopDetails(res.Data)
            let body = {
              "pagination": {
                "page": 1,
                "limit": 10
              },
              "search": res.Data.name,
              "boosted": false
          
            }
            getAllUniqueWithFilterKeySearcheswithUserInfo(body)
            .then(res1=>{
              // console.log(res1)
              if(res1.status==200&&res1.data.length>0){
                setQueries(res1.data)
              }else{
                setQueries([])
              }
            })
            // if(res.Data.user_id && res.Data.user_id.isBrand){
            //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
            // }else{
  
            //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
            // }
          }
        })
    }else if(influencerId){
      getSinlgeInfluencerDetailsWithOutMobileAndEmail(influencerId)
        .then(res => {
          // console.log(res)
          if (res.Data) {
              setInfluencerDetails(res.Data)
              let body = {
                "pagination": {
                  "page": 1,
                  "limit": 5
                },
                "search": res.Data.name,
                "boosted": false
            
              }
              getAllUniqueWithFilterKeySearcheswithUserInfo(body)
              .then(res1=>{
                // console.log(res1)
                if(res1.status==200&&res1.data.length>0){
                  setQueries(res1.data)
                }else{
                  setQueries([])
                }
              })
          }
            // })
          // }
        })
    }else{
      getQuries()
    }
  },[])

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    if (width >= 834) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (brandDetails.emailId && brandDetails.name && brandDetails.contactNum && brandDetails.brandName && brandDetails.products && brandDetails.link) {
      setSubmitBtnDisabled(false);
    }
  }, [brandDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const handleBrandDetails = (name, val) => {
    setBrandDetails({
      ...brandDetails,
      [name]: val,
    });
  };

  const handleEmailSubmit = (e) => {
    // e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(businessEmail);

    testEmailId ? setBusinessEmailError(false) : setBusinessEmailError(true);

    if (testEmailId) {
      handleBrandDetails("emailId", businessEmail);
      window.location.replace("/joinasbrand#brand-form");
    };
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(brandDetails.emailId);

    // name validation
    const regexName = /^[a-zA-Z ]+$/;
    const regexName1 =/[^\s][A-z0-9À-ž\s]+/
    const testFirstName = regexName1.test(brandDetails.firstName);
    const testLasttName = regexName.test(brandDetails.lastName);

    // brand name validation
    // const testBrandName = regexName.test(brandDetails.brandName);

    // contact number validation
    const regexContactNum = /^\d{10}$/;
    const testContactNum = regexContactNum.test(
      parseInt(brandDetails.contactNum)
    );
    const testPincode =  /^\d{6}$/.test(
      parseInt(brandDetails.pincode)
    );

    //TODO: products and link validation remaining

    setError({
      // quantity:brandDetails.quantity>0?false:true,
      emailId: !testEmailId,
      firstName: !testFirstName,
      lastName:!testLasttName,
      // brandName: !testBrandName,
      // houseNumber:brandDetails.houseNumber==''?true:false,
      // brandName: "",
      // street:brandDetails.street==''?true:false,
      // ladnmark: brandDetails.ladnmark==''?true:false,
      // city: brandDetails.city==''?true:false,
      // state:brandDetails.state==''?true:false,
      // country:brandDetails.country==''?true:false,
      // pincode:!testPincode,
      contactNum: !testContactNum,
    });

    if (!testEmailId || !testFirstName || !testLasttName || !testContactNum) {
      setShowError(true);
      setSubmitBtnDisabled(true);
    } else {
      setShowError(false);
    }

    if (testEmailId && testFirstName && testLasttName && testContactNum) {
      // final submit function here
      let userAddress = {
        // ownerName: brandDetails.name,
        // email: brandDetails.emailId,
        // mobile: brandDetails.contactNum,
        // password: brandDetails.contactNum,
        // companyName: brandDetails.brandName,
        // sellCategory: brandDetails.products,
        // websiteLink: brandDetails.link,
          // firstName:brandDetails.firstName,
          // lastName:brandDetails.lastName,
          // houseNo: brandDetails.houseNo,
          // street: brandDetails.street,
          // landmark: brandDetails.ladnmark,
          // city: brandDetails.city,
          // state: brandDetails.state,
          // country: brandDetails.country,
          // pinCode: brandDetails.pincode,
          // email:brandDetails.emailId,
          // mobile:brandDetails.contactNum
        
      };

      // const body = {address: userAddress,sellerDetails:null,bookingCode:null,user:storeUserId,productId:productId,quantity:brandDetails.quantity};
      let initq= influencerDetails?influencerDetails.name:shopDetails&&shopDetails.name?shopDetails.name:""
      let initq2 = initq!="" ? "Query on " + initq +": ":""
      let query =  initq2 +  brandDetails.firstName
      const body ={
        "pagination": {
            "page": 1,
            "limit": 10
        },
        // "hashtags": [
        //     "gdfgfdkr"
        // ],
        "search": query,
        userData : {
          influencerUserDetails:referenceDetails._id,
          profilePic: {
            name: 'My pICTURE',
            url: 'https://xm-customer.s3.ap-south-1.amazonaws.com/images/16139913872243qF9NbThu.jpg',
          },
          name: brandDetails.lastName,
          email: brandDetails.emailId,
          mobile: brandDetails.contactNum,
          password: '12345',
          gender: 'Male',
          dob: '1994-08-28',
          address: {
            firstName: 'Balavan',
            lastName: 'Patil',
            mobile: '8197830443',
            line1: 'Patil Nivas',
            line2: 'Basavkalyan',
            landmark: 'Basavkalyan',
            houseNumber: '26/7',
            street: 'Talhbhog',
            city: 'Basavkalyan',
            pinCode: 585327,
            state: 'Karnataka',
            country: 'INDIA',
          },
        },
    }
      // console.log("body..................",body)
      AppSearchQuery(body)
      .then(response=>{
        if (response.status == 200 || response.status == 404) {
          setShowPopover(true)
          setSubmitSuccess(true)
        }else{
          alert(response.message)
        }
      })
    }
  };

  return (
    <div className={styles.body}>
      <SubmitPopover3
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      {/* <div className={styles.sectionHead}>
      <div style={{ alignSelf: "center" }}>
        <Link to="/">
          <img
            className={styles1.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
          </Link>
        </div>
      </div> */}
        {console.log(shopDetails)}
        {console.log(brandUserId)}
        {console.log(isMenuOpen)}
        {!influencerId&&!brandUserId&&!storeUserId&&<IndependentHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
        {influencerId&&influencerDetails&&<InfluencerHeader influencerId={influencerId} influencerDetails={influencerDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
        {brandUserId&&shopDetails&&<BrandHeader storeUserId={brandUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
        {storeUserId&&shopDetails&&<StoreHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
        
        {/* <div className={styles.border}>
          <div></div>
        </div> */}
      <div style={{ display:"flex", overflowX: "auto" }} hidden={true}>
        {false&&allBrandShops.length>0&&<h5>Ask a brand: </h5>}
      {false&&allBrandShops.length>0&&allBrandShops.map(userShop=>{
        return(
      //   <Link to={`/brandaskquery/${userShop.user_id}`} className={styles1.signUpBeta + " " + styles1.btn}>
      //   {userShop.name}
      //  </Link>
          <div className={styles.border}>
            <a href={userShop.user_id.isBrand?`/brandaskquery/${userShop.user_id._id}`:`/storeaskquery/${userShop.user_id._id}`}>
          {userShop.name} {'    '}</a>
          <div></div>
        </div>
      // <p className={styles1.signUpBeta + " " + styles1.btn}>
      //     {/* <a href="#sign-up-beta">Sign up for Beta</a> */}
      //     </p>
        )
      })}
      </div>
      <div style={{ display:"flex", overflowX: "auto" }} hidden={true}>
        {false&&allStoreShops.length>0&&<h5>Ask a store: </h5>}
      {false&&allStoreShops.length>0&&allStoreShops.map(userShop=>{
        // console.log(userShop)
        return(
      //   <Link to={`/brandaskquery/${userShop.user_id}`} className={styles1.signUpBeta + " " + styles1.btn}>
      //   {userShop.name}
      //  </Link>
          <div className={styles.border}>
            <a href={userShop.user_id.isBrand?`/brandaskquery/${userShop.user_id._id}`:`/storeaskquery/${userShop.user_id._id}`}>
          {userShop.name} {'    '}</a>
          <div></div>
        </div>
      // <p className={styles1.signUpBeta + " " + styles1.btn}>
      //     {/* <a href="#sign-up-beta">Sign up for Beta</a> */}
      //     </p>
        )
      })}
      </div>
      <div style={{ display:"flex", overflowX: "auto" }} hidden={true}>
        {false&&allInfluencers.length>0&&<h5>Ask a service/influencer: </h5>}
      {false&&allInfluencers.length>0&&allInfluencers.map(userShop=>{
        // console.log(userShop)
        return(
      //   <Link to={`/brandaskquery/${userShop.user_id}`} className={styles1.signUpBeta + " " + styles1.btn}>
      //   {userShop.name}
      //  </Link>
          <div className={styles.border}>
            <a href={`/influenceraskquery/${userShop._id}`}>
          {userShop.name} {'    '}</a>
          <div></div>
        </div>
      // <p className={styles1.signUpBeta + " " + styles1.btn}>
      //     {/* <a href="#sign-up-beta">Sign up for Beta</a> */}
      //     </p>
        )
      })}
      </div>
      
      <div className={styles.sectionForm} id="brand-form">
        <h1>Ask a Query</h1>
        {!brandUserId&&!storeUserId&&!influencerId&&<form action="" className={styles.expertForm}>
          <Form>
            {/* <FormField>
              Selected value: <b>{value}</b>
            </FormField> */}
            <FormField>
              <Checkbox
                radio
                label='Anything'
                name='checkboxRadioGroup'
                value='anything'
                checked={value === 'anything'}
                onChange={(e, data) => (setValue(data.value),setValue2(null))}
              />
            </FormField>
            <FormField>
              <Checkbox
                radio
                label='Brand'
                name='checkboxRadioGroup'
                value='brand'
                checked={value === 'brand'}
                onChange={(e, data) => (setValue(data.value),setValue2(null))}
              />
            </FormField>
            <FormField>
              <Checkbox
                radio
                label='Store'
                name='checkboxRadioGroup'
                value='store'
                checked={value === 'store'}
                onChange={(e, data) => (setValue(data.value),setValue2(null))}
              />
            </FormField>
            <FormField>
              <Checkbox
                radio
                label='Influencer or Service'
                name='checkboxRadioGroup'
                value='service'
                checked={value === 'service'}
                onChange={(e, data) => (setValue(data.value),setValue2(null))}
              />
            </FormField>
          </Form>
          {value === 'brand' && <div>
            <label htmlFor="Brand">Brand</label>
            <Dropdown
              value={value2 ? value2 : "Search Brand"}
              placeholder={value2 ? value2 : "Search Brand"}
              // disabled={selectedProduct?true:false}
              search
              selection
              options={allBrandShops}
              onChange={(event, { value }) => {
                // console.log(event)
                setValue2(value)
              }
              }
            />
          </div>}
          {value === 'store' && <div>
            <label htmlFor="Store">Store</label>
            <Dropdown
              value={value2 ? value2 : "Search Store"}
              placeholder={value2 ? value2 : "Search Store"}
              // disabled={selectedProduct?true:false}
              search
              selection
              options={allStoreShops}
              onChange={(event, { value }) => {
                // console.log(event)
                setValue2(value)
              }
              }
            />
          </div>}
          {value === 'service' && <div>
            <label htmlFor="Service">Service</label>
            <Dropdown
              value={value2 ? value2 : "Search Influencer or Service"}
              placeholder={value2 ? value2 : "Search Influencer or Service"}
              // disabled={selectedProduct?true:false}
              search
              selection
              options={allInfluencers}
              onChange={(event, { value }) => {
                // console.log(event)
                setValue2(value)
              }
              }
            />
          </div>}
            <div>
              {value&&value=='service'&&value2&&value2._id&&
            <a href={`/influenceraskquery/${value2._id}`}>
             {'Go'}</a>
              }
              {value&&value!='service'&&value2&&value2.user_id&&
            <a href={value2.user_id.isBrand?`/brandaskquery/${value2.user_id._id}`:`/storeaskquery/${value2.user_id._id}`}>
            {'Go'}</a>
              }
              
            </div>
          </form>}
        <div>
        {(false&&queries&&queries.length>0)&&<h5>Recent Queries: </h5>}
      {false&&queries&&(queries.length>0)&&queries.map(query=>{

        // return query&& query.searchHist&&query.searchHist.search&&(
        //     <h5>{query&& query.searchHist&&query.searchHist.search?query&& query.searchHist&&query.searchHist.search:""}  </h5>
        //   // <div className={styles.border}>
        //   //   <div></div>
        //   // </div>
        // )
        return query&& query.searchHist&&query.searchHist.search&&(
          <p>
            {query&& query.searchHist&&query.searchHist.search?query&& query.searchHist&&query.searchHist.search.replace(`Query on ${influencerDetails?influencerDetails.name:shopDetails?shopDetails.name:''}: `,""):""}
          </p>
        )
      })}
      </div>
        {/* <p>
         Test <span className={styles.accent}>Test</span>Test
        </p> */}
        {submitSuccess ? (
          <div className={styles.signedUp}>
            <img src={CheckCircle} alt="check-mark" />
            <h3>Thank you for posting your query!</h3>
            <p>Keep an eye out on Eyse app for responses and connect with reviewers for further guidance!</p>
            <p>(Default password for new account:12345)</p>
            <a href="https://play.google.com/store/apps/details?id=com.eyse">
              <div className={styles1.storeLogos}>
                <img
                  src={require("../../assets/icons/eyse-logo.jpg")}
                  alt="playstore-icon"
                />
                <img
                  src={require("../../assets/icons/playstore.png")}
                  alt="playstore-icon"
                />
                {/* <img
            src={require("../../assets/icons/appstore.png")}
            alt="appstore-icon"
          /> */}
              </div>
            </a>
          </div>
        ) : (
          <form action="" className={styles.brandForm}>
            <div>
              <h3>{product && product.prdct_attributes.prdct_name}</h3>
            </div>
            
            <div>
              <label htmlFor="">{influencerDetails?`Query on ${influencerDetails.name}`:shopDetails?`Query on ${shopDetails.name}`:"Query on "}</label>
              {height>width?<div><i htmlFor="">{influencerDetails?`Query on ${influencerDetails.name}`:shopDetails?`Query on ${shopDetails.name}`:"Query on "}</i></div>:null}
              <input
                type="text"
                name="firstName"
                placeholder="Ask your query"
                style={{ borderColor: error.firstName ? colors.red : colors.blue }}
                value={brandDetails.firstName}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="">Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="Enter name"
                style={{ borderColor: error.lastName ? colors.red : colors.blue }}
                value={brandDetails.lastName}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="">Contact Number</label>
              <input
                type="number"
                name="contactNum"
                placeholder="Enter contact number"
                value={brandDetails.contactNum}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.contactNum ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="emailId">Email ID</label>
              <input
                type="text"
                name="emailId"
                placeholder="Enter Email ID"
                style={{
                  borderColor: error.emailId ? colors.red : colors.blue,
                }}
                value={brandDetails.emailId}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>
            {/* <div>
              <label htmlFor="">Password</label>
              <input
                type="password"
                name="ladnmark"
                placeholder="Landmark"
                value={brandDetails.ladnmark}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.ladnmark ? colors.red : colors.blue,
                }}
              />
            </div> */}
            <div>
              <p style={{ display: showError ? "initial" : "none" }}>
                Error detected-{apiError} Please correct and submit again
              </p>
              <button
                type="submit"
                style={{
                  backgroundColor: submitBtnDisabled ? "#677890" : "#001E46",
                  cursor: submitBtnDisabled ? "default" : "pointer",
                }}
                // disabled={submitBtnDisabled}
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </div>
      <div className={styles.border}>
        <div></div>
      </div>

      <Footer />
    </div>
  );
}

export default AskQuery;