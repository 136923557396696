import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { AppSearchQuery, getAllInfluencersPostsWithMentionsByEmail, getAllUniqueSearcheswithUserInfo, getAllUniqueWithFilterKeySearcheswithUserInfo, getAllInfluencersPostsWithoutMobileAndEmail, getPostsByTaggedStoreUserId, getProductDetails, getSinlgeInfluencerPostsByEmail, getUniqueSearcheswithUserInfo, registerRetailer, saveOrder, updatePostDet, updatePostDetWeb } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import SubmitPopover3 from "../../components/SubmitPopover/SubmitPopover3";
import styles1 from "../Home/index.module.css"
import BrandHeader from "./BrandHeader";
import StoreHeader from "./StoreHeader";
import InfluencerHeader from "./InfluencerHeader";

const ModerateReviews = (props) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [brandDetails, setBrandDetails] = useState({
    quantity:0,
    
    firstName: "",
    lastName: "",
    houseNumber:"",
    brandName: "",
    contactNum: "",
    emailId: "",
    street:"",
    ladnmark: "",
    city: "",
    state:"",
    country:"",
    pincode:""
  });
  const [error, setError] = useState({
    quantity:false,
    
    firstName: false,
    lastName:false,
    houseNumber:false,
    brandName: false,
    contactNum: false,
    emailId: false,
    street:false,
    ladnmark: false,
    city: false,
    state:false,
    country:false,
    pincode:false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product,setProduct] = useState()
  let {email,storeUserId,brandUserId,influencerId,validated}=useParams()
  const [orderLoading,setOrderLoading] = useState(false)
  const [searchKey,setSearchKey] = useState()
  const [queries,setQueries] = useState([])
  const [shopDetails,setShopDetails] = useState()
  const [posts,setPosts] =  useState([])
  const [influencerDetails,setInfluencerDetails] = useState()
  const [page,setPage] = useState(1)
  const userData = localStorage.getItem("user-data");
  const token = localStorage.getItem("auth_token");

  // useEffect(()=>{
  //   getSinlgeInfluencerDetailsWithOutMobileAndEmail(influencerId)
  //       .then(res => {
  //         // console.log(res)
  //         if (res.Data) {
  //             setInfluencerDetails(res.Data)
  //         }
  //           // })
  //         // }
  //       })
  // },[])

  // useEffect(()=>{
  //   if(storeUserId||brandUserId){
  //     getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId?storeUserId:brandUserId)
  //       .then(res => {
  //         console.log(res.Data.name)
  //         if (res.status == 200) {
  //           setShopDetails(res.Data)
  //           // if(res.Data.user_id && res.Data.user_id.isBrand){
  //           //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
  //           // }else{
  
  //           //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
  //           // }
  //         }
  //       })
  //   }
  // },[])

  useEffect(()=>{
      // }
      getPost()
  },[])
  const getPost = ((page1)=>{
    userData&&getAllInfluencersPostsWithoutMobileAndEmail(page1?page1:page)
          .then(res => {
            console.log("-------------------")
            console.log(res)
            if (res.Data && res.Data.length>0) {
              setPosts(res.Data)
              // getQuriesWithKey(res.Data.name)
              setSearchKey(res.Data.name)
              let body = {
                "pagination": {
                  "page": 1,
                  "limit": 10
                },
              }
            }
          })
  })


  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

// useEffect(()=>{
//   console.log(productId)
//   getProductDetails(productId)
//       .then(res => {
//         // console.log(res.Data)
//         // let arr= res.Data
//         // if(arr && arr.length>0){
//           // arr.map(i=>{
            
//             // let arr2 = arr.map((item, index) => {
//               // console.log(item)
//               // return { key: index + 1, text: item.prdct_attributes.prdct_name, value: item };
//             // });
//             // setProducts([...[{key:-1,text:'Select Product',value:null}],...arr2]);
//             // arr2.sort( (a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
//             setProduct(res.Data);
//           // })
//         // }
//       })
// },[])

  return (
    <div className={styles.body}>
      <SubmitPopover3
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      {influencerId&&influencerDetails&&<InfluencerHeader influencerId={influencerId} influencerDetails={influencerDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {brandUserId&&shopDetails&&<BrandHeader storeUserId={brandUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {storeUserId&&shopDetails&&<StoreHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      
      <div  className={styles1.sectionForm} style={{maxHeight:height,overflowY:"scroll"}}>
          <h1>Recent Reviews on page: {page}</h1>
          <button
          disabled={page<=0||page==1?true:false}
            onClick={(e) => {
              let p = page
              e.preventDefault();
              getPost(p-1);
              setPage(p-1)
            }}
          >
            Back
          </button>
          <button
          // disabled={page<=1?false:true}
            onClick={(e) => {
              let p = page
              e.preventDefault();
              getPost(p+1);
              setPage(p+1)
            }}
          >
            Next
          </button>
        {posts.map(post => {
          // if(post.postData.products&&post.postData.products.length>0)console.log(post)
          return post.postData&&<div>
            <div style={{ display:"flex", overflowX: "auto" }}>
              {post && post.postData.data && post.postData.data.map(i => {
                return (
                  <img
                    src={post.type == 'VIDEO' ? i.thumbnail : i.url}
                    // style={{ height: '100px', width: '100px' }}
                    // style={{ maxHeight: height, maxWidth: width }}
                    style={{ maxHeight: height<=width?height/2:height, maxWidth: height<=width?width/2:width, }}

                  />
                )
              })}
              {post && post.postData.invoice && post.postData.invoice.map(i => {
                return (
                  <a href={i.url} target="_blank" rel="noopener noreferrer">Check Invoice</a>
                  // <img
                  //   src={i.url}
                  //   // style={{ height: '100px', width: '100px' }}
                  //   // style={{ maxHeight: height, maxWidth: width }}
                  //   style={{ maxHeight: height<=width?height/2:height, maxWidth: height<=width?width/2:width, }}

                  // />
                )
              })}
            </div>
            {post && <h3>Title: {post.postData.caption &&post.postData.caption.length>0 && post.postData.caption[0]&&post.postData.caption[0].value ? post.postData.caption[0].value : null}</h3>}
            {post && <h3>Description: {post.postData.desc && post.postData.desc.length>0 && post.postData.desc[0]&&post.postData.desc[0] ? post.postData.desc[0].value : null}</h3>}
            {post.postData.products.map((i,index) => {
                return (
                  <div>
                    <h5>Product :{i.name}</h5>
                    {
                      post.postData.invoice && post.postData.invoice.length > 0 &&
                      <button
                        disabled={i.ordered ? true : false}
                        onClick={async (e) => {
                          e.preventDefault();
                          let products = post.postData.products
                          // let descArray = post.postData.desc
                          // let titleArray = post.postData.caption
                          // console.log(descArray)
                          // console.log(titleArray)
                          // descArray[0].value = "invo1"
                          // titleArray[0].value = "invo1"
                          products[index].ordered = true
                          let body = {
                            postId: post.postData._id,
                            inflncrId: post._id,
                            products: products,
                            // desc: descArray,
                            // caption: titleArray,
                          };
                          console.log(body)
                          let res = await updatePostDetWeb(body)
                          console.log(res)
                        }}
                      >Verify</button>
                    }
                  </div>
                )
              })}
            <div>
            </div>
          </div>
        })}
          </div>
      <Footer />
    </div>
  );
}

export default ModerateReviews;