import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { AppSearchQuery, getAllInfluencersPostsWithMentionsByEmail, getAllUniqueSearcheswithUserInfo, getAllUniqueWithFilterKeySearcheswithUserInfo, getSinlgeInfluencerDetailsWithOutMobileAndEmail, getInfluencerPosts, getPostsByTaggedStoreUserId, getProductDetails, getSingleStoreDetailsByUserIdWithOutMobileAndEmail, getSinlgeInfluencerPostsByEmail, getUniqueSearcheswithUserInfo, registerRetailer, saveOrder, getAllInfluencersPosts, getAllInfluencersPostsWithoutMobileAndEmail } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import SubmitPopover3 from "../../components/SubmitPopover/SubmitPopover3";
import styles1 from "../Home/index.module.css"
import BrandHeader from "./BrandHeader";
import StoreHeader from "./StoreHeader";
import InfluencerHeader from "./InfluencerHeader";
import IndependentHeader from "./IndependentHeader";
import { rediectLink } from "../../api/fetchBackend";

const ViewReviews = (props) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [brandDetails, setBrandDetails] = useState({
    quantity:0,
    
    firstName: "",
    lastName: "",
    houseNumber:"",
    brandName: "",
    contactNum: "",
    emailId: "",
    street:"",
    ladnmark: "",
    city: "",
    state:"",
    country:"",
    pincode:""
  });
  const [error, setError] = useState({
    quantity:false,
    
    firstName: false,
    lastName:false,
    houseNumber:false,
    brandName: false,
    contactNum: false,
    emailId: false,
    street:false,
    ladnmark: false,
    city: false,
    state:false,
    country:false,
    pincode:false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product,setProduct] = useState()
  let {email,storeUserId,brandUserId,influencerId,validated}=useParams()
  const [orderLoading,setOrderLoading] = useState(false)
  const [searchKey,setSearchKey] = useState()
  const [queries,setQueries] = useState([])
  const [shopDetails,setShopDetails] = useState()
  const [posts,setPosts] =  useState([])
  const [influencerDetails,setInfluencerDetails] = useState()

  useEffect(()=>{
    getSinlgeInfluencerDetailsWithOutMobileAndEmail(influencerId)
        .then(res => {
          // console.log(res)
          if (res.Data) {
              setInfluencerDetails(res.Data)
          }
            // })
          // }
        })
  },[])

  useEffect(()=>{
    if(storeUserId||brandUserId){
      getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId?storeUserId:brandUserId)
        .then(res => {
          console.log(res.Data.name)
          if (res.status == 200) {
            setShopDetails(res.Data)
            // if(res.Data.user_id && res.Data.user_id.isBrand){
            //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
            // }else{
  
            //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
            // }
          }
        })
    }
  },[])

  useEffect(()=>{
    
  },[])

  useEffect(()=>{
    if(email || influencerId){
        ((storeUserId||brandUserId)?getPostsByTaggedStoreUserId(email):getInfluencerPosts(influencerId))
          .then(res => {
            console.log("-------------------")
            console.log(res)
            if (((storeUserId||brandUserId))&&res.data && res.data.length>0) {
              let arr =[]
              res.data.map(d=>{
                let obj ={} 
                obj = d.postData
                obj.mentionInfId=d._id
                arr.push(obj)
              })
              setPosts(arr)
              // getQuriesWithKey(res.Data.name)
              setSearchKey(res.data.name)
              let body = {
                "pagination": {
                  "page": 1,
                  "limit": 10
                },
              }
            }
            if (influencerId&&res.Data && res.Data.length>0) {
              setPosts(res.Data)
              // getQuriesWithKey(res.Data.name)
              setSearchKey(res.Data.name)
              let body = {
                "pagination": {
                  "page": 1,
                  "limit": 10
                },
              }
            }
          })
      // }
    }else{
      getAllInfluencersPostsWithoutMobileAndEmail(1)
        .then(res => {
          // console.log(res)
          if (res.status == 200) {
            let arr = []
            res.Data.length>0 && res.Data.map(i=>{
              let arrIn  = {}
              arrIn = i.postData
              arrIn.mentionInfId = i._id
              arr.push(arrIn)
            })
            setPosts(arr)
            // if(res.Data.user_id && res.Data.user_id.isBrand){
            //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
            // }else{
  
            //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
            // }
          }
        })
    }
  },[])


  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

// useEffect(()=>{
//   console.log(productId)
//   getProductDetails(productId)
//       .then(res => {
//         // console.log(res.Data)
//         // let arr= res.Data
//         // if(arr && arr.length>0){
//           // arr.map(i=>{
            
//             // let arr2 = arr.map((item, index) => {
//               // console.log(item)
//               // return { key: index + 1, text: item.prdct_attributes.prdct_name, value: item };
//             // });
//             // setProducts([...[{key:-1,text:'Select Product',value:null}],...arr2]);
//             // arr2.sort( (a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
//             setProduct(res.Data);
//           // })
//         // }
//       })
// },[])

  return (
    <div className={styles.body}>
      <SubmitPopover3
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      {!influencerId&&!brandUserId&&!storeUserId&&<IndependentHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {influencerId&&influencerDetails&&<InfluencerHeader influencerId={influencerId} influencerDetails={influencerDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {brandUserId&&shopDetails&&<BrandHeader storeUserId={brandUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {storeUserId&&shopDetails&&<StoreHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      
      <div  className={styles1.sectionForm} style={{maxHeight:height,overflowY:"scroll"}}>
          <h1>Recent Reviews</h1>
        {posts.map(post => {
          // console.log(post)
          return <div>
            <div style={{ display:"flex", overflowX: "auto" }}>
              {post && post.data && post.data.map(i => {
                return (
                  <img
                    src={post.type == 'VIDEO' ? i.thumbnail : i.url}
                    // style={{ height: '100px', width: '100px' }}
                    // style={{ maxHeight: height, maxWidth: width }}
                    style={{ maxHeight: height<=width?height/2:height, maxWidth: height<=width?width/2:width, }}

                  />
                )
              })}
            </div>
            {post && <h3>Title: {post.caption &&post.caption.length>0 && post.caption[0]&&post.caption[0].value ? post.caption[0].value : null}</h3>}
            {post && <h3>Description: {post.desc && post.desc.length>0 && post.desc[0]&&post.desc[0] ? post.desc[0].value : null}</h3>}
            {post.products&&brandUserId&&<div>
                {/* <div style={{ display: "flex", overflowX: "auto" }}>
                      <img
                        src={post.products.productImage}
                        // style={{ height: '100px', width: '100px' }}
                        style={{ maxHeight: height, maxWidth: width }}
                      />
                </div> */}
                {<Link to={`/buyfrombrandreview/${post.mentionInfId}/${post._id}/${brandUserId}/${post.products.productID}`} className={styles1.btn}>
                  {/* Buy Now */}
                {<h5>Product :{post.products.name} {post.products.ordered?'(Verified Purchase)':''}</h5>}
                </Link>}
              </div>}
              {/* {console.log(post.links[0])} */}
              <div style={{ display:"flex", overflowX: "auto" }}>
              {post && post.links && post.links[0] && post.links[0].data && post.links[0].data.length>0 && post.links[0].data.map(i => {
                
                console.log(i)
                return(

                 <a href={`${rediectLink(i.url)}`}  target="_blank" className={styles1.btn}>
                {i.name}
                </a>
                )
                // return i.map(l=>{
                // })
                
                
              })}
            </div>
              {post.products&&storeUserId&&<div>
                {/* <div style={{ display: "flex", overflowX: "auto" }}>
                      <img
                        src={post.products.productImage}
                        // style={{ height: '100px', width: '100px' }}
                        style={{ maxHeight: height, maxWidth: width }}
                      />
                </div> */}
                  {/* Buy Now */}
                {<h5>Product :{post.products.name} {post.products.ordered?'(Verified Purchase)':''}</h5>}
              </div>}
              {!storeUserId&&!brandUserId&&post.products&&post.products.length>0&&post.products.map(p=>{
                return <h5>Product :{p.name} {p.ordered?'(Verified Purchase)':''}</h5>
              })}
              {post&&(influencerId)&&<a href={`${APP_URL}post/${influencerId}/${post._id}`} className={styles1.btn}>
                  Explore Details
                  </a>}
              {post&&(brandUserId||storeUserId || post.mentionInfId)&&<a href={`${APP_URL}post/${post.mentionInfId}/${post._id}`} className={styles1.btn}>
                  Explore Details
                  </a>}
            <div>
              {post && (influencerId) && <Link to={`/taginfluencerreview/${influencerId}/${post._id}/${influencerId}`} className={styles1.btn}>
                Tag with Post
              </Link>}
              {post && (brandUserId) && <Link to={`/tagbrandreview/${post.mentionInfId}/${post._id}/${brandUserId}`} className={styles1.btn}>
                Tag with Post
              </Link>}
              {post && (storeUserId) && <Link to={`/tagstorereview/${post.mentionInfId}/${post._id}/${storeUserId}`} className={styles1.btn}>
                Tag with Post
              </Link>}
              {post && !storeUserId && !influencerId && !brandUserId && <Link to={`/tagreview/${post.mentionInfId}/${post._id}`} className={styles1.btn}>
                Tag with Post
              </Link>}
            </div>
            <div>
            {shopDetails&&shopDetails.affiliate_code&&<h5>
            CODE:  {shopDetails.affiliate_code}
                </h5>}
                </div>
          </div>
        })}
          </div>
      <Footer />
    </div>
  );
}

export default ViewReviews;