import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { AppSearchQuery, getProductDetails, registerRetailer, saveOrder } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import SubmitPopover3 from "../../components/SubmitPopover/SubmitPopover3";
import styles1 from "../Home/index.module.css"

const StoreHeader = ({storeUserId,shopDetails,loggedInUser,navigate}) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div className={styles1.sectionReviewHeader}>
        <header className={styles1.header}>
          <Link to="/">
          <img
            className={styles1.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
            </Link>
            {height>width&&<div className={styles1.menu}>
            <img
              className={styles1.burgerMenu}
              src={isMenuOpen ? Cross : BurgerMenu}
              alt="burger-menu"
              style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
              onClick={() => setIsMenuOpen((prevState) => !prevState)}
            />
            <div
              className={styles1.headerBtns}
              style={{
                transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
              }}
            >
              <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/storeaskquery/${storeUserId?storeUserId:""}`} className={styles1.btn}>
            Ask a Query
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/storeanswerquery/${storeUserId?storeUserId:""}`} className={styles1.btn}>
            Answer Query
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/addstorereview/${storeUserId?storeUserId:""}`} className={styles1.btn}>
            Add Review
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/viewstorereviews/${storeUserId}/${storeUserId}`} className={styles1.btn}>
            View Reviews
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/validatedstorereviews/${storeUserId}/${storeUserId}`} className={styles1.btn}>
                Tagged Reviews
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
          <Link to={`/viewstore/true/${storeUserId?storeUserId:""}`} className={styles1.btn}>
            Digital Store
          </Link>
                </div>
                {loggedInUser &&
              <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/addproduct`} className={styles1.btn}>
                  Manage Products
                </Link>
              </div>
            }
            </div>
          </div>
          }
          {height<=width&&<div className={styles1.menu}>
            <img
              className={styles1.burgerMenu}
              src={Cross}
              alt="burger-menu"
              style={{ transform: "rotate(45deg)" }}
              // onClick={() => setIsMenuOpen((prevState) => !prevState)}
            />
            <div
              className={styles1.headerBtns}
              style={{
                transform: "scale(1,1)",
              }}
            >
              <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/storeaskquery/${storeUserId?storeUserId:""}`} className={styles1.btn}>
            Ask a Query
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/storeanswerquery/${storeUserId?storeUserId:""}`} className={styles1.btn}>
            Answer Query
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/addstorereview/${storeUserId?storeUserId:""}`} className={styles1.btn}>
            Add Review
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/viewstorereviews/${storeUserId}/${storeUserId}`} className={styles1.btn}>
            View Reviews
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/validatedstorereviews/${storeUserId}/${storeUserId}`} className={styles1.btn}>
                Tagged Reviews
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
          <Link to={`/viewstore/true/${storeUserId?storeUserId:""}`} className={styles1.btn}>
            Digital Store
          </Link>
                </div>
            {loggedInUser &&
              <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/addproduct`} className={styles1.btn}>
                  Manage Products
                </Link>
              </div>
            }
            {loggedInUser &&
              <button
                onClick={(e) => {

                  localStorage.clear();
                  navigate('/login')
                }}
              >
                Logout
              </button>
            }
            </div>
          </div>}
        </header>
      {loggedInUser && height > width &&
        <div className={styles1.menu}>
          <button
            onClick={(e) => {

              localStorage.clear();
              navigate('/login')
            }}
          >
            Logout
          </button>
        </div>
      }
      </div>

    // <div>
    // <div className={styles1.joinOptns}>
    //       <Link to={`/storeaskquery/${storeUserId?storeUserId:""}`} className={styles1.btn}>
    //         Ask a Query
    //       </Link>
    //       <Link to={`/storeanswerquery/${storeUserId?storeUserId:""}`} className={styles1.btn}>
    //         Answer Query
    //       </Link>
    //       <Link to={`/addstorereview/${storeUserId?storeUserId:""}`} className={styles1.btn}>
    //         Add Review
    //       </Link>
    //     </div>
    //     <div className={styles1.joinOptns}>
    //       {/* <Link to={`/viewstorereviews/${storeUserId}/${shopDetails&&shopDetails.user_id&&shopDetails.user_id.email?shopDetails.user_id.email:""}`} className={styles1.btn}>
    //         View Reviews
    //       </Link>
    //       <Link to={`/validatedstorereviews/${storeUserId}/${shopDetails&&shopDetails.user_id&&shopDetails.user_id.email?shopDetails.user_id.email:""}`} className={styles1.btn}>
    //         Tagged Reviews
    //       </Link> */}
    //       <Link to={`/viewstorereviews/${storeUserId}/${storeUserId}`} className={styles1.btn}>
    //         View Reviews
    //       </Link>
    //       <Link to={`/validatedstorereviews/${storeUserId}/${storeUserId}`} className={styles1.btn}>
    //         Tagged Reviews
    //       </Link>
    //     </div>
    // </div>
  );
}

export default StoreHeader;