import React from "react";

import styles from "./index.module.css";

const SubmitPopover = ({showPopover=false, success=false, closePopover, message=message?message:"Your account has been saved"}) => {
    return (
      <div
        className={styles.container}
        style={{ borderColor: success ? "#5cf07b" : "#ff5d5f", right: showPopover ? "10px" : "-350px" }}
      >
        <img
          src={
            success
              ? require("../../assets/icons/green-tick.png")
              : require("../../assets/icons/red-cross.png")
          }
          alt={success ? "green-tick" : "red-cross"}
        />
        <div>
          <h4>{success ? "Success" : "Error"}</h4>
          <p>{success ? message : "There was an error"}</p>
        </div>
        <button onClick={closePopover}>CLOSE</button>
      </div>
    );
}

export default SubmitPopover;