import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { getAllProductsOnUserId, getEUserDetailsbyEmailId, getEUserDetbyEmail, getProductDetails, getSingleStoreDetailsByUserIdWithOutMobileAndEmail, getSpecificPost, getUserbyEmail, registerRetailer, saveB2BOrder2, saveB2COrderWebWithoutPay, saveOrder2, saveOrder3, saveOrderWebWithoutPay } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import { Dropdown } from "semantic-ui-react";
import styles1 from "../Home/index.module.css"

const Checkout = (props) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [referrerValidated,setReferrerValidated] = useState(false)
  const [brandDetails, setBrandDetails] = useState({
    quantity:0,
    
    firstName: "",
    lastName: "",
    houseNumber:"",
    brandName: "",
    contactNum: "",
    referrercontactNum: "",
    emailId: "",
    street:"",
    ladnmark: "",
    city: "",
    state:"",
    country:"",
    pincode:"",
  });
  const [error, setError] = useState({
    quantity:false,
    
    firstName: false,
    lastName:false,
    houseNumber:false,
    brandName: false,
    contactNum: false,
    referrercontactNum:false,
    emailId: false,
    street:false,
    ladnmark: false,
    city: false,
    state:false,
    country:false,
    pincode:false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product,setProduct] = useState()
  const [postDetails,setPostDetails] = useState()
  const [influencerDetails,setInfluencerDetails] = useState()
  const [buyerDetails,setBuyerDetails] = useState()
  let {storeUserId, productId, mentionInfId, mentionPostId,b2b}=useParams()
  const [orderLoading,setOrderLoading] = useState(false)
  const [productsOnBrand, setProductsOnBrand] = useState([])
  const [loading, setLoading] = useState(false)
  const loggedInUser = JSON.parse(localStorage.getItem("user-data"));
  const token = localStorage.getItem("auth_token");

  useEffect(()=>{
    
  },[])
  useEffect(()=>{
    // console.log(productId)
    getProductDetails(productId)
        .then(res => {
          console.log(res)
          setProduct(res.Data);
          if(loggedInUser){
            let Dpin = "100000"
            setBrandDetails({
              ...brandDetails,
              ["firstName"]: loggedInUser.ownerName,
              ["lastName"]: loggedInUser.ownerName,
              ["houseNumber"]: "NA",
              ["contactNum"]: loggedInUser._id==res.Data.userId._id?"":loggedInUser.mobile,
              ["emailId"]: loggedInUser.email,
              ["street"]: "NA",
              ["ladnmark"]: "NA",
              ["city"]: "NA",
              ["state"]: "NA",
              ["country"]: "NA",
              ["pincode"]: Number(Dpin),
              // ["quantity"]: product.sample_qty,
            })  
          }
        })
  },[])


  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    if (width >= 834) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (brandDetails.emailId && brandDetails.name && brandDetails.contactNum && brandDetails.brandName && brandDetails.products && brandDetails.link) {
      setSubmitBtnDisabled(false);
    }
  }, [brandDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

useEffect(()=>{
  getSpecificPost(mentionInfId,mentionPostId)
      .then(res => {
        console.log(res.Data)
        if(res.status==200){

          setPostDetails(res.Data);
        }
          // })
        // }
      })
},[])

  const handleBrandDetails = (name, val) => {
    setBrandDetails({
      ...brandDetails,
      [name]: val,
    });
  };

  const handleEmailSubmit = (e) => {
    // e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(businessEmail);

    testEmailId ? setBusinessEmailError(false) : setBusinessEmailError(true);

    if (testEmailId) {
      handleBrandDetails("emailId", businessEmail);
      window.location.replace("/joinasbrand#brand-form");
    };
  }

  const handleSubmit = async(e) => {
    e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(brandDetails.emailId);

    // name validation
    const regexName = /^[a-zA-Z ]+$/;
    const testFirstName = brandDetails.firstName//regexName.test(brandDetails.firstName);
    const testLasttName = brandDetails.lastName//regexName.test(brandDetails.lastName);

    // brand name validation
    // const testBrandName = regexName.test(brandDetails.brandName);

    // contact number validation
    const regexContactNum = /^\d{10}$/;
    const testContactNum = regexContactNum.test(
      parseInt(brandDetails.contactNum)
    );
    const testPincode =  /^\d{6}$/.test(
      parseInt(brandDetails.pincode)
    );

    //TODO: products and link validation remaining

    setError({
      quantity:brandDetails.quantity>0?false:true,
      emailId: !testEmailId,
      firstName: !testFirstName,
      lastName:!testLasttName,
      // brandName: !testBrandName,
      houseNumber:brandDetails.houseNumber==''?true:false,
      // brandName: "",
      street:brandDetails.street==''?true:false,
      ladnmark: brandDetails.ladnmark==''?true:false,
      city: brandDetails.city==''?true:false,
      state:brandDetails.state==''?true:false,
      country:brandDetails.country==''?true:false,
      pincode:!testPincode,
      contactNum: !testContactNum,
    });

    if (!testEmailId || !testFirstName || !testLasttName || !testContactNum || !testPincode) {
      setShowError(true);
      setSubmitBtnDisabled(true);
    } else {
      setShowError(false);
    }

    if (testEmailId && testFirstName && testLasttName && testContactNum) {
      // final submit function here
      let userAddress = {
        // ownerName: brandDetails.name,
        // email: brandDetails.emailId,
        // mobile: brandDetails.contactNum,
        // password: brandDetails.contactNum,
        // companyName: brandDetails.brandName,
        // sellCategory: brandDetails.products,
        // websiteLink: brandDetails.link,
          firstName:brandDetails.firstName,
          lastName:brandDetails.lastName,
          houseNo: brandDetails.houseNo,
          street: brandDetails.street,
          landmark: brandDetails.ladnmark,
          city: brandDetails.city,
          state: brandDetails.state,
          country: brandDetails.country,
          pinCode: brandDetails.pincode,
          email:brandDetails.emailId,
          mobile:brandDetails.contactNum
        
      };

      const body = {address: userAddress,sellerDetails:null,bookingCode:null,user:storeUserId,productId:product._id,quantity:brandDetails.quantity,mentionInfId:mentionInfId,mentionPostId:mentionPostId, postDetails:postDetails , influencerDetails:influencerDetails
      ,userData : {
        profilePic: {
          name: 'My pICTURE',
          url: 'https://xm-customer.s3.ap-south-1.amazonaws.com/images/16139913872243qF9NbThu.jpg',
        },
        name: brandDetails.firstName + " "+ brandDetails.lastName ,
        email: brandDetails.emailId,
        mobile: brandDetails.contactNum,
        password: '12345',
        gender: 'Male',
        dob: '1994-08-28',
        address: {
          firstName: brandDetails.firstName,
          lastName: brandDetails.lastName,
          mobile: brandDetails.contactNum,
          line1: 'Patil Nivas',
          line2: 'Basavkalyan',
          landmark: brandDetails.ladnmark,
          houseNumber: brandDetails.houseNumber,
          street: brandDetails.street,
          city: brandDetails.city,
          pinCode: brandDetails.pincode,
          state: brandDetails.state,
          country: brandDetails.country,
        },
      },};
      if(loggedInUser){
        const EURetailerDetails = await getEUserDetailsbyEmailId({email:loggedInUser.mobile})
        // console.log(EURetailerDetails)
        if(loggedInUser._id==product.userId._id && EURetailerDetails.Data){
          body.user = EURetailerDetails.Data._id
          // console.log(body)
          saveB2COrderWebWithoutPay(body)
            .then(res => {
              // console.log(res)
              if(res.status==201 || res.status==200){

                setShowPopover(true)
                setSubmitSuccess(true)
              }else{
                alert(res.message)
                // setShowPopover(false)
                // setSubmitSuccess(false)
              }
              return

            })
      return
        }
      }
      if(buyerDetails){
        body.user = buyerDetails._id
        if(loggedInUser.email=="gouravroy09@gmail.com"){
          saveOrderWebWithoutPay(body)
            .then(res => {
              // console.log(res)
              setShowPopover(true)
              setSubmitSuccess(true)
              return

            })
      return
        }
        saveB2BOrder2(body)
      .then(res=>{
        // console.log(res)
        // return
          const cashfree = window.Cashfree({
            mode: "production" //or production
          });

          let checkoutOptions = {
            paymentSessionId: res.cfOrder.paymentSessionId,
            redirectTarget:'_blank',// `https://www.eyseapp.com/thankyou`,
            returnUrl:`https://www.eyseapp.com/thankyou/b2b`,
          }
          cashfree.checkout(checkoutOptions).then(function(result){
            if(result.error){
              alert(result.error.message)
            }
            if(result.redirect){
              console.log("Redirection")
              // setShowPopover(true)
              // setSubmitSuccess(true)
            }
          });
      })
      return
      }

      if(postDetails){
        saveOrder3(body)
      .then(res=>{
        // console.log(res)
        // return
          const cashfree = window.Cashfree({
            mode: "production" //or production
          });

          let checkoutOptions = {
            paymentSessionId: res.cfOrder.paymentSessionId,
            redirectTarget:'_blank',// `https://www.eyseapp.com/thankyou`,
            returnUrl:`https://www.eyseapp.com/thankyou/${mentionInfId}/${mentionPostId}/${storeUserId}/${product._id}`,
          }
          cashfree.checkout(checkoutOptions).then(function(result){
            if(result.error){
              alert(result.error.message)
            }
            if(result.redirect){
              console.log("Redirection")
              // setShowPopover(true)
              // setSubmitSuccess(true)
            }
          });
      })
      return
      }
      if(!storeUserId && productId){
        saveOrder3(body)
      .then(res=>{
        // console.log(res)
        // return
          const cashfree = window.Cashfree({
            mode: "production" //or production
          });

          let checkoutOptions = {
            paymentSessionId: res.cfOrder.paymentSessionId,
            redirectTarget:'_blank',// `https://www.eyseapp.com/thankyou`,
            returnUrl:`https://www.eyseapp.com/thankyou/${product._id}`,
          }
          cashfree.checkout(checkoutOptions).then(function(result){
            if(result.error){
              alert(result.error.message)
            }
            if(result.redirect){
              console.log("Redirection")
              // setShowPopover(true)
              // setSubmitSuccess(true)
            }
          });
      })
      return
      }
      if(storeUserId){
        saveOrder2(body)
        .then(res=>{
          // console.log('saveorder.....', JSON.stringify(res ));
          // return
          // if (
          //   res.status === 200 ||
          //   res.status === 201 
          // ) {
            // console.log('saveorder.....', JSON.stringify(res ));
            const cashfree = window.Cashfree({
              mode: "production" //or production
            });
  
            let checkoutOptions = {
              paymentSessionId: res.cfOrder.paymentSessionId,
              redirectTarget:'_blank',// `https://www.eyseapp.com/thankyou`,
              returnUrl:`https://www.eyseapp.com/thankyou`,
            }
            cashfree.checkout(checkoutOptions).then(function(result){
              if(result.error){
                alert(result.error.message)
              }
              if(result.redirect){
                console.log("Redirection")
                // setShowPopover(true)
                // setSubmitSuccess(true)
              }
            });
  
  
            // const paymentMessage = document.getElementById("paymentMessage");
            // document.getElementById("checkout").addEventListener("click", function () {
            //   paymentMessage.innerText = "";
            //   paymentMessage.classList.remove("alert-danger");
            //   paymentMessage.classList.remove("alert-success");
            //   cashfree
            //     .checkout({
            //       paymentSessionId: document.getElementById("paymentSessionId").value,
            //       returnUrl: document.getElementById("returnUrl").value,
            //       redirectTarget: "_blank"
            //     })
            //     .then(function () {
            //       console.log("on going redirection");
            //     });
            // });
          // }
          // setShowPopover(true);
          // console.log(JSON.stringify(res));
          // if (res.status === 201) {
          //   setSubmitSuccess(true);
          // } else {
          //   setSubmitSuccess(false);
          // } 
          // setApiError(res.message)
          // setShowError(true);
        })
      }
    }
  };

  // const saveOrder1 = async () => {
  //   setOrderLoading(true);

  //   // const axiosConfig = {
  //   //   headers: {
  //   //     Authorization: token,
  //   //     //  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MjY2ZTJiYjdkY2QxZmEzOTY3NTVjM2MiLCJuYW1lIjoiYWxvayIsImVtYWlsIjoiU2xva0AxMjMuZ21haWwuY29tIiwibW9iaWxlIjoiNjM5MzQ4OTQ3NiIsInJvbGUiOlsiRU5EX1VTRVIiLCJJTkZMVUVOQ0VSIl0sImlhdCI6MTY1NTkwMjUyNCwiZXhwIjoxNjU4NDk0NTI0fQ.JEz-GLHOtxK_yEpCfnPjwkohl1sZuLo1NzHvFBvCEfs',
  //   //   },
  //   // };
  //   const body = {address: useSavedAddress ? savedUserAddress : userAddress,sellerDetails:sellerDetails?sellerDetails:null,bookingCode:bookingCode&&isValidBookingCode?bookingCode:null};
  //   const url = `${BASE_URI}euOrder/saveOrder`;

  //   try {
  //     axios.post(url, body, axiosConfig).then(res => {
  //       console.log('saveorder.....', JSON.stringify(res?.data?.cfOrder ));
  //       if (
  //         // res.status === 200 ||
  //         // res.status === 201 || 
  //         res?.data?.cfOrder?.paymentSessionId
  //       ) {
  //         console.log('CashfreePayment.....',res?.data?.cfOrder?.paymentSessionId);
  //         navigation.navigate('CashfreePayment', {
  //           order_token: res?.data?.cfOrder?.paymentSessionId,
  //           order_id: res?.data?.cfOrder?.orderId,
  //           navigation: navigation,
  //           isOrderBooking:true
  //         });
  //       } else if (res.status === 404) {
  //         if (Platform.OS === 'android') {
  //           ToastAndroid.showWithGravityAndOffset(
  //             res.message,
  //             ToastAndroid.SHORT,
  //             ToastAndroid.BOTTOM,
  //             0,
  //             80,
  //           );
  //           console.log(res.message);
  //         } else {
  //           AlertIOS.alert(res.message);
  //         }
  //       }
  //       setOrderLoading(false);
  //     });
  //   } catch (err) {
  //     console.log(JSON.stringify(err))

  //     await Promise.all([setOrderLoading(false)]).then(()=>{});
  //     if (Platform.OS === 'android') {
  //       err
  //         ? ToastAndroid.showWithGravity(
  //             err.message,
  //             ToastAndroid.SHORT,
  //             ToastAndroid.BOTTOM,
  //           )
  //         : ToastAndroid.showWithGravity(
  //             'Something went wrong!',
  //             ToastAndroid.SHORT,
  //             ToastAndroid.BOTTOM,
  //           );
  //     } else {
  //       err
  //         ? AlertIOS.alert(err.message)
  //         : AlertIOS.alert('Something went wrong!');
  //     }
  //     setOrderLoading(false);
  //   }
  // };
  useEffect(()=>{
    getProductsonBrand()
  },[])
  
  const getProductsonBrand = async() => {
    // console.log(storeUserId)
    // return
    setLoading(true)
    const res = await getAllProductsOnUserId(storeUserId)
    setLoading(false)
        // console.log(JSON.stringify(res.Data))
        const data = res.Data;
        if (data && data.length > 0) {
          let arr = res.Data
          if (arr && arr.length > 0) {
            arr.map(i => {

              let arr2 = arr.map((item, index) => {
                // console.log(item)
                return { key: index + 1, text: item.prdct_attributes.tagName, value: item };
              });
              // setProducts([...[{key:-1,text:'Select Product',value:null}],...arr2]);
              arr2.sort((a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
              // setProducts(arr2);
              setProductsOnBrand(arr2);
              // setFilterProducts(arr2)
            })
          }
        }
  };

  return showPopover?
  <div className={styles.body}>
    <SubmitPopover
    message={"Your order has been placed"}
      showPopover={showPopover}
      success={submitSuccess}
      closePopover={() => setShowPopover(false)}
    /></div>:(
    <div className={styles.body}>
      <SubmitPopover
      message={"Your order has been placed"}
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      {/* {console.log(loggedInUser)} */}
      {/* {console.log(userData)}
      {console.log(token)} */}
      {/* <div className={styles.header}>
        <Link to="/">
          <img
            className={styles.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
        </Link>
        <img
          className={styles.burgerMenu}
          src={isMenuOpen ? Cross : BurgerMenu}
          alt="burger-menu"
          style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
          onClick={() => setIsMenuOpen((prevState) => !prevState)}
        />
        <div
          className={styles.headerBtn + " " + styles.btn}
          style={{
            transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
          }}
          // onClick={}
        >
          <a href="#brand-form">Register Now</a>
        </div>
      </div> */}
      <div className={styles.sectionHead}>
        <div className={styles.pageHeadings}>
          <span className={styles.pageHeading} ref={h1}></span>{" "}
          {/* <span
            className={styles.pageHeading + " " + styles.accent}
            ref={accentH1}
          ></span> */}
        </div>
        {/* <div className={styles.emailInput}>
          <input
            type="text"
            placeholder="Enter your Business Email ID"
            style={{
              borderColor: businessEmailError ? colors.red : colors.blue,
            }}
            value={businessEmail}
            onChange={(e) => setBusinessEmail(e.target.value)}
          />
          <a
            className={styles.btn}
            // href="#brand-form"
            onClick={(e) => handleEmailSubmit(e)}
          >
            Schedule a Demo
          </a>
        </div> */}
      </div>
      <div className={styles.border}>
        <div></div>
      </div>
      <div className={styles.sectionForm} id="brand-form">
        <h1>Checkout</h1>
        {/* {localStorage&&console.log(loggedInUser._id==product._id)} */}
        {/* {console.log(loggedInUser._id==product._id)} */}
        {/* {console.log(product)} */}
        {/* <p>
         Test <span className={styles.accent}>Test</span>Test
        </p> */}
          <form action="" className={styles.brandForm}>
            {!postDetails&&!b2b&&
            <div>
            <label htmlFor="">Referrer Contact Number</label>
            <input
              type="number"
              name="referrercontactNum"
              placeholder="Enter Referrer contact number"
              value={brandDetails.referrercontactNum}
              onChange={(e) =>
                handleBrandDetails(e.target.name, e.target.value)
              }
              style={{
                borderColor: error.referrercontactNum ? colors.red : colors.blue,
              }}
            />
            <button
                type="validate"
                style={{
                  backgroundColor: submitBtnDisabled ? "#677890" : "#001E46",
                  cursor: submitBtnDisabled ? "default" : "pointer",
                }}
                // disabled={submitBtnDisabled}
                onClick={async(e) => {
                  e.preventDefault();
                  const res = await getEUserDetailsbyEmailId({email:brandDetails.referrercontactNum})
                  console.log(res)
                  if(res.status!=404){
                    setReferrerValidated(true)
                    setInfluencerDetails(res.Data)
                  }else{
                    setReferrerValidated(false)
                    setInfluencerDetails(null)
                  }
                }}
              >
                {referrerValidated?"Validated":"Validate"}
              </button>
          </div>}
          {b2b&&
            <div>
            <label htmlFor="">Buyer Registered Contact Number</label>
            <input
              type="number"
              name="referrercontactNum"
              placeholder="Enter Registered contact number"
              value={brandDetails.referrercontactNum}
              onChange={(e) =>
                handleBrandDetails(e.target.name, e.target.value)
              }
              style={{
                borderColor: error.referrercontactNum ? colors.red : colors.blue,
              }}
            />
            <button
                type="validate"
                style={{
                  backgroundColor: submitBtnDisabled ? "#677890" : "#001E46",
                  cursor: submitBtnDisabled ? "default" : "pointer",
                }}
                // disabled={submitBtnDisabled}
                onClick={async(e) => {
                  e.preventDefault();
                  const res = await getUserbyEmail(brandDetails.referrercontactNum)
                  if(res.status!=404 && res.Data){
                    if(res.Data.role=='USER'){
                      alert('Seller Account not activated!!')
                      return
                    }
                    setReferrerValidated(true)
                    setBuyerDetails(res.Data)
                    // setBrandDetails({
                    //   ...brandDetails,
                    //   ["quantity"]: product.sample_qty,
                    // })
                    let addINfo1 = res.Data.additionalInfo
                    // console.log(res.Data.additionalInfo.address)
                    let address = addINfo1[0].address[0]
                    // handleBrandDetails("firstName", res.Data.ownerName)
                    // handleBrandDetails("lastName", res.Data.ownerName)
                    // handleBrandDetails("houseNumber", address.address)
                    // handleBrandDetails("contactNum", res.Data.mobile)
                    // handleBrandDetails("emailId", res.Data.email)
                    // handleBrandDetails("street", address.address)
                    // handleBrandDetails("ladnmark", address.address)
                    // handleBrandDetails("city", address.city)
                    // handleBrandDetails("state", address.state)
                    // handleBrandDetails("country", address.country)
                    // handleBrandDetails("pincode", address.pinCode)

                    setBrandDetails({
                      ...brandDetails,
                      ["firstName"]: res.Data.ownerName,
                      ["lastName"]: res.Data.ownerName,
                      ["houseNumber"]: address.address,
                      ["contactNum"]: res.Data.mobile,
                      ["emailId"]: res.Data.email,
                      ["street"]: address.address,
                      ["ladnmark"]: address.address,
                      ["city"]: address.city,
                      ["state"]: address.state,
                      ["country"]: address.country,
                      ["pincode"]: address.pinCode,
                      ["quantity"]: product.sample_qty,
                    })  
                  }else{
                    setReferrerValidated(false)
                    setBuyerDetails(null)
                  }
                }}
              >
                {referrerValidated?"Validated":"Validate"}
              </button>
          </div>}
            <div>
            {postDetails && height>width?<div><i htmlFor="">Review Images :</i></div>:null}
            {postDetails && height <= width &&
                    <div>
                      <label htmlFor="">Review Images :</label>
                    </div>}
            {postDetails && postDetails.postData && postDetails.postData.data && postDetails.postData.data.length>0 && postDetails.postData.data.map(i=>{
              return (
                // <div className={styles.socialMediaLinks}>
                //   <a
                //   href={i.type=='VIDEO'?i.thumbnail:i.url}
                //   target={"_blank"}
                // ></a>
                // </div>
                // <a href={i.type=='VIDEO'?i.thumbnail:i.url} target="_blank" rel="noopener noreferrer">
                  <img
                    // className={styles.socialMediaLinks}
                    // src={require("../../assets/images/iphone-mockup-sec1-1.png")}
                    src={postDetails.postData.type=='VIDEO'?i.thumbnail:i.url}
                    // style={{height:'100px',width:'100px'}}
                    style={{ maxHeight: height<=width?height/2:height, maxWidth: height<=width?width/2:width, }}
                    // alt="app-homescreen-mockup"
                    // data-aos="fade-up"
                    // data-aos-duration="2000"
                  />
                 //</a>
              )
            })}
            </div>
            <div>

            <div>
            {postDetails && height>width?<div><i htmlFor="">Title :{postDetails && postDetails.postData && postDetails.postData.caption[0].value}</i></div>:null}
            {postDetails && height<=width &&
            <div>
              <label htmlFor="">Title :{postDetails && postDetails.postData && postDetails.postData.caption&& postDetails.postData.caption.length>0&& postDetails.postData.caption[0].value}</label>
            </div>}
            </div>
            <div>
            {postDetails && height>width?<div><i htmlFor="">Description :{postDetails && postDetails.postData && postDetails.postData.desc[0].value}</i></div>:null}
            {postDetails && height<=width &&
            <div>
              <label htmlFor="">Description :{postDetails && postDetails.postData && postDetails.postData.desc && postDetails.postData.desc.length>0 && postDetails.postData.desc[0].value}</label>
            </div>}
            </div>
            </div>

            {productsOnBrand && productsOnBrand.length > 0 ? <div>
              <label htmlFor="">Tag Product(s)</label>
              <Dropdown
                value={product}
                placeholder={"Tag a Product"}
                // disabled={selectedProduct?true:false}
                search
                fluid
                // multiple
                selection
                options={productsOnBrand}
                onChange={(event, { value }) =>
                // console.log(value)
                setProduct(value)
                  // handleReviewDetails("products", value)
                }
                // onSearchChange={(e) => getProductsonBrand(e.target.value)}
              />
            </div> : null}
            <div>
              <div>
            {product && product.prdct_attributes.media && product.prdct_attributes.media.map(i=>{
              return i.type!='VIDEO'?(
                // <div className={styles.socialMediaLinks}>
                //   <a
                //   href={i.type=='VIDEO'?i.thumbnail:i.url}
                //   target={"_blank"}
                // ></a>
                // </div>
                // <a href={i.type=='VIDEO'?i.thumbnail:i.url} target="_blank" rel="noopener noreferrer">
                  <img
                    // className={styles.socialMediaLinks}
                    // src={require("../../assets/images/iphone-mockup-sec1-1.png")}
                    src={i.type=='VIDEO'?i.thumbnail:i.url}
                    style={{height:'100px',width:'100px'}}
                    // alt="app-homescreen-mockup"
                    // data-aos="fade-up"
                    // data-aos-duration="2000"
                  />
                 //</a>
              ):null
            })}
          </div>
              <h3>Selected Product: {product && product.prdct_attributes.prdct_name}</h3>
              {product || (token && product && product.userId._id==loggedInUser._id && loggedInUser.email!="gouravroy09@gmail.com")?<h3>Selling Price(per item):Rs. {product.prdct_attributes.retail_dsct_price?product.prdct_attributes.retail_dsct_price:product.prdct_attributes.dsct_price}</h3>:null}
              {product && token && loggedInUser.email=="gouravroy09@gmail.com"?<h3>Sample Price(per item):Rs. {product.sample_charge?product.sample_charge:product.prdct_attributes.dsct_price}</h3>:null}
            </div>
            
            {height>width ?
                        (<div><i htmlFor="">{token && product && !product.userId._id==loggedInUser._id ?`Sample Quantity ${product.sample_qty}`:`Enter Quantity :`} </i></div>):
                        (<label htmlFor="quantity">{token && product && !product.userId._id==loggedInUser._id?`Sample Quantity ${product.sample_qty}`:`Enter Quantity :`}</label>)
                      }
            {(product || (product && loggedInUser && loggedInUser.email!="gouravroy09@gmail.com"))&&<div>
              <label htmlFor="quantity">Quantity</label>
              <input
                type="number"
                name="quantity"
                placeholder={brandDetails.quantity!=0?brandDetails.quantity:"Enter Order Quantity"}
                disabled={token && (loggedInUser && loggedInUser.email=="gouravroy09@gmail.com")?true:false}
                style={{
                  borderColor: error.quantity ? colors.red : colors.blue,
                }}
                value={brandDetails.quantity}
                onChange={(e) =>
                  {
                    handleBrandDetails(e.target.name, parseInt(e.target.value))
                  }
                }
              />
            </div>}
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="">First Name</label>
              <input
                type="text"
                name="firstName"
                placeholder="Enter first name"
                style={{ borderColor: error.firstName ? colors.red : colors.blue }}
                value={brandDetails.firstName}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>}
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="">Last Name</label>
              <input
                type="text"
                name="lastName"
                placeholder="Enter last name"
                style={{ borderColor: error.lastName ? colors.red : colors.blue }}
                value={brandDetails.lastName}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>}
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="">House Number</label>
              <input
                type="text"
                name="houseNumber"
                placeholder="Enter House Number"
                style={{
                  borderColor: error.houseNumber ? colors.red : colors.blue,
                }}
                value={brandDetails.houseNumber}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>}
            <div>
              <label htmlFor="">Contact Number</label>
              <input
                type="number"
                name="contactNum"
                placeholder="Enter contact number"
                value={brandDetails.contactNum}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.contactNum ? colors.red : colors.blue,
                }}
              />
            </div>
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="emailId">Email ID</label>
              <input
                type="text"
                name="emailId"
                placeholder="Enter Email ID"
                style={{
                  borderColor: error.emailId ? colors.red : colors.blue,
                }}
                value={brandDetails.emailId}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
              />
            </div>}
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="">Street</label>
              <input
                type="text"
                name="street"
                placeholder="Enter Street name"
                value={brandDetails.street}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.street ? colors.red : colors.blue,
                }}
              />
            </div>}
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="">Landmark</label>
              <input
                type="text"
                name="ladnmark"
                placeholder="Landmark"
                value={brandDetails.ladnmark}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.ladnmark ? colors.red : colors.blue,
                }}
              />
            </div>}
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="">City</label>
              <input
                type="text"
                name="city"
                placeholder="City"
                value={brandDetails.city}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.city ? colors.red : colors.blue,
                }}
              />
            </div>}
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="">State</label>
              <input
                type="text"
                name="state"
                placeholder="State"
                value={brandDetails.state}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.state ? colors.red : colors.blue,
                }}
              />
            </div>}
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="">Country</label>
              <input
                type="text"
                name="country"
                placeholder="Country"
                value={brandDetails.country}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.country ? colors.red : colors.blue,
                }}
              />
            </div>}
            {!(product && loggedInUser &&loggedInUser._id==product.userId._id)&&<div>
              <label htmlFor="">Pincode</label>
              <input
                type="text"
                name="pincode"
                placeholder="pincode"
                value={brandDetails.pincode}
                onChange={(e) =>
                  handleBrandDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.pincode ? colors.red : colors.blue,
                }}
              />
            </div>}
            <div>
              <p style={{ display: showError ? "initial" : "none" }}>
                Error detected-{apiError} Please correct and submit again
              </p>
              <button
                type="submit"
                style={{
                  backgroundColor: submitBtnDisabled ? "#677890" : "#001E46",
                  cursor: submitBtnDisabled ? "default" : "pointer",
                }}
                // disabled={submitBtnDisabled}
                onClick={(e) => handleSubmit(e)}
              >
                Order
              </button>
            </div>
          </form>
      </div>
      <div className={styles.border}>
        <div></div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default Checkout;