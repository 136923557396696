import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import Cross from "../../assets/icons/cross-blue.svg"
import BurgerMenu from "../../assets/icons/burger-menu-blue.svg"
import DollarSign from "../../assets/icons/dollar-sign.svg";
import Speaker from "../../assets/icons/speaker.svg"
import Graph from "../../assets/icons/graph.svg"
import InstagramLogo from "../../assets/icons/instagram-logo-2.svg";
import FacebookLogo from "../../assets/icons/facebook-logo.svg";
import TwitterLogo from "../../assets/icons/twitter-logo.svg"
import Plus from "../../assets/icons/plus.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import { getAllCities, getAllInflncrCate, getAllStates, registerNewInfluencer } from "../../api/ApiManager";

import styles from "./index.module.css"

const SkillDiscovery = () => {

  const {height, width} = useWindowDimensions();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [education, setEducation] = useState([
    { id: 1, value: "High School" },
    { id: 2, value: "Junior College" },
    { id: 3, value: "Under Graduate" },
    { id: 4, value: "Post Graduate" },
  ]);
  const [influencerCategories, setInfluencerCategories] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    emailId: "",
    contactNum: "",
    state: "",
    city: "",
    education: "",
    expertise: "",
    yearsOfExp: "",
    instagram: "",
    facebook: "",
    twitter: "",
  });
  const [otherLinks, setOtherLinks] = useState([]);
  const [error, setError] = useState({
    name: false,
    emailId: false,
    contactNum: false,
    state: false,
    city: false,
    education: false,
    expertise: false,
    yearsOfExp: false,
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [apiError,setApiError] = useState('')
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };

  useEffect(() => {

    getAllStates()
    .then(res => {
      // console.log(res.data);
      let arr= res.data
        arr.sort( (a, b) => a.name > b.name ? 1 : -1)
      setStates(arr);
    })

    getAllCities()
    .then(res => {
      // console.log(JSON.stringify(res.data))
      let arr= res.data
        arr.sort( (a, b) => a.name > b.name ? 1 : -1)
      setCities(arr);
    })

    getAllInflncrCate()
    .then(res => {
      if (res.status === 200) {
        // console.log(res.data);
        let arr= res.data
        arr.sort( (a, b) => a.field > b.field ? 1 : -1)
        setInfluencerCategories(arr);
      }
    })

    const typedH1 = new Typed(h1.current, {
      strings: ["Display your skills on EYSE 	&#38; "],
      typeSpeed: 40,
      showCursor: false,
    });

    const typedAccentH1 = new Typed(accentH1.current, {
      strings: ["and get scouted by top recruiters"],
      startDelay: 1800,
      typeSpeed: 40,
      showCursor: false,
    });

    return () => {
      typedH1.destroy();
      typedAccentH1.destroy();
    };
  }, []);

  useEffect(() => {
    if (width >= 834) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (userDetails.name && userDetails.emailId && userDetails.contactNum && userDetails.state && userDetails.city && userDetails.education && userDetails.expertise && userDetails.yearsOfExp && (userDetails.instagram || userDetails.facebook || userDetails.twitter)) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  }, [userDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const handleUserDetails = (name, val) => {
    setUserDetails({
      ...userDetails,
      [name]: val,
    });
  };

  const handleOtherLinks = (val, index) => {
    let newArr = otherLinks;
    newArr[index] = val
    setOtherLinks([...newArr])
    console.log(val)
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // name validation
    const regexName = /^[a-zA-Z ]+$/;
    const testName = regexName.test(userDetails.name);
    
    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(userDetails.emailId);

    // contact number validation
    const regexContactNum = /^\d{10}$/;
    const testContactNum = regexContactNum.test(
      parseInt(userDetails.contactNum)
    );

    let testState = false;
    let testCity = false;
    let testEducation = false;
    let testExpertise = false;

    // state validation
    if (userDetails.state === "" || userDetails.state === "Select State") {
      testState = true;
    }

    // city validation
    if (userDetails.city === "" || userDetails.city === "Select city / town") {
      testCity = true;
    }

    // city validation
    if (userDetails.education === "" || userDetails.education === "Select Education") {
      testEducation = true;
    }

    // city validation
    if (userDetails.expertise === "" || userDetails.expertise === "Select category of expertise") {
      testExpertise = true;
    }

    // contact number validation
    const testYearsOfExp = userDetails.yearsOfExp >= 0 && userDetails.yearsOfExp <= 99;

    setError({
      emailId: !testEmailId,
      name: !testName,
      contactNum: !testContactNum,
      state: testState,
      city: testCity,
      education: testEducation,
      expertise: testExpertise,
      yearsOfExp: !testYearsOfExp
    });

    if (!testEmailId || !testName || !testContactNum || testState || testCity || testEducation || testExpertise || !testYearsOfExp) {
      setShowError(true);
      setSubmitBtnDisabled(true);
    } else {
      setShowError(false);
      setSubmitBtnDisabled(false);
    }

    if (
      !(!testEmailId ||
      !testName ||
      !testContactNum ||
      testState ||
      testCity ||
      testEducation ||
      testExpertise ||
      !testYearsOfExp)
    ) {
      setSubmitSuccess(true);
      // final submit function here
      let body = {
        name: userDetails.name,
        email: userDetails.emailId,
        password: userDetails.contactNum,
        mobile: userDetails.contactNum,
        //latitude : position.coords.latitude.toString(),
        //longitude : position.coords.longitude.toString(),
        //below are influencer details
        dob: "1994-08-28",
        category_id: "",
        description: "I am a Hardware Influenser",
        latitude: "",
        longitude: "",
        if_expert: true,
        years_Active: userDetails.yearsOfExp,
        expert_charge: "95000",
        rate_per_hour: "",
        instagram_link: {
          name: "",
          url: userDetails.instagram,
        },
        facebook_link: {
          name: "",
          url: userDetails.facebook,
        },
        twitter_link: {
          name: "",
          url: userDetails.twitter,
        },
      };
      console.log(JSON.stringify(body))
      registerNewInfluencer(body)
      .then(res=>{
        setShowPopover(true);
        console.log(JSON.stringify(res));
        if (res.status === 201) {
          setSubmitSuccess(true);
        } else {
          setSubmitSuccess(false);
        }
        setApiError(res.message)
        setShowError(true);
      })
    }


  };

  return (
    <div className={styles.body}>
      <SubmitPopover
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      <header className={styles.header}>
        <Link to="/">
          <img
            className={styles.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-dark.png")}
          />
        </Link>
        <img
          className={styles.burgerMenu}
          src={isMenuOpen ? Cross : BurgerMenu}
          alt="burger-menu"
          style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
          onClick={() => setIsMenuOpen((prevState) => !prevState)}
        />
        <a
          href={"/addskill"}
          target=""
          className={styles.headerBtn + " " + styles.btn}
          style={{
            transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
          }}
        >
          <p>Add Your Skill</p>
        </a>
      </header>
      <div className={styles.pageHeadings}>
        <span className={styles.pageHeading} ref={h1}></span>{" "}
        <span
          className={styles.pageHeading + " " + styles.accent}
          ref={accentH1}
        ></span>
      </div>
      <div className={styles.sectionInfo}>
        <div className={styles.infoBox}>
          {/* <img src={DollarSign} alt="dollar-sign" /> */}
          <h4>
            {/* <span>Monitize</span> your content & track your earnings by{" "}
            <span>partnering with brands</span> */}
            <span>Search and choose a job requirement</span>
          </h4>
        </div>
        <div className={styles.infoBox}>
          {/* <img src={Speaker} alt="speaker-icon" /> */}
          <h4>
            {/* Directly connect with your followers, improve trust factor &{" "}
            <span>grow your personal brand</span> */}
            <span>Share details of your hands-on experience</span>
          </h4>
        </div>
        <div className={styles.infoBox}>
          {/* <img src={Graph} alt="graph-icon" /> */}
          <h4>
            {/* Get <span>professional help and</span> apply tried & tested{" "}
            <span>methods to grow your audience</span> */}
            <span>Get selected by hiring manager based on your response</span>
          </h4>
        </div>
      </div>
      {false&&<div className={styles.sectionForm} id="expert-form">
        <h1>Apply as Expert</h1>
        <p>
          Become a part of growing community of experts & <br />
          reap the best results out of your content
        </p>
        {submitSuccess ? (
          <div className={styles.signedUp}>
            <img src={CheckCircle} alt="check-mark" />
            <h3>Thank you for signing up!</h3>
            <p>Keep an eye out for our beta launch mail!</p>
          </div>
        ) : (
          <form action="" className={styles.expertForm}>
            <div>
              <label htmlFor="">Full Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                value={userDetails.name}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                style={{ borderColor: error.name ? colors.red : colors.blue }}
              />
            </div>
            <div>
              <label htmlFor="">Email ID</label>
              <input
                type="text"
                name="emailId"
                placeholder="Enter Email ID"
                value={userDetails.emailId}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.emailId ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">Contact Number</label>
              <input
                type="number"
                name="contactNum"
                placeholder="Enter contact number"
                value={userDetails.contactNum}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.contactNum ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="state">State</label>
              <select
                name="state"
                value={userDetails.state}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.state ? colors.red : colors.blue,
                }}
              >
                <option>Select state</option>
                {states.map((item) => {
                  return (
                    <option key={item._id} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label htmlFor="city">City/Town</label>
              <select
                name="city"
                value={userDetails.city}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.city ? colors.red : colors.blue,
                }}
              >
                <option>Select city / town</option>
                {cities.map((item) => {
                  return (
                    <option key={item._id} value={item.name}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label htmlFor="education">Education</label>
              <select
                name="education"
                value={userDetails.education}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.education ? colors.red : colors.blue,
                }}
              >
                <option>Select Education</option>
                {education.map((item) => {
                  return (
                    <option key={item.id} value={item.value}>
                      {item.value}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label htmlFor="expertise">Category of Expertise</label>
              <select
                name="expertise"
                value={userDetails.expertise}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.expertise ? colors.red : colors.blue,
                }}
              >
                <option>Select category of expertise</option>
                {influencerCategories.map((item) => {
                  return (
                    <option key={item._id} value={item.field}>
                      {item.field}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label htmlFor="">Years of Experience</label>
              <input
                type="number"
                name="yearsOfExp"
                placeholder="How many years have you been in this field"
                value={userDetails.yearsOfExp}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.yearsOfExp ? colors.red : colors.blue,
                }}
              />
            </div>
            <div className={styles.addLinks}>
              <div>
                <img
                  src={require("../../assets/images/blank_image.png")}
                  alt="blank"
                />
                <p>
                  Add your social media links
                  <span> &#40;atleast 1 link mandatory&#41;</span>
                </p>
              </div>
              <div>
                <img src={InstagramLogo} alt="instagram-logo" />
                <input
                  type="text"
                  name="instagram"
                  placeholder="Enter link address"
                  onChange={(e) =>
                    handleUserDetails(e.target.name, e.target.value)
                  }
                />
              </div>
              <div>
                <img src={FacebookLogo} alt="facebook-logo" />
                <input
                  type="text"
                  name="facebook"
                  placeholder="Enter link address"
                  onChange={(e) =>
                    handleUserDetails(e.target.name, e.target.value)
                  }
                />
              </div>
              <div>
                <img src={TwitterLogo} alt="twitter-logo" />
                <input
                  type="text"
                  name="twitter"
                  placeholder="Enter link address"
                  onChange={(e) =>
                    handleUserDetails(e.target.name, e.target.value)
                  }
                />
              </div>
              {otherLinks.map((link, index) => (
                <div key={index}>
                  <img
                    src={require("../../assets/images/blank_image.png")}
                    alt="blank"
                  />
                  <input
                    placeholder="Enter link address"
                    value={link}
                    onChange={(e) => handleOtherLinks(e.target.value, index)}
                    // style={{marginBottom: 16}}
                  />
                </div>
              ))}
              <div
                onClick={() => {
                  if (otherLinks.length < 3)
                    setOtherLinks((prevState) => [...prevState, ""]);
                }}
                className={styles.addLink + " " + styles.btn}
              >
                <img src={Plus} alt="plus-sign" />
                <p>Add other links</p>
              </div>
            </div>
            <div>
              <p style={{ display: showError ? "initial" : "none" }}>
                Error detected-{apiError} Please correct and submit again
              </p>
              <button
                type="submit"
                style={{
                  backgroundColor: submitBtnDisabled ? "#677890" : "#011E46",
                  cursor: submitBtnDisabled ? "default" : "pointer",
                }}
                disabled={submitBtnDisabled}
                className={styles.submitBtn + " " + styles.btn}
                onClick={(e) => handleSubmit(e)}
              >
                Submit Application
              </button>
            </div>
          </form>
        )}
      </div>}
      <div>
      </div>
      <div className={styles.border}>
        <div></div>
      </div>
      <Footer />
    </div>
  );
}

export default SkillDiscovery;