import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import Cross from "../../assets/icons/cross-blue.svg"
import BurgerMenu from "../../assets/icons/burger-menu-blue.svg"
import DollarSign from "../../assets/icons/dollar-sign.svg";
import Speaker from "../../assets/icons/speaker.svg"
import Graph from "../../assets/icons/graph.svg"
import InstagramLogo from "../../assets/icons/instagram-logo-2.svg";
import FacebookLogo from "../../assets/icons/facebook-logo.svg";
import TwitterLogo from "../../assets/icons/twitter-logo.svg"
import Plus from "../../assets/icons/plus.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";
import { Dropdown, FormField, Form, Checkbox } from "semantic-ui-react";

import { allBrands, getAllCities, getAllInflncrCate, getAllParentCategory, getAllStates, getNearbyPartnerBrandStores, getNearbyShopsDetailsWithCategoryAndBrandFilter, getSingleStoreDetailsByUserIdWithOutMobileAndEmail, registerNewInfluencer } from "../../api/ApiManager";

import styles from "./index.module.css"
import styles1 from "../Home/index.module.css"
import BrandHeader from "../join-as-brand/BrandHeader";

const BrandStoreLocator = () => {

  const {height, width} = useWindowDimensions();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [education, setEducation] = useState([
    { id: 1, value: "High School" },
    { id: 2, value: "Junior College" },
    { id: 3, value: "Under Graduate" },
    { id: 4, value: "Post Graduate" },
  ]);
  const [influencerCategories, setInfluencerCategories] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    emailId: "",
    contactNum: "",
    state: "",
    city: "",
    education: "",
    expertise: "",
    yearsOfExp: "",
    instagram: "",
    facebook: "",
    twitter: "",
    category:"",
    brand:"",
  });
  const [otherLinks, setOtherLinks] = useState([]);
  const [error, setError] = useState({
    name: false,
    emailId: false,
    contactNum: false,
    state: false,
    city: false,
    education: false,
    expertise: false,
    yearsOfExp: false,
    category:false,
    brand:false,
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [apiError,setApiError] = useState('')
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [categories,setCategories] = useState()
  const [brands,setBrands] = useState()
  const [stores, setStores] = useState([])
  const [location,setLocation] = useState()
  const [navigationLink,setNavigationLink]=useState()
  const [value,setValue] = useState('this')
  let {brandUserId, productId,initStr}=useParams()
  const [shopDetails,setShopDetails] = useState()
  const [loading,setLoading] =useState(true)

  useEffect(()=>{
    handleLocationClick()
    if(brandUserId){
      getSingleStoreDetailsByUserIdWithOutMobileAndEmail(brandUserId)
        .then(res => {
          console.log(res.Data.name)
          if (res.status == 200) {
            setShopDetails(res.Data)
            // if(res.Data.user_id && res.Data.user_id.isBrand){
            //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
            // }else{
  
            //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
            // }
          }
        })
    }
  },[])

  useEffect(() => {
    if (width >= 834) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (userDetails.name && userDetails.emailId && userDetails.contactNum && userDetails.state && userDetails.city && userDetails.education && userDetails.expertise && userDetails.yearsOfExp && (userDetails.instagram || userDetails.facebook || userDetails.twitter)) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  }, [userDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const handleUserDetails = (name, val) => {
    setUserDetails({
      ...userDetails,
      [name]: val,
    });
  };

  const handleOtherLinks = (val, index) => {
    let newArr = otherLinks;
    newArr[index] = val
    setOtherLinks([...newArr])
    console.log(val)
  }

  const handleSubmit = (e) => {
    e.preventDefault();


    setError({
      category: userDetails.category!=""?false:true,
      brand: userDetails.brand!=""?false:true
    });

    if (userDetails.category=="" && userDetails.brand=="") {
      setShowError(true);
      setSubmitBtnDisabled(true);
      return
    } else {
      setShowError(false);
      setSubmitBtnDisabled(false);
    }

    if (
      userDetails.category!="" || userDetails.brand!=""
    ) {
      setSubmitSuccess(true);
      // final submit function here
      getNearbyShopsDetailsWithCategoryAndBrandFilter({
        lat: location.lat, long: location.long, childCategoryName: userDetails.category.childCategoryName,
        brand: userDetails.brand
      }).then(res => {
          if(res.status==200&&res.Data && res.Data.length>0){
            setStores(res.Data);
          }else{
            setStores([])
          }
        })
        .catch(error => console.log(error));
    }


  };
  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error1);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    let lt= "https://maps.google.com?q="+ latitude+","+longitude
    setLocation({lat:latitude,long:longitude})    
    setNavigationLink(lt)
    getNearbyPartnerBrandStores({
      lat: latitude, long: longitude, storeUserId: brandUserId
    }).then(res => {
        if(res.status==200&&res.Data && res.Data.length>0){
          setStores(res.Data);
        }else{
          setStores([])
        }
        setLoading(false)
      })
      .catch(error => console.log(error));
  }

  function error1() {
    console.log("Unable to retrieve your location");
  }


  return (
    <div className={styles.body}>
      <SubmitPopover
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
        />
        {brandUserId&&shopDetails&&<BrandHeader storeUserId={brandUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {/* <header className={styles.header}>
        <Link to="/">
          <img
            className={styles.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-dark.png")}
          />
        </Link>
      </header> */}
      <div className={styles.sectionForm} id="expert-form">
        <h1>Eyse Store Locator</h1>
        <p>
          Explore <br />
          Nearby Store for brand : {shopDetails&&shopDetails.name}
        </p>
          <div  style={{maxHeight:height,overflowY:"scroll"}}>
          <h5>{loading?"Loading":`Showing: ${stores.length} Stores`}</h5>
          {stores.map(store=>{
          // console.log(product.value.prdct_attributes)
          return !store.isBrand&&(
            <div style={{display:"flex",overflowX:"scroll"}} >
              <h5>{store.name}</h5>
              <p>{store.address}</p>
              {
              <a href={`/viewstore/true/`+`${store.user_id}`}>Explore Store</a>
              }
              {store.location&&store.location.coordinates&&store.location.coordinates[0]&&store.location.coordinates[1]&&
              <a href={"https://maps.google.com?q="+ store.location.coordinates[0]+","+store.location.coordinates[1]}>Get Direction</a>
              }
            </div>
          )})}
          </div>
      </div>
      <div className={styles.border}>
        <div></div>
      </div>
      <Footer />
    </div>
  );
}

export default BrandStoreLocator;