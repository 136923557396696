import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import Typed from "typed.js";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import Cross from "../../assets/icons/cross.svg";
import BurgerMenu from "../../assets/icons/burger-menu.svg";
import CilSpa from "../../assets/icons/cil-spa.svg";
import RecordVoiceOver from "../../assets/icons/record-voice-over.svg";
import AllInclusive from "../../assets/icons/all-inclusive.svg";
import MilitaryTech from "../../assets/icons/military-tech.svg";
import CheckCircle from "../../assets/icons/check-circle.svg"
import styles from "./index.module.css";
import { registerNewUser } from "../../api/ApiManager";
import Badge from '@mui/material/Badge';

const Home = () => {
  const [showComponent, setShowComponent] = useState(false); 
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    emailId: "",
    name: "",
    contactNum: "",
  });
  const [error, setError] = useState({
    emailId: false,
    name: false,
    contactNum: false,
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [secTwoHeight, setSecTwoHeight] = useState(536);
  const [apiError,setApiError] = useState('')
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };

  useEffect(() => {
    const typedH1 = new Typed(h1.current, {
      strings: ["Find answers to your queries through"],
      typeSpeed: 40,
      showCursor: false,
    });

    const typedAccentH1 = new Typed(accentH1.current, {
      strings: ["Personal Experiences","Reviews and feedbacks","Expert curated content"],
      startDelay: 2300,
      backDelay: 500,
      smartBackspace: false,
      backSpeed:0,
      fadeOut:true,
      typeSpeed: 100,
      showCursor: false,
      loop:true,
      //attr: 'placeholder',
    bindInputFocusEvents: true,
    });

    return () => {
      typedH1.destroy();
      typedAccentH1.destroy();
    };
  }, []);

  useEffect(() => {
    if (width >= 834) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }

    if (width < 350) {
      setSecTwoHeight(760)
    } else if (width >= 350 && width < 390) {
      setSecTwoHeight(700)
    } else if (width >= 390 && width < 415) {
      setSecTwoHeight(665);
    } else if (width >= 415 && width < 834) {
      setSecTwoHeight(618);
    } else {
      setSecTwoHeight(536);
    }
  }, [width]);

  useEffect(() => {
    if (userDetails.emailId && userDetails.name && userDetails.contactNum) {
      setSubmitBtnDisabled(false);
    }
  }, [userDetails]);

  useEffect(() => { 
    const timeout = setTimeout(() => { 
      setShowComponent(true); 
    }, /*14500*/5500); 
 
    return () => clearTimeout(timeout); 
  }, []); 

  const handleUserDetails = (name, val) => {
    setUserDetails({
      ...userDetails,
      [name]: val,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(userDetails.emailId);

    // name validation
    const regexName = /^[a-zA-Z ]+$/;
    const testName = regexName.test(userDetails.name);

    // contact number validation
    const regexContactNum = /^\d{10}$/;
    const testContactNum = regexContactNum.test(
      parseInt(userDetails.contactNum)
    );

    setError({
      emailId: !testEmailId,
      name: !testName,
      contactNum: !testContactNum,
    });

    if (!testEmailId || !testName || !testContactNum) {
      setShowError(true);
      setSubmitBtnDisabled(true);
    } else {
      setShowError(false);
      setSubmitBtnDisabled(false);
    }

    if (testEmailId && testName && testContactNum) {
      // final submit function here
      var body = {
        profilePic: {
          name: 'My pICTURE',
          url: 'https://xm-customer.s3.ap-south-1.amazonaws.com/images/16139913872243qF9NbThu.jpg',
        },
        name: userDetails.name,
        email: userDetails.emailId,
        mobile: userDetails.contactNum,
        password: userDetails.contactNum,
        gender: 'Male',
        dob: '1994-08-28',
        address: {
          firstName: 'Balavan',
          lastName: 'Patil',
          mobile: '8197830443',
          line1: 'Patil Nivas',
          line2: 'Basavkalyan',
          landmark: 'Basavkalyan',
          houseNumber: '26/7',
          street: 'Talhbhog',
          city: 'Basavkalyan',
          pinCode: 585327,
          state: 'Karnataka',
          country: 'INDIA',
        },
      };
      
      // console.log("body",JSON.stringify(body))
  
      registerNewUser(body)
      .then(res=>{
        setShowPopover(true);
        console.log(JSON.stringify(res))
        if(res.status===201){
          setSubmitSuccess(true);
        } else {
          setSubmitSuccess(false);
        }
        setApiError(res.message)
        setShowError(true);
      })
    }
  };

  return (
    <div className={styles.body}>
      <SubmitPopover
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />

      <div className={styles.sectionOne}>
        <header className={styles.header}>
          <img
            className={styles.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
          <div className={styles.menu}>
            <img
              className={styles.burgerMenu}
              src={isMenuOpen ? Cross : BurgerMenu}
              alt="burger-menu"
              style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
              onClick={() => setIsMenuOpen((prevState) => !prevState)}
            />
            <div
              className={styles.headerBtns}
              style={{
                transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
              }}
            >
              {/* <div className={styles.addProduct}>
                <div></div>
                <Link to="/adminlogin" className={styles.btn}>
                  Admin Login
                </Link>
              </div> */}
              {/* <div className={styles.addProduct}>
                <div></div>
                <Link to="/teamlogin" className={styles.btn}>
                  Team Login
                </Link>
              </div> */}
              {/* <div className={styles.addProduct}>
                <div></div>
                <Link to="/login" className={styles.btn}>
                  Seller Login
                </Link>
              </div> */}
              <div className={styles.addProduct}>
                <div></div>
                <Badge badgeContent={"New"} color="primary">
                <Link to="/skilldiscovery" className={styles.btn}>
                  For Recruitment
                </Link>
                </Badge>
              </div>
              <div>
                <p className={styles.partner + " " + styles.btn}>
                  Partner with us
                </p>
                <div className={styles.partnerOptns}>
                  <Link to="/joinasbrand" className={styles.btn}>
                    Join as Brand
                  </Link>
                  <div className={styles.border}>
                    <div></div>
                  </div>
                  <Link to="/joinasbrand" className={styles.btn}>
                    Join as Distributor
                  </Link>
                  <div className={styles.border}>
                    <div></div>
                  </div>
                  <Link to="/joinasexpert" className={styles.btn}>
                    Join as Expert
                  </Link>
                  <div className={styles.border}>
                    <div></div>
                  </div>
                  <Link to="/dealer/join-as-retailer" className={styles.btn}>
                    Join as Retailer
                  </Link>
                </div>
              </div>
              <div className={styles.border}>
                <div></div>
              </div>
              {/* <a
                className={styles.signUpBeta + " " + styles.btn}
                href="#sign-up-beta"
              >
                Sign up for Beta
              </a> */}
              <a
                className={styles.signUpBeta + " " + styles.btn}
                href="https://play.google.com/store/apps/details?id=com.eyse"
              >
                Sign up for Beta
              </a>
            </div>
          </div>
        </header>
        <div className={styles.headBody}>
          <h1 ref={h1}></h1>
          <h1 className={styles.accentH1} ref={accentH1}></h1>
          {showComponent &&
            <a
              className={styles.signUpBeta + " " + styles.btn}
              style={{ color: "white" }}
              href="/askquery"
            >
              Ask your query now
            </a>
          }
          <div>
            <img
              className={styles.mockup}
              src={require("../../assets/images/iphone-mockup-sec1-1.png")}
              alt="app-homescreen-mockup"
              data-aos="fade-up"
              data-aos-duration="2000"
            />
          </div>
        </div>
      </div>

      <div
        className={styles.sectionTwo}
        style={{ height: `${secTwoHeight}px` }}
      >
        <div className={styles.infos}>
          <div>
            <div className={styles.info}>
              <img src={CilSpa} alt="flower-icon" className={styles.infoIcon} />
              <p>
                Find inspiration for home, travel, fashion & basically anything
                you look for.
              </p>
            </div>
            <div className={styles.info}>
              <img
                src={RecordVoiceOver}
                alt="person-talking-icon"
                className={styles.infoIcon}
              />
              <p>
                Get on 1:1 calls with your favorite expert & let them guide you
              </p>
            </div>
          </div>
          <div>
            <div className={styles.info}>
              <img
                src={AllInclusive}
                alt="lazy-8-icon"
                className={styles.infoIcon}
              />
              <p>
                Find end to end information in one place. Search, research, shop
                & more
              </p>
            </div>
            <div className={styles.info}>
              <img
                src={MilitaryTech}
                alt="badge-icon"
                className={styles.infoIcon}
              />
              <p>
                Discover trustworthy experience by making purchases based on
                expert reviews
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.sectionThree}>
        <div /*data-aos="fade-right" data-aos-duration="2000"*/>
          <img
            src={require("../../assets/images/iphone-mockup-sec3-1.png")}
            alt="app-catalogue-mockup"
          />
          <div>
            <h2>Find all the information in one place!</h2>
            <p>
              Quickly find the answers you are looking for through highly
              curated & detailed collections. Understand the entire journey or
              find answers for specific queries, we have it all covered.
            </p>
          </div>
        </div>
        <div /*data-aos="fade-left" data-aos-duration="2000"*/>
          <div>
            <h2>
              Shopping reimagined! Dive into the world of smart purchasing
            </h2>
            <p>
              Dedicated posts for each product making product-detail-information
              & first hand experiences more accessible. Buy only after ensuring
              it fits your exact needs. Bonus- buy entire collections in one go!
            </p>
          </div>
          <img
            src={require("../../assets/images/iphone-mockup-sec3-2.png")}
            alt="app-tagged-products-mockup"
          />
        </div>
        <div /*data-aos="fade-right" data-aos-duration="2000"*/>
          <img
            src={require("../../assets/images/iphone-mockup-sec3-3.png")}
            alt="app-video-call-mockup"
          />
          <div>
            <h2>Get on 1:1 with an expert!</h2>
            <p>
              Speak to our experts directly to get more information, ask follow
              up questions, get into the specifics and more! Get a wholistic
              understanding of whatever you are looking for - by booking a 1:1
              call with your expert of choice
            </p>
          </div>
        </div>
      </div>

      {/* <div className={styles.sectionFour} id="sign-up-beta">
        <h1>Sign up for Beta</h1>
        <p>Be one of the first ones to check out the app</p>
        <div className={styles.border}>
          <div></div>
        </div>
        {submitSuccess ? (
          <div className={styles.signedUp}>
            <img src={CheckCircle} alt="check-mark" />
            <h3>Thank you for signing up!</h3>
            <p>Keep an eye out for our beta launch mail!</p>
          </div>
        ) : (
          <form className={styles.betaForm}>
            <div>
              <label htmlFor="emailId">Email ID</label>
              <input
                type="email"
                name="emailId"
                placeholder="Enter Email ID"
                value={userDetails.emailId}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.emailId ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                name="name"
                value={userDetails.name}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                placeholder="Enter your name"
                style={{
                  borderColor: error.name ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="contactNum">Contact Number</label>
              <input
                type="number"
                name="contactNum"
                value={userDetails.contactNum}
                onChange={(e) =>
                  handleUserDetails(e.target.name, e.target.value)
                }
                placeholder="Enter contact number"
                style={{
                  borderColor: error.contactNum ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <p style={{ display: showError ? "initial" : "none" }}>
                Error detected-{apiError} Please correct and submit again
              </p>
              <button
                type="submit"
                style={{
                  backgroundColor: submitBtnDisabled ? "#677890" : "#011E46",
                  cursor: submitBtnDisabled ? "default" : "pointer",
                }}
                disabled={submitBtnDisabled}
                className={styles.signUpBeta + " " + styles.btn}
                onClick={(e) => handleSubmit(e)}
              >
                Sign up for Beta
              </button>
            </div>
          </form>
        )}
      </div> */}

      <div className={styles.border}>
        <div></div>
      </div>

      <div className={styles.sectionFive}>
      {/* <h2>Join the revolution!</h2>
          <a href="https://play.google.com/store/apps/details?id=com.eyse">
        <div className={styles.storeLogos}>

          <img
            src={require("../../assets/icons/playstore.png")}
            alt="playstore-icon"
          />
        </div>
          </a> */}
        {/* <h2>Coming soon on</h2>
        <div className={styles.storeLogos}>
          <img
            src={require("../../assets/icons/playstore.png")}
            alt="playstore-icon"
          />
          <img
            src={require("../../assets/icons/appstore.png")}
            alt="appstore-icon"
          />
        </div> */}
        <h2>Join the revolution!</h2>
        <a href="https://play.google.com/store/apps/details?id=com.eyse">
        <div className={styles.storeLogos}>
        <img
            src={require("../../assets/icons/eyse-logo.jpg")}
            alt="playstore-icon"
          />
          <img
            src={require("../../assets/icons/playstore.png")}
            alt="playstore-icon"
          />
          {/* <img
            src={require("../../assets/icons/appstore.png")}
            alt="appstore-icon"
          /> */}
        </div>
        </a>
        <h3>Partner with us</h3>
        <div className={styles.joinOptns}>
          <Link to="/joinasexpert" className={styles.btn}>
            Join as Expert
          </Link>
          <Link to="/joinasbrand" className={styles.btn}>
            Join as Brand
          </Link>
          <Link to="/dealer/join-as-retailer" className={styles.btn}>
            Join as a Service
          </Link>
        </div>
        <div className={styles.joinOptns}>
          <Link to="/joinasbrand" className={styles.btn}>
            Join as Wholesaler
          </Link>
          <Link to="/joinasbrand" className={styles.btn}>
            Join as Distributor
          </Link>
          <Link to="dealer/join-as-retailer" className={styles.btn}>
            Join as Retailer
          </Link>
        </div>
        <p className={styles.signUpBeta + " " + styles.btn}>
          {/* <a href="#sign-up-beta">Sign up for Beta</a> */}
          <a href="https://play.google.com/store/apps/details?id=com.eyse">Sign up for Beta</a>
        </p>
      </div>

      <div className={styles.border}>
        <div></div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
