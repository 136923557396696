import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { loginSeller } from '../../api/ApiManager';
import styles from "./index.module.css";

const Login = (props) => {

    const navigate = useNavigate();
    const [loginDetails, setLoginDetails] = useState({
      emailId: '',
      password: ''
    })
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);

    useEffect(() => {
      if (loginDetails.emailId && loginDetails.password) {
        setSubmitBtnDisabled(false);
      } else {
        setSubmitBtnDisabled(true);
      }
    }, [loginDetails]);

    const handleLoginDetails = (name, val) => {
      setLoginDetails({
        ...loginDetails,
        [name]: val
      })
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      if(props.admin){
        if(loginDetails.emailId!=='gouravroy09@gmail.com'){
          alert('You dont have admin rights!')
          e.preventDefault()
        }
      }
      const body = {
        userName: loginDetails.emailId,
        password: loginDetails.password
      }

      loginSeller(body)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("user-data", JSON.stringify(res.data));
            localStorage.setItem("auth_token", JSON.stringify(res.data.token));
            if(props.admin){
              navigate("/manageseller");
            }else{
              navigate(`/viewstore/true/`+`${res.data._id}`);
            }
          }
        })
        .catch((err) => console.log("login err", err));
    }

    return (
      <div className={styles.body}>
        <p className={styles.heading}>{props.admin? 'Admin ':''}Login</p>
        <form action="" className={styles.loginForm}>
          <div>
            <label htmlFor="emailId">Email ID</label>
            <input
              type="text"
              name="emailId"
              placeholder="Email ID"
              spellCheck={false}
              onChange={(e) =>
                handleLoginDetails(e.target.name, e.target.value)
              }
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              spellCheck={false}
              onChange={(e) =>
                handleLoginDetails(e.target.name, e.target.value)
              }
            />
          </div>
          <div>
            <button
              type="submit"
              style={{
                backgroundColor: submitBtnDisabled ? "#677890" : "#001E46",
                cursor: submitBtnDisabled ? "default" : "pointer",
              }}
              disabled={submitBtnDisabled}
              onClick={(e) => handleSubmit(e)}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    );
}

export default Login;