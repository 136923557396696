
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import {
  searchBrandNames,
  directs3upload,
  uploadProductDetails,
  getAllProductsOnUserId,
  updateProductDetails,
  getSingleStoreDetailsByUserIdWithOutMobileAndEmail,
} from "../../api/ApiManager";
import "semantic-ui-css/semantic.min.css";
import styles from "./index.module.css";
import imageCompression from 'browser-image-compression';
const InventoryDashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [shopDetail, setShopDetail] = useState();
  const [productDetails, setProductDetails] = useState({
    images: [],
    videos: [],
    colour:"",
    productName: "",
    brand: "",
    model: "",
    moq: 0,
    ogPrice: 0,
    dsctPrice: 0,
    retailPrice: 0,
    dsctRetailPrice: 0,
    sku: 0,
    skuId:'',
    dispatchTime: "",
    category: "",
    gender: "",
    ownership: "",
    channel: "",
    size: "",
    tags: [], //TODO: see in b2b how to handle tags
    delivery: "",
    samples: "",
    sampleQty: 0,
    samplePrice: 0,
    creditBooster: "",
    minCreditInstallment: "",
    memberDsct: 0,
    autorenewal: "",
    desc: "",
    // variantColorChosen: {colorName: "BLACK"}
  });
  const [s3FileArray, setS3FileArray] = useState([]);
  const [sellCategory, setSellCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchBrands, setSearchBrands] = useState([]);
  const [searchColours, setSearchColours] = useState(
    [
      // { key: 0, text: "Black", value: "Black" },
      // { key: 1, text: "White", value: "White" },
      // { key: 2, text: "Blue", value: "Blue" },
      // { key: 3, text: "Red", value: "Red" },
      // { key: 4, text: "Yellow", value: "Yellow" },
      // { key: 5, text: "Green", value: "Green" },
      // { key: 6, text: "Orange", value: "Orange" },
      // { key: 7, text: "Pink", value: "Pink" },
      // { key: 8, text: "Purple", value: "Purple" },
      // { key: 9, text: "Brown", value: "Brown" },
      // { key: 10, text: "Golden", value: "Golden" },
      // { key: 11, text: "Golden", value: "Golden" },

      {key:0,text:"aliceblue",value:"aliceblue"},
{key:1,text:"antiquewhite",value:"antiquewhite"},
{key:2,text:"aqua",value:"aqua"},
{key:3,text:"aquamarine",value:"aquamarine"},
{key:4,text:"azure",value:"azure"},
{key:5,text:"beige",value:"beige"},
{key:6,text:"bisque",value:"bisque"},
{key:7,text:"black",value:"black"},
{key:8,text:"blanchedalmond",value:"blanchedalmond"},
{key:9,text:"blue",value:"blue"},
{key:10,text:"blueviolet",value:"blueviolet"},
{key:11,text:"brown",value:"brown"},
{key:12,text:"burlywood",value:"burlywood"},
{key:13,text:"cadetblue",value:"cadetblue"},
{key:14,text:"chartreuse",value:"chartreuse"},
{key:15,text:"chocolate",value:"chocolate"},
{key:16,text:"coral",value:"coral"},
{key:17,text:"cornflowerblue",value:"cornflowerblue"},
{key:18,text:"cornsilk",value:"cornsilk"},
{key:143,text:"cream",value:"cream"},
{key:19,text:"crimson",value:"crimson"},
{key:20,text:"cyan",value:"cyan"},
{key:21,text:"darkblue",value:"darkblue"},
{key:22,text:"darkcyan",value:"darkcyan"},
{key:23,text:"darkgoldenrod",value:"darkgoldenrod"},
{key:24,text:"darkgray",value:"darkgray"},
{key:25,text:"darkgreen",value:"darkgreen"},
{key:26,text:"darkkhaki",value:"darkkhaki"},
{key:27,text:"darkmagenta",value:"darkmagenta"},
{key:28,text:"darkolivegreen",value:"darkolivegreen"},
{key:29,text:"darkorange",value:"darkorange"},
{key:30,text:"darkorchid",value:"darkorchid"},
{key:31,text:"darkred",value:"darkred"},
{key:32,text:"darksalmon",value:"darksalmon"},
{key:33,text:"darkseagreen",value:"darkseagreen"},
{key:34,text:"darkslateblue",value:"darkslateblue"},
{key:35,text:"darkslategray",value:"darkslategray"},
{key:36,text:"darkturquoise",value:"darkturquoise"},
{key:37,text:"darkviolet",value:"darkviolet"},
{key:38,text:"deeppink",value:"deeppink"},
{key:39,text:"deepskyblue",value:"deepskyblue"},
{key:40,text:"dimgray",value:"dimgray"},
{key:41,text:"dodgerblue",value:"dodgerblue"},
{key:42,text:"fawn",value:"fawn"},
{key:43,text:"firebrick",value:"firebrick"},
{key:44,text:"floralwhite",value:"floralwhite"},
{key:45,text:"forestgreen",value:"forestgreen"},
{key:46,text:"fuchsia",value:"fuchsia"},
{key:47,text:"gainsboro",value:"gainsboro"},
{key:48,text:"ghostwhite",value:"ghostwhite"},
{key:49,text:"gold",value:"gold"},
{key:50,text:"goldenrod",value:"goldenrod"},
{key:51,text:"gray",value:"gray"},
{key:52,text:"green",value:"green"},
{key:53,text:"greenyellow",value:"greenyellow"},
{key:54,text:"greymetal",value:"greymetal"},
{key:55,text:"honeydew",value:"honeydew"},
{key:56,text:"hotpink",value:"hotpink"},
{key:57,text:"indianred ",value:"indianred "},
{key:58,text:"indigo",value:"indigo"},
{key:59,text:"ivory",value:"ivory"},
{key:60,text:"khaki",value:"khaki"},
{key:61,text:"lavender",value:"lavender"},
{key:62,text:"lavenderblush",value:"lavenderblush"},
{key:63,text:"lawngreen",value:"lawngreen"},
{key:64,text:"lemonchiffon",value:"lemonchiffon"},
{key:65,text:"lightblue",value:"lightblue"},
{key:66,text:"lightcoral",value:"lightcoral"},
{key:67,text:"lightcyan",value:"lightcyan"},
{key:68,text:"lightgoldenrodyellow",value:"lightgoldenrodyellow"},
{key:69,text:"lightgrey",value:"lightgrey"},
{key:70,text:"lightgreen",value:"lightgreen"},
{key:71,text:"lightpink",value:"lightpink"},
{key:72,text:"lightsalmon",value:"lightsalmon"},
{key:73,text:"lightseagreen",value:"lightseagreen"},
{key:74,text:"lightskyblue",value:"lightskyblue"},
{key:75,text:"lightslategray",value:"lightslategray"},
{key:76,text:"lightsteelblue",value:"lightsteelblue"},
{key:77,text:"lightyellow",value:"lightyellow"},
{key:78,text:"lime",value:"lime"},
{key:79,text:"limegreen",value:"limegreen"},
{key:80,text:"linen",value:"linen"},
{key:81,text:"magenta",value:"magenta"},
{key:82,text:"maroon",value:"maroon"},
{key:83,text:"mediumaquamarine",value:"mediumaquamarine"},
{key:84,text:"mediumblue",value:"mediumblue"},
{key:85,text:"mediumorchid",value:"mediumorchid"},
{key:86,text:"mediumpurple",value:"mediumpurple"},
{key:87,text:"mediumseagreen",value:"mediumseagreen"},
{key:88,text:"mediumslateblue",value:"mediumslateblue"},
{key:89,text:"mediumspringgreen",value:"mediumspringgreen"},
{key:90,text:"mediumturquoise",value:"mediumturquoise"},
{key:91,text:"mediumvioletred",value:"mediumvioletred"},
{key:92,text:"midnightblue",value:"midnightblue"},
{key:93,text:"mintcream",value:"mintcream"},
{key:94,text:"mistyrose",value:"mistyrose"},
{key:95,text:"moccasin",value:"moccasin"},
{key:96,text:"navajowhite",value:"navajowhite"},
{key:97,text:"navy",value:"navy"},
{key:98,text:"navyblue",value:"navyblue"},
{key:99,text:"offwhite",value:"offwhite"},
{key:100,text:"oldlace",value:"oldlace"},
{key:101,text:"olive",value:"olive"},
{key:102,text:"olivedrab",value:"olivedrab"},
{key:103,text:"orange",value:"orange"},
{key:104,text:"orangered",value:"orangered"},
{key:105,text:"orchid",value:"orchid"},
{key:106,text:"palegoldenrod",value:"palegoldenrod"},
{key:107,text:"palegreen",value:"palegreen"},
{key:108,text:"paleturquoise",value:"paleturquoise"},
{key:109,text:"palevioletred",value:"palevioletred"},
{key:110,text:"papayawhip",value:"papayawhip"},
{key:111,text:"peachpuff",value:"peachpuff"},
{key:112,text:"peru",value:"peru"},
{key:113,text:"pink",value:"pink"},
{key:114,text:"plum",value:"plum"},
{key:115,text:"powderblue",value:"powderblue"},
{key:116,text:"purple",value:"purple"},
{key:117,text:"rebeccapurple",value:"rebeccapurple"},
{key:118,text:"red",value:"red"},
{key:119,text:"rosybrown",value:"rosybrown"},
{key:120,text:"royalblue",value:"royalblue"},
{key:121,text:"saddlebrown",value:"saddlebrown"},
{key:122,text:"salmon",value:"salmon"},
{key:123,text:"sandybrown",value:"sandybrown"},
{key:124,text:"seagreen",value:"seagreen"},
{key:125,text:"seashell",value:"seashell"},
{key:126,text:"sienna",value:"sienna"},
{key:127,text:"silver",value:"silver"},
{key:128,text:"skyblue",value:"skyblue"},
{key:129,text:"slateblue",value:"slateblue"},
{key:130,text:"slategray",value:"slategray"},
{key:131,text:"snow",value:"snow"},
{key:132,text:"springgreen",value:"springgreen"},
{key:133,text:"steelblue",value:"steelblue"},
{key:134,text:"tan",value:"tan"},
{key:135,text:"teal",value:"teal"},
{key:136,text:"thistle",value:"thistle"},
{key:137,text:"tomato",value:"tomato"},
{key:138,text:"turquoise",value:"turquoise"},
{key:139,text:"violet",value:"violet"},
{key:140,text:"wheat",value:"wheat"},
{key:141,text:"white",value:"white"},
{key:142,text:"whitesmoke",value:"whitesmoke"},
{key:143,text:"yellow",value:"yellow"},
{key:144,text:"yellowgreen",value:"yellowgreen"},









    //"Black","White","Blue","Red","Yellow","Green","Orange","Pink","Purple","Brown","Golden","Golden",
  ]);
  const [searchModels, setSearchModels] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [sixMonthCheckBox, setSixMonthCheckBox] = useState(false);
  const [tenMonthCheckBox, setTenMonthCheckBox] = useState(false);
  const [memberDiscount, setMemberDiscount] = useState(false);
  const [products,setProducts] = useState([])
  const [selectedProduct,setSelectedProduct] = useState()
  useEffect(() => {
    const loggedInUser = localStorage.getItem("user-data");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser);
      getUserDetails(foundUser._id);
      getAllProductsOnUserId(foundUser._id)
      .then(res => {
        let arr= res.Data
        if(arr && arr.length>0){
          arr.map(i=>{
            
            let arr2 = arr.map((item, index) => {
              // console.log(item)
              return { key: index + 1, text: item.prdct_attributes.prdct_name, value: item };
            });
            setProducts(arr2);
          })
        }
      })
    } else {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    const productName = productDetails.brand + " - " + productDetails.model;
    handleProductDetails("productName", productName)
  }, [productDetails.brand, productDetails.model])
  const handleProductDetails = (name, value) => {
    let data = productDetails;
    data = {
      ...productDetails,
      [name]: value,
    };
    setProductDetails(data);
  };
  const toggleSwitch = () => {
    console.log("callled");
    if (sixMonthCheckBox || tenMonthCheckBox) {
      setSixMonthCheckBox(false);
      setTenMonthCheckBox(false);
    } else {
      setSixMonthCheckBox(true);
    }
  };
  const getUserDetails = (id) => {
    getSingleStoreDetailsByUserIdWithOutMobileAndEmail(id)
      .then((res) => {
        //console.log(JSON.stringify(res.Data.sellCategory))
        const data = res.Data.sellCategory;
        setShopDetail(res.Data);
        getSellCategory(data);
      })
      .catch((err) => console.log("shop details err", err));
  };
  const getSellCategory = (data) => {
    let arr = [];
    let parentCate = [];
    parentCate = /*data.additionalInfo[0].sellCategory*/data;
    setCategories(parentCate);
    // console.log(parentCate);
    for (let i = 0; i < parentCate.length; i++) {
      const childCate = parentCate[i].childCategory?parentCate[i].childCategory:[];
      for (let j = 0; j < childCate.length; j++) {
        arr.push(childCate[j]);
      }
    }
    setSellCategory(arr);
  };
  const onSearchBrands = async (searchKey) => {
  const res = await searchBrandNames(searchKey);
  // console.log(res);
    let arr = [];
    arr.push({ key: 0, text: searchKey, value: searchKey });
    
    if (res.status === 200) {
    //  console.log("brands=>", res.data.brand);
      const brandNames = res.data.brand.map((item, index) => {
        return { key: index + 1, text: item, value: item };
      });
      setSearchBrands([...arr, ...brandNames]);
    }  else {
      setSearchBrands(arr);
    }
  };
  const onSearchModels = async (searchKey) => {
    const res = await searchBrandNames(searchKey);
    console.log(res);
    let arr = [];
    arr.push({ key: 0, text: searchKey, value: searchKey });
    
    if (res.status === 200) {
      // console.log("models=>", res.data.model);
      const modelNames = res.data.model.map((item, index) => {
        return { key: index + 1, text: item, value: item };
      });
      setSearchModels([...arr, ...modelNames]);
    } else {
      setSearchModels(arr);
    }
  };
  // const validation = () => {
  //   return (
  //     minOrderQuantity > 0 &&
  //     brandCheck &&
  //     modelCheck &&
  //     originalPrice > 0 &&
  //     discountedPrice >= 0 &&
  //     retailPrice > 0 &&
  //     discountedRetailPrice >= 0 &&
  //     category &&
  //     category !== ""
  //   );
  // };
  const handleSubmit = () => {
    if(selectedProduct){
      uploadProductInfo2()
    }else{
      getContentURL();
    }
  };
  const getContentURL = async () => {
    let imagesArr = [];
    let compressedImages = [];
    let videosArr = [];
    let s3Images = [];
    let s3Videos = [];
    let s3FileArray = [];
    for (let image of productDetails.images) {
      imagesArr.push(image);
    }
    for (let video of productDetails.videos) {
      videosArr.push(video);
    }
    //compress images first
    await Promise.all(
      imagesArr.map(async(imageFile) =>{
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          console.log(JSON.stringify(compressedFile));
          return compressedFile
          //await uploadToServer(compressedFile); // write your own logic
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      })
      ).then((res) => {
      compressedImages.push(...res);
    });
    await Promise.all(
      compressedImages.map((element) => directs3upload(element, "IMAGE"))
    ).then((res) => {
      s3Images.push(...res);
    });
    await Promise.all(
      videosArr.map((element) => directs3upload(element, "VIDEO"))
    ).then((res) => {
      s3Videos.push(...res)
      // console.log(
      //   'video s3 array.....asdasda............ :' +
      //     JSON.stringify(s3FileArray),
      // );
      s3FileArray = [...s3Images, ...s3Videos];
      uploadProductInfo(s3FileArray);
    });
  };
  const uploadProductInfo = async (s3FileArray) => {
    let parentCategory = "";
    // console.log("categries", JSON.stringify(categories), productDetails.category);
    for (let i = 0; i < categories.length; i++) {
      const childCategories = categories[i].childCategory?categories[i].childCategory:[];
      for (let j = 0; j < childCategories.length; j++) {
        const childCategory = childCategories[j];
        if (productDetails.category === childCategory) {
          parentCategory = categories[i].parentCategory;
          break;
        }
      }
      if (parentCategory !== "") {
        break;
      }
    }
    let body = {
      prdct_attributes: {
        category: {
          parentCategoryName: parentCategory,
          childCategoryName: productDetails.category,
        },
        prdct_name: productDetails.productName,
        brand: productDetails.brand,
        model: productDetails.model,
        original_price: productDetails.ogPrice,
        dsct_price: productDetails.dsctPrice,
        retail_price: productDetails.retailPrice,
        discounted_retail_price: productDetails.dsctRetailPrice,
        size: productDetails.size,
        colour: productDetails.colour,
        gender: productDetails.gender,
        ownership_type: productDetails.ownership,
        channel_type: productDetails.channel,
        description: productDetails.desc,
        media: s3FileArray,
        sellerSKU:productDetails.skuId
      },
      dispatch_time: productDetails.dispatchTime,
      // free_delivery: productDetails.delivery,
      // sample_charge: productDetails.sampleCharge,
      free_delivery: "100",
      sample_charge: productDetails.sampleCharge,
      sample_qty: productDetails.sampleQuantity,
      credit_booster: productDetails.minCreditInstallment,
      credit_period: sixMonthCheckBox ? "1" : tenMonthCheckBox ? "2" : "0",
      credit_isAvail: true,
      member_discount: productDetails.memberDsct,
      inventory_quantity: productDetails.sku,
      min_order_quantity: productDetails.moq,
      tags: productDetails.tags,
      content: [
        {
          name: "test",
          url: "test",
        },
      ],
    };
    console.log("body: " + JSON.stringify(body));
    try {
      uploadProductDetails(body).then((res) => {
        if(res.status==201 || res.status==200){
          setSubmitSuccess(true);
          setShowPopover(true);
        }else{
          alert(res.message)
        }
      }).catch(err => {
        setSubmitSuccess(false);
        setShowPopover(true);
      })
    } catch (err) {
      console.log("create product err", err);
      setSubmitSuccess(false);
      setShowPopover(true);
    }
  };



  const uploadProductInfo2 = async () => {
    let parentCategory = "";
    // console.log("categries", JSON.stringify(categories), productDetails.category);
    // for (let i = 0; i < categories.length; i++) {
    //   const childCategories = categories[i].childCategory?categories[i].childCategory:[];
    //   for (let j = 0; j < childCategories.length; j++) {
    //     const childCategory = childCategories[j];
    //     if (productDetails.category === childCategory) {
    //       parentCategory = categories[i].parentCategory;
    //       break;
    //     }
    //   }
    //   if (parentCategory !== "") {
    //     break;
    //   }
    // }
    let ogPrice = productDetails.ogPrice>0?productDetails.ogPrice:selectedProduct.prdct_attributes.original_price
    let discountPrice = productDetails.dsctPrice>0?productDetails.dsctPrice:selectedProduct.prdct_attributes.dsct_price
    let retailDiscountPrice = productDetails.dsctRetailPrice>0?productDetails.dsctRetailPrice:selectedProduct.prdct_attributes.discounted_retail_price
    let retailOgPrice = productDetails.retailPrice>0?productDetails.retailPrice:selectedProduct.prdct_attributes.retail_price
    let body = {
      _id:selectedProduct._id,
      prdct_attributes: {
        category: {
          parentCategoryName: selectedProduct.prdct_attributes.category.parentCategoryName,
          childCategoryName: selectedProduct.prdct_attributes.category.childCategoryName,
        },
        prdct_name: selectedProduct.prdct_attributes.prdct_name,
        brand: selectedProduct.prdct_attributes.brand,
        model: selectedProduct.prdct_attributes.model,
        original_price: productDetails.ogPrice>0?productDetails.ogPrice:selectedProduct.prdct_attributes.original_price,
        dsct_price: productDetails.dsctPrice>0?productDetails.dsctPrice:selectedProduct.prdct_attributes.dsct_price,
        dsct_percent: discountPrice<ogPrice?parseInt(100*(ogPrice-discountPrice)/ogPrice):0,
        retail_original_price: productDetails.retailPrice>0?productDetails.retailPrice:selectedProduct.prdct_attributes.retail_price,
        retail_dsct_price: productDetails.dsctRetailPrice>0?productDetails.dsctRetailPrice:selectedProduct.prdct_attributes.discounted_retail_price,
        retail_dsct_percent: retailDiscountPrice<retailOgPrice?parseInt(100*(retailOgPrice-retailDiscountPrice)/retailOgPrice):0,
        size: productDetails.size!=''?productDetails.size:selectedProduct.prdct_attributes.size,
        colour: productDetails.colour!=''?productDetails.colour:selectedProduct.prdct_attributes.colour,
        gender: productDetails.gender!=''?productDetails.gender:selectedProduct.prdct_attributes.gender,
        ownership_type: selectedProduct.prdct_attributes.ownership_type,
        channel_type: selectedProduct.prdct_attributes.channel_type,
        description: productDetails.desc!=''?productDetails.desc:selectedProduct.prdct_attributes.description,
        media: selectedProduct.prdct_attributes.media,
        sellerSKU:productDetails.skuId!=''?productDetails.skuId:selectedProduct.sellerSKU
      },
      dispatch_time: productDetails.dispatchTime!=''?productDetails.dispatchTime:selectedProduct.dispatch_time,
      // free_delivery: productDetails.delivery,
      // sample_charge: productDetails.sampleCharge,
      free_delivery: selectedProduct.free_delivery,
      sample_charge: productDetails.samplePrice>0?productDetails.samplePrice:selectedProduct.sample_charge,
      sample_qty: productDetails.sampleQty?productDetails.sampleQty:selectedProduct.sample_qty,
      credit_booster: selectedProduct.credit_booster,
      credit_period: selectedProduct.credit_period,
      credit_isAvail: selectedProduct.credit_isAvail,
      member_discount: productDetails.memberDsct?productDetails.memberDsct:selectedProduct.member_discount,
      inventory_quantity: productDetails.sku?productDetails.sku:selectedProduct.inventory_quantity,
      min_order_quantity: productDetails.moq>0?productDetails.moq:selectedProduct.min_order_quantity,
      tags: selectedProduct.tags,
      content: selectedProduct.content,
    };
    console.log("body: " + JSON.stringify(body));
    try {
      updateProductDetails(body).then((res) => {
        if(res.status==201 || res.status==200){
          setSubmitSuccess(true);
          setShowPopover(true);
        }else{
          alert(res.message)
        }
      }).catch(err => {
        console.log("create product err", JSON.stringify(err));
        // setSubmitSuccess(false);
        // setShowPopover(true);
      })
    } catch (err) {
      console.log("create product err", JSON.stringify(err));
      // setSubmitSuccess(false);
      // setShowPopover(true);
    }
  };

  const uploadProductInfo3 = async () => {
    let parentCategory = "";
    // console.log("categries", JSON.stringify(categories), productDetails.category);
    // for (let i = 0; i < categories.length; i++) {
    //   const childCategories = categories[i].childCategory?categories[i].childCategory:[];
    //   for (let j = 0; j < childCategories.length; j++) {
    //     const childCategory = childCategories[j];
    //     if (productDetails.category === childCategory) {
    //       parentCategory = categories[i].parentCategory;
    //       break;
    //     }
    //   }
    //   if (parentCategory !== "") {
    //     break;
    //   }
    // }
    let body = {
      // _id:selectedProduct._id,
      prdct_attributes: {
        category: {
          parentCategoryName: selectedProduct.prdct_attributes.category.parentCategoryName,
          childCategoryName: selectedProduct.prdct_attributes.category.childCategoryName,
        },
        prdct_name: selectedProduct.prdct_attributes.prdct_name,
        brand: selectedProduct.prdct_attributes.brand,
        model: selectedProduct.prdct_attributes.model,
        original_price: productDetails.ogPrice>0?productDetails.ogPrice:selectedProduct.prdct_attributes.original_price,
        dsct_price: productDetails.dsctPrice>0?productDetails.dsctPrice:selectedProduct.prdct_attributes.dsct_price,
        retail_price: productDetails.retailPrice>0?productDetails.retailPrice:selectedProduct.prdct_attributes.retail_price,
        discounted_retail_price: productDetails.dsctRetailPrice>0?productDetails.dsctRetailPrice:selectedProduct.prdct_attributes.discounted_retail_price,
        size: productDetails.size!=''?productDetails.size:selectedProduct.prdct_attributes.size,
        colour: productDetails.colour!=''?productDetails.colour:selectedProduct.prdct_attributes.colour,
        gender: productDetails.gender!=''?productDetails.gender:selectedProduct.prdct_attributes.gender,
        ownership_type: selectedProduct.prdct_attributes.ownership_type,
        channel_type: selectedProduct.prdct_attributes.channel_type,
        description: productDetails.desc!=''?productDetails.desc:selectedProduct.prdct_attributes.description,
        media: selectedProduct.prdct_attributes.media,
        sellerSKU:productDetails.skuId!=''?productDetails.skuId:selectedProduct.sellerSKU
      },
      dispatch_time: productDetails.dispatchTime!=''?productDetails.dispatchTime:selectedProduct.dispatch_time,
      // free_delivery: productDetails.delivery,
      // sample_charge: productDetails.sampleCharge,
      free_delivery: selectedProduct.free_delivery,
      sample_charge: productDetails.samplePrice>0?productDetails.samplePrice:selectedProduct.sample_charge,
      sample_qty: productDetails.sampleQty?productDetails.sampleQty:selectedProduct.sample_qty,
      credit_booster: selectedProduct.credit_booster,
      credit_period: selectedProduct.credit_period,
      credit_isAvail: selectedProduct.credit_isAvail,
      member_discount: productDetails.memberDsct?productDetails.memberDsct:selectedProduct.member_discount,
      inventory_quantity: productDetails.sku?productDetails.sku:selectedProduct.inventory_quantity,
      min_order_quantity: productDetails.moq>0?productDetails.moq:selectedProduct.min_order_quantity,
      tags: selectedProduct.tags,
      content: selectedProduct.content,
    };
    console.log("body: " + JSON.stringify(body));
    try {
      uploadProductDetails(body).then((res) => {
        if(res.status==201 || res.status==200){
          setSubmitSuccess(true);
          setShowPopover(true);
        }else{
          alert(res.message)
        }
      }).catch(err => {
        console.log("create product err", JSON.stringify(err));
        alert("Wrong details!")
        // setSubmitSuccess(false);
        // setShowPopover(true);
      })
    } catch (err) {
      console.log("create product err", err);
      alert("Wrong details!")
      // setSubmitSuccess(false);
      // setShowPopover(true);
    }
  };
  // const handleLogout = () => {
  // //   setUser({});
  // //   setUsername("");
  // //   setPassword("");
  //   localStorage.clear();
  // };
  const Size = ({ title, value }) => {
    return (
      <button
        style={{
          height: 25,
          backgroundColor: value === productDetails.size ? "#679EE5" : "#fff",
          paddingLeft: 25,
          paddingRight: 25,
          borderRadius: 11,
          borderWidth: 1,
          borderColor: "#679EE5",
          marginLeft: 6,
        }}
        onClick={() => handleProductDetails("size", value)}
      >
        <p
          style={{
            color: value === productDetails.size ? "#fff" : "#679EE5",
            fontSize: 14,
            fontFamily: "DidactGothic-Regular",
          }}
        >
          {title}
        </p>
      </button>
    );
  };
  return (
    <div>
      <SubmitPopover
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
        message="The product is added successfully"
      />
      <h3>Inventory Dashboard</h3>
      {/* <div>
          <label htmlFor="selectedProduct">Selected Product</label>
          <Dropdown
            placeholder="Select Product"
            selection
            options={products}
            onChange={(event, { value }) =>{
              setSelectedProduct(value)
              // handleProductDetails('colour',value.prdct_attributes.colour),
              // handleProductDetails('productName',value.prdct_attributes.prdct_name),
              // handleProductDetails('brand',value.prdct_attributes.brand);
              // handleProductDetails('model',value.prdct_attributes.model);
              // handleProductDetails('moq',value.prdct_attributes.moq);
              // handleProductDetails('ogPrice',value.prdct_attributes.ogPrice);
              // handleProductDetails('dsctPrice',value.prdct_attributes.dsctPrice);
              // handleProductDetails('retailPrice',value.prdct_attributes.retailPrice);
              // handleProductDetails('dsctRetailPrice',value.prdct_attributes.dsctRetailPrice);
              // handleProductDetails('skuId',value.prdct_attributes.sellerSKU);
              // handleProductDetails('size',value.prdct_attributes.size);

            }
            }
           // onSearchChange={(e) => onSearchBrands(e.target.value)}
          />
        </div> */}
        {products.map(product=>{
          // console.log(product)
          return (
            <form  id={product.value._id} action="" spellCheck={false}>
            <div  style={{display:"flex",overflowX:"scroll"}} >
              <div>
          <label htmlFor="productName">product name</label>
          <input
            type="text"
            name="productName"
            disabled={true}
            placeholder={product.value.prdct_attributes.prdct_name}
            value={productDetails.productName?productDetails.name:product.value.prdct_attributes.prdct_name}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
          />
        </div>
        <div>
          <label htmlFor="brand">brand</label>
          <Dropdown
            placeholder={product.value.prdct_attributes.brand}
            disabled={selectedProduct?true:false}
            search
            selection
            options={searchBrands}
            onChange={(event, { value }) =>
              handleProductDetails("brand", value)
            }
            onSearchChange={(e) => onSearchBrands(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="model">model</label>
          <Dropdown
            placeholder={product.value.prdct_attributes.model}
            disabled={selectedProduct?true:false}
            search
            selection
            options={searchModels}
            onChange={(event, { value }) =>
              handleProductDetails("model", value)
            }
            onSearchChange={(e) => onSearchModels(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="colour">colour</label>
          <Dropdown
            placeholder={product.value.prdct_attributes.colour}
            selection
            options={searchColours}
            onChange={(event, { value }) =>
              handleProductDetails("colour", value)
            }
           // onSearchChange={(e) => onSearchBrands(e.target.value)}
          />
        </div>
              <div>
          <label htmlFor="images">Add/Edit Product Images</label>
          <div>
            {product.value.prdct_attributes.media && product.value.prdct_attributes.media.map(i=>{
              return i.type!='VIDEO'?(
                // <div className={styles.socialMediaLinks}>
                //   <a
                //   href={i.type=='VIDEO'?i.thumbnail:i.url}
                //   target={"_blank"}
                // ></a>
                // </div>
                <a href={i.type=='VIDEO'?i.thumbnail:i.url} target="_blank" rel="noopener noreferrer">
                  <img
                    className={styles.socialMediaLinks}
                    // src={require("../../assets/images/iphone-mockup-sec1-1.png")}
                    src={i.type=='VIDEO'?i.thumbnail:i.url}
                    style={{height:'39px',width:'39px'}}
                    // alt="app-homescreen-mockup"
                    // data-aos="fade-up"
                    // data-aos-duration="2000"
                  />
                </a>
              ):null
            })}
          </div>
          <input
            disabled={selectedProduct?true:false}
            type="file"
            name="images"
            accept="image/*"
            multiple={true}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.files)
            }
          />
        </div>
        <div>
          <label htmlFor="videos">Add/Edit Product Videos &#40;Optional&#41;</label>
          <div>
            {product.value.prdct_attributes.media && product.value.prdct_attributes.media.map(i=>{
              return i.type=='VIDEO'?(
                // <div className={styles.socialMediaLinks}>
                //   <a
                //   href={i.type=='VIDEO'?i.thumbnail:i.url}
                //   target={"_blank"}
                // ></a>
                // </div>
                <a href={i.url} target="_blank" rel="noopener noreferrer">
                  <img
                    className={styles.socialMediaLinks}
                    // src={require("../../assets/images/iphone-mockup-sec1-1.png")}
                    src={i.thumbnail}
                    style={{height:'39px',width:'39px'}}
                    // alt="app-homescreen-mockup"
                    // data-aos="fade-up"
                    // data-aos-duration="2000"
                  />
                </a>
              ):null
            })}
          </div>
          <input
            disabled
            type="file"
            name="videos"
            accept="video/mp4,video/x-m4v,video/*"
            multiple={true}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.files)
            }
          />
        </div>
        
        
        
        <div>
          <label htmlFor="moq">moq</label>
          <input
            type="text"
            name="moq"
            placeholder="MOQ"
            value={productDetails.moq?productDetails.moq :product.value.min_order_quantity}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value === "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
        </div>
        <div>
          <label htmlFor="ogPrice">original price</label>
          <input
            type="text"
            name="ogPrice"
            placeholder="Original Price"
            value={productDetails.ogPrice?productDetails.ogPrice :product.value.prdct_attributes.original_price}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value === "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
        </div>
        <div>
          <label htmlFor="dsctPrice">discounted price</label>
          <input
            type="text"
            name="dsctPrice"
            placeholder="Discount Price"
            value={productDetails.dsctPrice?productDetails.dsctPrice :product.value.prdct_attributes.dsct_price}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value === "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
        </div>
        <div>
          <label htmlFor="retailPRrice">retail price</label>
          <input
            type="text"
            name="retailPrice"
            placeholder="Retail Price"
            value={productDetails.retailPrice?productDetails.retailPrice :product.value.prdct_attributes.retail_original_price}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value === "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
        </div>
        <div>
          <label htmlFor="dsctRetailPrice">discounted retail price</label>
          <input
            type="text"
            name="dsctRetailPrice"
            placeholder="Discounted Retail Price"
            value={productDetails.dsctRetailPrice?productDetails.dsctRetailPrice :product.value.prdct_attributes.retail_dsct_price}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value === "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
        </div>
        <div>
          <label htmlFor="skuId">SKU Id</label>
          <input
            type="text"
            name="skuId"
            placeholder="enter sku id"
            value={productDetails.skuId?productDetails.skuId :product.value.sellerSKU}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value
              )
            }
          />
        </div>
        <div>
          <label htmlFor="sku">SKU Quntity</label>
          <input
            type="text"
            name="sku"
            placeholder="SKU"
            value={productDetails.sku?productDetails.sku: product.value.inventory_quantity}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value === "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
        </div>
        <div>
          <label htmlFor="dispatchTime">dispatch time</label>
          <select
            name="dispatchTime"
            value={productDetails.dispatchTime?productDetails.dispatchTime:product.value.dispatch_time}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
            style={{
              // borderWidth: 0,
              backgroundColor: "#0000",
            }}
          >
            <option>Select Dispatch Time</option>
            <option value="1-2 Days">1-2 Days</option>
            <option value="3-6 Days">3-6 Days</option>
          </select>
        </div>
        <div>
          <label htmlFor="category">Category</label>
          <select
            disabled={selectedProduct?true:false}
            name="category"
            value={productDetails.category?productDetails.category:product.value.prdct_attributes.category.childCategoryName}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
            style={{
              // borderWidth: 0,
              backgroundColor: "#0000",
            }}
          >
            <option>Select Category</option>
            {sellCategory.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="gender">gender</label>
          <select
            name="gender"
            value={productDetails.gender?productDetails.gender:product.value.prdct_attributes.gender  }
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
            style={{
              width: "30%",
              // borderWidth: 0,
              backgroundColor: "#0000",
            }}
          >
            <option>Select Gender</option>
            <option value="Men">Men</option>
            <option value="Women">Women</option>
            <option value="Kids">Kids</option>
            <option value="Universal">Universal</option>
          </select>
        </div>
        <div>
          <label htmlFor="owndership">ownership type</label>
          <select
            disabled={selectedProduct?true:false}
            name="ownership"
            value={productDetails.ownership?productDetails.ownership:product.value.prdct_attributes.ownership_type}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
            style={{
              // borderWidth: 0,
              backgroundColor: "#0000",
            }}
          >
            <option>Select Ownership Type</option>
            <option value="Brand">Brand</option>
            <option value="Reseller">Reseller</option>
          </select>
        </div>
        <div>
          <label htmlFor="channel">channel type</label>
          <select
          disabled={selectedProduct?true:false}
            name="channel"
            value={productDetails.channel?productDetails.channel:product.value.prdct_attributes.channel_type}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
            style={{
              // borderWidth: 0,
              backgroundColor: "#0000",
            }}
          >
            <option>Select Channel</option>
            <option value="Online">Online</option>
            <option value="Offline">Offline</option>
            <option value="Omni">OmniChannel</option>
          </select>
        </div>
        <div>
          <label htmlFor="size">size</label>
          <input
            type="text"
            name="size"
            placeholder="Size"
            value={productDetails.size?productDetails.size:product.value.prdct_attributes.size}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value //=== "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
          <div style={{display:"flex",overflowX:"scroll"}}>
            <Size title="Small" value={38} />
            <Size title="Medium" value={40} />
            <Size title="Large" value={42} />
          </div>
        </div>
        <div>
          <label htmlFor="tags">tags</label>
          <input
            type="text"
            name="tags"
            placeholder="Tags (Seperate tags using comma)"
            value={productDetails.tags?productDetails.tags:product.value.tags.toString()}
            onChange={(e) => {
              const tags = e.target.value.split(",").map((item) => item.trim());
              handleProductDetails(e.target.name, tags);
            }}
          />
        </div>
        <div>
          <label htmlFor="delivery">Delivery</label>
          <select
            name="delivery"
            value={productDetails.delivery?productDetails.delivery:product.value.free_delivery?'Charged':'Free'}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
            style={{
              // borderWidth: 0,
              backgroundColor: "#0000",
            }}
          >
            <option>Select Option</option>
            <option value="Free">Free</option>
            <option value="Charged">Charged</option>
          </select>
        </div>
        <div>
          <label htmlFor="samples">Samples</label>
          <select
            name="samples"
            value={productDetails.samples?productDetails.samples:product.value.sample_charge?'Charged':'Free'}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
            style={{
              // borderWidth: 0,
              backgroundColor: "#0000",
            }}
          >
            <option>Select Option</option>
            <option value="Free">Free</option>
            <option value="Charged">Charged</option>
          </select>
        </div>
        <div>
          <label htmlFor="sampleQty">sample quantity</label>
          <input
            type="text"
            name="sampleQty"
            placeholder="Sample Quantity"
            value={productDetails.sampleQty?productDetails.sampleQty:product.value.sample_qty}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value === "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
        </div>
        <div>
          <label htmlFor="samplePrice">sample price</label>
          <input
            type="text"
            name="samplePrice"
            placeholder="Sample Price"
            value={productDetails.samplePrice?productDetails.samplePrice:product.value.sample_charge}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value === "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
        </div>
        <div>
          <div>
            <label htmlFor="creditBooster">credit booster</label>
            <input
              type="radio"
              name="creditBooster"
              value={sixMonthCheckBox || tenMonthCheckBox}
              checked={sixMonthCheckBox || tenMonthCheckBox}
              onClick={() => toggleSwitch()}
            />
          </div>
          <div>
            <label>1 month installment</label>
            <input
              type="radio"
              name="sixMonthCreditBooster"
              value={sixMonthCheckBox}
              checked={sixMonthCheckBox}
              onClick={() => {
                setSixMonthCheckBox(!sixMonthCheckBox);
                setTenMonthCheckBox(false);
              }}
            />
          </div>
          <div>
            <label>2 months installment</label>
            <input
              disabled
              type="radio"
              name="tenMonthCreditBooster"
              value={tenMonthCheckBox}
              checked={tenMonthCheckBox}
              onClick={() => {
                setTenMonthCheckBox(!tenMonthCheckBox);
                setSixMonthCheckBox(false);
              }}
            />
          </div>
        </div>
        <div>
          <label htmlFor="minCreditInstallment">
            Minimum credit installment
          </label>
          <input
            type="text"
            name="minCreditInstallment"
            placeholder="Minimum Credit Installment"
            value={productDetails.minCreditInstallment}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
          />
        </div>
        <div>
          <label htmlFor="memberDsctPrice">members discount</label>
          <input
            type="radio"
            checked={memberDiscount}
            onClick={() => setMemberDiscount((prev) => !prev)}
          />
          <input
            type="text"
            name="memberDsct"
            placeholder="Member's Discount Price"
            value={productDetails.memberDsct}
            onChange={(e) =>
              handleProductDetails(
                e.target.name,
                e.target.value === "" ? 0 : parseInt(e.target.value, 10)
              )
            }
          />
        </div>
        <div>
          <label htmlFor="autorenewal">autorenewal</label>
          <input
            type="radio"
            name="autorenewal"
            value={productDetails.autorenewal}
            // checked={this.state.site === result.SITE_NAME}
            // onChange={this.onSiteChanged}
          />
        </div>
        <div>
          <label htmlFor="desc">description</label>
          <textarea
            rows={7}
            cols={40}
            maxLength={300}
            name="desc"
            placeholder={product.value.prdct_attributes.description?product.value.prdct_attributes.description:"Description"}
            className={styles.textarea}
            onChange={(e) =>
              handleProductDetails(e.target.name, e.target.value)
            }
          />
        </div>
        {/* <div>
          <button
          disabled={selectedProduct?true:false}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            Add Product  
          </button>
        </div> */}
        <div>
          <button
          // disabled={selectedProduct?false:true}
            onClick={(e) => {
              e.preventDefault();
              uploadProductInfo2();
            }}
          >
            Update Product
          </button>
        </div>
        {/* <div>
          <button
          disabled={selectedProduct?false:true}
            onClick={(e) => {
              e.preventDefault();
              uploadProductInfo3();
            }}
          >
            Add Variant
          </button>
        </div> */}
            </div>
      </form>
          )
        })}
        
    </div>
  );
};
export default InventoryDashboard;