import S3FileUpload from 'react-s3/lib/ReactS3';
import ReactS3Client from 'react-aws-s3-typescript';
import commonFetch from './commonApi'
import { APP_URL, s3accessKey, s3secretKey } from './ApiConstants'

export function saveOrder2(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}euOrder/saveOrder2`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function reachoutCreator(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}adminDataColl/reachoutCreator`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function saveB2BOrder2(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}orders/saveOrder2`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}
export function saveOrderWebWithoutPay(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}orders/saveOrderWebWithoutPay`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function saveB2COrderWebWithoutPay(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}euOrder/saveOrderWebWithoutPay`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}


export function saveOrder3(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}euOrder/saveOrder3`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function AppSearchQuery(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}app/webSearch`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getAllUniqueSearcheswithUserInfo(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}userData/getAllUniqueSearcheswithUserInfo`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getAllUniqueWithFilterKeySearcheswithUserInfo(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}userData/getAllUniqueWithFilterKeySearcheswithUserInfo`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getPostsByQueryId(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}inflncr/getPostsByQueryId`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getPostsByText(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}app/search`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getUniqueSearcheswithUserInfo(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}userData/getUniqueSearcheswithUserInfo`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}


export function registerNewInfluencer(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}endUsers/registerNewUser`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getEUserDetailsbyEmailId(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}endUsers/getEUserDetailsbyEmailId`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getUserbyEmail(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}users/getUserbyEmail/${data}`,
      // data: data,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function registerNewUser(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}endUsers/registerNewUser`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function becomeASeller(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}users/becomeASeller/`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function becomeABuyer(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}users/registerNewUser`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function registerRetailer(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}users/registerNewUser`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function singleStepOnboardSeller(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}users/singleStepOnboardUser`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getAllInflncrCate(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}inflncr/getAllInflncrCate`,
      data: data,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

// export function getInfluencerDetails(InfluencerId) {
//   return new Promise((success, failure) => {
//     commonFetch({
//       url: `${APP_URL}inflncr/getInfluencerDetails/${InfluencerId}`,
//       method: "GET",
//     })
//       .then((res) => {
//         res.json().then((result) => {
//           success(result);
//         });
//       })
//       .catch((err) => {
//         failure(err);
//       });
//   });
// }

export function getSinlgeInfluencerDetailsWithOutMobileAndEmail(InfluencerId) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}inflncr/getSinlgeInfluencerDetailsWithOutMobileAndEmail/${InfluencerId}`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getAllParentCategory(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}category/getAllParentCategory`,
      data: data,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function allBrands(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}products/allBrands`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getNearbyShopsDetailsWithCategoryAndBrandFilter(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}shop/getNearbyShopsDetailsWithCategoryAndBrandFilter`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getNearbyPartnerBrandStores(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}shop/getNearbyPartnerBrandStores`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getProductDetails(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}products/getProductOnId/${data}`,
      // data: data,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getAllCities(data) {
  return new Promise((success, failure) => {
                      commonFetch({
      url: `${APP_URL}city/getAllcities`,
      data: data,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

// export function getAllUsers(data) {
//   return new Promise((success, failure) => {
//     commonFetch({
//       url: `${APP_URL}users/getAllUsers`,
//       data: data,
//       method: "GET",
//     })
//       .then((res) => {
//         res.json().then((result) => {
//           success(result);
//         });
//       })
//       .catch((err) => {
//         failure(err);
//       });
//   });
// }
export function getAllUserShopDetailsWithOutMobileAndEmail(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}users/getAllUserShopDetailsWithOutMobileAndEmail`,
      data: data,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}
export function getAllShopsDetailsWithoutUserInfo(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}shop/getAllShopsDetailsWithoutUserInfo`,
      data: data,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getAllProductsOnUserId(id) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}products/getProductOnUserId/${id}/1/10000`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getTotalProductsOnUserId(id) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}products/getAllProductOnUserId/${id}/true/1/10000`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function enableDisablePrdct(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}products/enableDisablePrdct`,
      data: data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getAllStates(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}states/getAllStates`,
      data: data,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export function getAllCountries(data) {
  return new Promise((success, failure) => {
    commonFetch({
      url: `${APP_URL}country/getAllCountries`,
      data: data,
      method: "GET",
    })
      .then((res) => {
        res.json().then((result) => {
          success(result);
        });
      })
      .catch((err) => {
        failure(err);
      });
  });
}

export const findArrayElementByTitle = (array, title) => {
  if (title !== "") {
    let matchedArrayElement = array.find((element) => element._id === title);
    return matchedArrayElement.name;
  } else {
    return null;
  }
};
  
export const loginSeller = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}users/login`,
      data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const activateSeller = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}users/approveSeller`,
      data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateMyStore = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}shop/updateMyStore`,
      data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSpecificPost = (infId,postId) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/getSpecificPost/${infId}/${postId}`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getMentionItems = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}mention/getMyItems`,
      data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getInfluencerPosts = (infId) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/getInfluencerPosts/${infId}/all/1/1000`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const updatePostDetWeb = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/updatePostDetWeb`,
      data,
      method: "POST"
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getAllInfluencersPostsWithoutMobileAndEmail = (page) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/getAllInfluencersPostsWithoutMobileAndEmail/all/${page}/10`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getPostsByTaggedStore = (email) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/getPostsByTaggedStore/${email}`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getPostsByTaggedStoreUserId = (email) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/getPostsByTaggedStoreUserId/${email}`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getAllInfluencers = (email) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/getAllInfluencers`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getAllInfluencersWithOutMobileAndEmail = (email) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/getAllInfluencersWithOutMobileAndEmail`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getAllInfluencersPostsWithMentionsByEmail = (email) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/getAllInfluencersPostsWithMentionsByEmail/${email}/all/1/10`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const getAllMentionsWithSourcePostByTaggedStore = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}mention/getAllMentionsWithSourcePostByTaggedStore`,
      method: "POST",
      data
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getAllMentionsWithSourcePostByTaggedStoreWithUserId = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}mention/getAllMentionsWithSourcePostByTaggedStoreWithUserId`,
      method: "POST",
      data
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const getAllValidatedMentionsWithSourcePostForServiceProviders = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}mention/getAllValidatedMentionsWithSourcePostForServiceProviders`,
      method: "POST",
      data
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getAllMentionsWithSourcePostWithOutMobileAndEmail = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}mention/getAllMentionsWithSourcePostWithOutMobileAndEmail`,
      method: "POST",
      data
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// export const getShopDetails1 = (id) => {
//   return new Promise((resolve, reject) => {
//     commonFetch({
//       url: `${APP_URL}users/getUserDetails/${id}`,
//       method: "GET",
//     })
//       .then((res) => {
//         res.json().then((res) => {
//           resolve(res);
//         });
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// }
// export const getShopDetails = (id) => {
//   return new Promise((resolve, reject) => {
//     commonFetch({
//       url: `${APP_URL}shop/getShopDetailsByUser/${id}`,
//       method: "GET",
//     })
//       .then((res) => {
//         res.json().then((res) => {
//           resolve(res);
//         });
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// }

export const getSingleStoreDetailsByUserIdWithOutMobileAndEmail = (id) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}shop/getSingleStoreDetailsByUserIdWithOutMobileAndEmail/${id}`,
      method: "GET",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const searchBrandNames = (searchKey) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}products/searchPrdAttr`,
      data: {
        key: searchKey,
      },
      method: "POST",
    })
      .then((res) => {
        // console.log(res)
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const searchStoreOrBrand = (searchKey) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}shop/searchShop`,
      data: {
        "page": 1,
        "limit": 100,
        "searchValue":searchKey
        // "lat": currentLocation?.coords?.latitude,
        // "long": currentLocation?.coords?.longitude
      },
      method: "POST",
    })
      .then((res) => {
        // console.log(res)
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export const searchTag = (searchKey) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}tag/searchTag`,
      data: {
        "page": 1,
        "limit": 100,
        "searchValue":searchKey
        // "lat": currentLocation?.coords?.latitude,
        // "long": currentLocation?.coords?.longitude
      },
      method: "POST",
    })
      .then((res) => {
        // console.log(res)
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getProductsOnBrandName = (searchKey,limit) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}products/getProductsOnBrandName`,
      data: {
        brand: searchKey,
        page:1,
        limit: limit,
        // pagination:{
        //   page:1,
        //   limit: limit
        // }
      },
      method: "POST",
    })
      .then((res) => {
        // console.log(res)
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const getProductsOnBrandNameWithoutToken = (searchKey,limit) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}products/getProductsOnBrandNameWithoutToken`,
      data: {
        brand: searchKey,
        page:1,
        limit: limit,
        // pagination:{
        //   page:1,
        //   limit: limit
        // }
      },
      method: "POST",
    })
      .then((res) => {
        // console.log(res)
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// upload image/video to aws
export const directs3upload = async (
  element,
  mediaType
) => {
  let filename = element.name.replace(/\.[^/.]+$/, "");
  // ! remove the file extension from name end
  let match = /\.(\w+)$/.exec(filename);
  console.log("type.........................." + JSON.stringify(match));
  let type = `multipart/form-data`;
  const options = {
    keyPrefix: "products/",
    bucketName: "eyseapp",
    region: "ap-south-1",
    accessKeyId: s3accessKey,
    secretAccessKey: s3secretKey,
    successActionStatus: 201,
  };
  let file = {
    uri: element,
    name: filename,
    type: type,
  };

  const s3 = new ReactS3Client(options);

  const res = await s3.uploadFile(element, filename).then((result) => {
    console.log(JSON.stringify(result));

    console.log("logging S3 response ..........................");
    // console.log(
    //   "S3 response .........................." + JSON.stringify(result)
    // );
    const mediaElement = {
      name: result.key,
      url: result.location,
      type: mediaType,
      //video: element
    };
    
    return mediaElement;
  });

  console.log(res);
  return res;
};

export const addReview = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}inflncr/addReview`,
      data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadProductDetails = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}products/createNewProduct`,
      data,
      method: "POST",
    })
      .then((res) => {
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

  // axios
  //   .post(`${BASE_URI}products/createNewProduct`, body, {
  //     headers: {
  //       Authorization: `${userData.token}`,
  //     },
  //   })
  //   .then((result) => {
  //     setStatus(JSON.stringify(result.data.status));
  //     if (JSON.stringify(result.data.status) === "201") {
  //       setLoading(false);
  //       // "product created" msg
  //       clickConfirm();
  //     }
  //   })
  //   .catch((err) => {
  //     setLoading(false);
  //     if (err.message === "Request failed with status code 409") {
  //       // "Product already exists" msg here
  //     } else {
  //       // error message here
  //     }
  //   });
};

export const updateProductDetails = (data) => {
  return new Promise((resolve, reject) => {
    commonFetch({
      url: `${APP_URL}products/updateProduct`,
      data,
      method: "POST",
    })
      .then((res) => {
        console.log(res)
        res.json().then((res) => {
          resolve(res);
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

  // axios
  //   .post(`${BASE_URI}products/createNewProduct`, body, {
  //     headers: {
  //       Authorization: `${userData.token}`,
  //     },
  //   })
  //   .then((result) => {
  //     setStatus(JSON.stringify(result.data.status));
  //     if (JSON.stringify(result.data.status) === "201") {
  //       setLoading(false);
  //       // "product created" msg
  //       clickConfirm();
  //     }
  //   })
  //   .catch((err) => {
  //     setLoading(false);
  //     if (err.message === "Request failed with status code 409") {
  //       // "Product already exists" msg here
  //     } else {
  //       // error message here
  //     }
  //   });
};