import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { AppSearchQuery, getAllShopsDetails, getAllShopsDetailsWithoutUserInfo, getAllUniqueSearcheswithUserInfo, getAllUniqueWithFilterKeySearcheswithUserInfo, getAllUserShops, getProductDetails, getUniqueSearcheswithUserInfo, registerRetailer, saveOrder } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import SubmitPopover3 from "../../components/SubmitPopover/SubmitPopover3";
import styles1 from "../Home/index.module.css"
import BrandHeader from "./BrandHeader";
import StoreHeader from "./StoreHeader";
import InfluencerHeader from "./InfluencerHeader";
import {WhatsappShareButton,WhatsappIcon } from "react-share";
import IndependentHeader from "./IndependentHeader";

const ViewStores = (props) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [brandDetails, setBrandDetails] = useState({
    quantity:0,
    
    firstName: "",
    lastName: "",
    houseNumber:"",
    brandName: "",
    contactNum: "",
    emailId: "",
    street:"",
    ladnmark: "",
    city: "",
    state:"",
    country:"",
    pincode:""
  });
  const [error, setError] = useState({
    quantity:false,
    
    firstName: false,
    lastName:false,
    houseNumber:false,
    brandName: false,
    contactNum: false,
    emailId: false,
    street:false,
    ladnmark: false,
    city: false,
    state:false,
    country:false,
    pincode:false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product,setProduct] = useState()
  let {storeUserId, brandUserId,influencerId,b2c}=useParams()
  const [orderLoading,setOrderLoading] = useState(false)
  const [searchKey,setSearchKey] = useState()
  const [queries,setQueries] = useState([])
  const [shopDetails,setShopDetails] = useState()
  const [influencerDetails,setInfluencerDetails] = useState()
  const [allUserShops,setAllUserShops] = useState([])

  useEffect(()=>{
    getAllShopsDetailsWithoutUserInfo()
        .then(res => {
          console.log(res)
          if (res.Data) {
              let arr = res.Data
              arr.sort((a,b)=>a.name>b.name?1:-1)
              setAllUserShops(arr)
          }
            // })
          // }
        })
  },[])

  return (
    <div className={styles.body}>
      <SubmitPopover3
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      {b2c&&<IndependentHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {/* <div className={styles.sectionHead}>
      <div style={{ alignSelf: "center" }}>
        <Link to="/">
          <img
            className={styles1.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
          </Link>
        </div>
        <div className={styles.pageHeadings}>
          <span className={styles.pageHeading} ref={h1}></span>{" "}
        </div>
      </div>
      <div className={styles.border}>
        <div></div>
      </div> */}
      <div className={styles.border}>
        <div></div>
      </div>
      <div  style={{maxHeight:height,overflowY:"scroll",paddingBottom:20}}>
          <h5>Showing: {allUserShops.length} Stores</h5>
          {allUserShops.map(userShop=>{
          // console.log(query)
          return (
            <div style={{display:"flex",overflowX:"scroll"}} >
              <h5>{userShop.name}  </h5>
              <Link to={b2c?`/viewstore/`+`${b2c}`+`/`+`${userShop.user_id._id}`:`/viewstore/`+`${userShop.user_id._id}`} className={styles1.btn}>
                 View
                </Link>
                {/* <a
                href={window.location.host + `/viewstore/${userShop.user_id}`}
                target={"_blank"}
              >View</a> */}
                <WhatsappShareButton
                    url={b2c?(`${window.location.host}/viewstore/`+`${b2c}`+`/`+`${userShop.user_id._id}`):(`${window.location.host}/viewstore/`+`${userShop.user_id._id}`)}
                    title={userShop.name}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
            </div>
          )})}
          </div>
      <Footer />
    </div>
  );
}

export default ViewStores;