import React from "react";
import styles from "./index.module.css";

const PrivacyPolicy = () => {
    return (
      <div className={styles.main}>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy of eyseapp.com is made effective immediately by
          Feature Ventures LLP which has its registered address at Jhorehat,
          Andul Station Road, Howrah, West Bengal-711106
          <br />
          <br />
          The terms “we” and “us” refer to EYSE and its affiliates, and the term
          “you” refers to you, the user of the website.
        </p>
        <div className={styles.info}>
          <div>
            <h2>General</h2>
            <p>
              This policy covers how we treat your information (including
              personal information) that we collect and receive, including
              information related to your visit or use (including past visits or
              use) of our websites, emails, platforms, mobile sites,
              applications, other products and/or services (“ Site ”).
              <br />
              <br />
              You should read this policy before using the Site. By using or
              accessing the Site, or any part of it, you agree and acknowledge
              that you have read and understood the policy and consent to the
              collection, use and disclosure of your information as described in
              this policy. You should not use or access the Site, or any part of
              it, if you do not agree with any portion of this policy.
            </p>
          </div>
          <div>
            <h2>Information that we collect</h2>
            <p>
              When you use the Site or register with us, you will provide us
              with information including, without limitation, your demographic
              information, your transaction information and your personal
              information. Further, during your access and use of the Site, we
              may learn and collect additional information from and about you,
              such as preferences you set, photographs and videos you upload and
              comments you make.
              <br />
              <br />
              Personal information is information about you that is personally
              identifiable like your name, address, email address, phone number,
              gender, age, billing or credit card information and that is not
              otherwise publicly available. The term information shall include
              personal information.
              <br />
              <br />
              When you use the Site, we may also collect and store additional
              information which may or may not be personally identifying to you
              or identifiable with other information collected. Such information
              includes, without limitation, your internet protocol address,
              browser types, domain name, type of operating system and other
              statistical data involving your use of the Site and/or our
              services. This information is collected passively using various
              technologies or may be provided by you through communications with
              us. We also collect information through surveys, challenges and
              competitions in which you participate. We also collect information
              regarding your use of the Site, e.g., how you engage with the
              Site, including how you interact with content we show to you, the
              ads you view, videos you watch and problems encountered, the
              content you like and the users you follow. We also infer your
              preferences, including your interests, gender and age for the
              purpose of personalising content. We process information about
              your followers, the likes you receive and responses to content you
              upload, for the purposes of promoting your content to other users
              and exploring whether your profile presents further opportunities
              for collaboration. Where appropriate, we will also use this
              information for the purpose of serving personalised advertising
              and to tell you about new services and opportunities.
              <br />
              <br />
              Please note that if your profile is public, your content will be
              visible to anyone on the Site and may also be accessed or shared
              by your friends and followers as well as third parties such as
              search engines, content aggregators and news sites.
              <br />
              <br />
              If you do not wish to provide such information, you must stop
              accessing or using the Site. EYSE may in its sole discretion deny
              any person access to the Site.
            </p>
          </div>
          <div>
            <h2>Cookies</h2>
            <p>
              When you use the Site, we may store some information on your
              mobile phone or other device used to access the Site. This
              information will be in the form of a “cookie” or similar file.
              Cookies are small pieces of information that gets stored on your
              computer or device when you access the Site. We use cookies to
              track your usage, trends on the usage, and to improve the Site and
              analyse and assess changes to, successes and failures of the Site.
              These cookies remember information about your usage of the Site
              and help you navigate the Site. We do not use cookies to spy on
              you or otherwise invade your privacy. By using the Site, you
              consent to our use of cookies.
              <br />
              <br />
              We link your contact or subscriber information with your activity
              on our Site across all your devices, using your email or other
              log-in or device information. These third parties may use this
              information to display advertisements on our Site and elsewhere
              online tailored to your interests, preferences, and
              characteristics. We are not responsible for the privacy practices
              of these third parties, and the information practices of these
              third parties are not covered by this Privacy Policy.
            </p>
          </div>
          <div>
            <h2>Other Monitoring Programs</h2>
            <p>
              For security purposes and to ensure the availability of the Site,
              we also use software programs and tools to monitor network
              traffic, to identify unauthorised attempts to upload or change
              information, or otherwise cause damage to the Site.
            </p>
          </div>
          <div>
            <h2>Customer Service</h2>
            <p>
              We may also collect information when you interact with our
              customer service in order to assist you and to improve the
              operation of the Site.
            </p>
          </div>
          <div>
            <h2>How we use your information</h2>
            <p>
              The information that we collect is used to contact you, to
              administer your accounts, for research, and to improve the quality
              and your experience of the Site and develop our present and future
              products and services and provide information of these products
              and services to you. We will not sell, share or disclose your
              information with any third party without your consent, except as
              provided for in this policy. We may disclose your information to
              service providers who you may be require such information for the
              provisions of services selected by you.
              <br />
              <br />
              We reserve the right to send you certain communications relating
              to the Site, such as service announcements, administrative
              messages and information regarding existing and new services,
              without offering you the opportunity to opt out of receiving them.
              <br />
              <br />
              You may also provide us with access to information which is
              already publicly available, or becomes publicly available to us in
              the future. Further, we may also access information about you from
              third parties independent of your use of the Site. You understand
              and acknowledge that any such information we may collect cannot be
              accorded the protections of this policy.
              <br />
              <br />
              This policy does not apply to the practices of other entities that
              we do not own or control, or to people that we do not employ or
              manage.
            </p>
          </div>
          <div>
            <h2>Storage and Destruction</h2>
            <p>
              We shall store all information in accordance with standard market
              practices relating to storage of information and this may involve
              storage in servers and databases owned and maintained by us and/or
              our affiliates, agents or service providers. We may also retain
              the information once the purpose for which it is collected has
              been completed and the same may be retained and/or destroyed in
              accordance with standard market practices applicable to similar
              businesses.
            </p>
          </div>
          <div>
            <h2>Access and Protection of your Information</h2>
            <p>
              Your information that is provided to us or collected by us shall
              be protected in accordance with standard market practices
              applicable to similar businesses in India.
              <br />
              <br />
              You have a right to access, modify or correct any information
              provided to us by the use of the Site. If you update, modify or
              correct your information, we may continue to keep copies of your
              information prior to such update, modification or correction for
              uses provided for in this policy. You can request internal
              deletion of your prior information at any time by requesting us
              with specific details of the information that you wish to delete,
              and we shall endeavour to respond to your request in a timely
              manner.
            </p>
          </div>
          <div>
            <h2>Disclosure of Information</h2>
            <p>
              We share your data with third party service providers who help us
              to deliver the Site, such as cloud storage providers. We also
              share your information with business partners, other companies in
              the same group as EYSE, content moderation services, measurement
              providers, advertisers, and analytics providers. Where and when
              required by law, we will share your information with law
              enforcement agencies or regulators, and with third parties
              pursuant to a legally binding court order.
              <br />
              <br />
              We shall not rent, sell, share or disclose your information with
              non-affiliated companies or persons, except as provided in this
              policy or the applicable terms of use of the Site, or to provide
              you with the requested products and services on the Site, or when
              we have your consent, or under the following circumstances:
            </p>
            <ul>
              <li>
                We provide the information to our trusted partners who work on
                our behalf or with third parties under confidentiality
                agreements. These partners may use your information to help us
                communicate with you about our Site and our other products and
                services. However, these partners do not have any independent
                right to share this information;
                <br />
                We provide the information to our advisors and consultants for
                billing, business, legal and taxation purposes;
              </li>
              <li>
                We may disclose the information pursuant to law, court orders,
                requests or orders of Governmental authorities, or to exercise
                our legal rights or defend against legal claims;
              </li>
              <li>
                We believe it is necessary to share information in order to
                investigate, prevent, or take action regarding illegal
                activities, suspected fraud, situations involving potential
                threats to the safety of any person, violations of the
                applicable terms of use of the Site, or as otherwise required by
                law;
              </li>
              <li>
                We believe it is necessary to disclose the information to
                enforce the applicable terms of use of the Site and/or
                reasonably necessary to protect our rights and property or our
                officers, directors, shareholders, employees or agents;
              </li>
              <li>
                We share your information with third parties who we may be in
                discussions with for partnerships, associations, investments in
                or financing of our projects;
              </li>
              <li>
                We transfer information about you if we are acquired by or
                merged with another company or transfer a part of our business
                to a third party. Any third party or resultant entity that
                receives your information pursuant to a merger, demerger or
                business transfer shall have the right to continue to use your
                information. In this event, we will notify you before
                information about you is transferred and becomes subject to a
                different privacy policy.
              </li>
              <li>
                We may publish any testimonials that you have provided about the
                Site and such testimonials and other marketing, promotions and
                advertisements may include your information that you have
                provided to us.
              </li>
              <li>
                We will, from time to time, include links to and from the
                websites of our partner networks, advertisers and affiliates. If
                you follow a link to any of these websites, please note that
                these websites have their own privacy policies and that we do
                not accept any responsibility or liability for these policies.
                Please check these policies before you submit any information to
                these websites.
              </li>
            </ul>
          </div>
          <div>
            <h2>Anonymised Data</h2>
            <p>
              We may also use your information collected to create aggregate
              anonymised data. We shall ensure that such anonymised data will
              not be directly identifiable to you or to the information shared
              with us. By using this Site, you provide us the right to use your
              information to create anonymised data and use it for our business
              purposes.
            </p>
          </div>
          <div>
            <h2>Changes to this Privacy Policy</h2>
            <p>
              Amendments to this policy will be posted to this URL and will be
              effective when posted. You shall be updated of changes to the same
              at least once a year. Your continued use of this Site following
              the posting of any amendment, modification, or change shall
              constitute your acceptance of the amendment. If you do not agree
              to the updated policy, you must stop accessing or using the Site.
            </p>
          </div>
          <div>
            <h2>Complaints, Questions and Suggestions</h2>
            <p>
              In the event that you wish to make a complaint about how we
              process your personal data, please contact our Grievance Officer
              Arun Lodhi in the first instance at mailto: hello@eyseapp.com and
              we will endeavour to deal with your request as soon as possible.
              This is without prejudice to your right to launch a claim with the
              relevant data protection authority.
              <br />
              <br />
              If you have questions, suggestions, or require to contact us as
              per this policy, please contact us at
              mailto:gourav@featureventures.com
            </p>
          </div>
        </div>
        <p>
          <br />
          <br />
          Last updated: 22 November 2022
        </p>
      </div>
    );
}

export default PrivacyPolicy;