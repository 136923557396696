import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { AppSearchQuery, getProductDetails, registerRetailer, saveOrder } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import SubmitPopover3 from "../../components/SubmitPopover/SubmitPopover3";
import styles1 from "../Home/index.module.css"

const IndependentHeader = () => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div className={styles1.sectionReviewHeader}>
        <header className={styles1.header}>
          <Link to="/">
          <img
            className={styles1.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
            </Link>
            {height>width&&<div className={styles1.menu}>
            <img
              className={styles1.burgerMenu}
              src={isMenuOpen ? Cross : BurgerMenu}
              alt="burger-menu"
              style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
              onClick={() => setIsMenuOpen((prevState) => !prevState)}
            />
            <div
              className={styles1.headerBtns}
              style={{
                transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
              }}
            >
              <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/askquery`} className={styles1.btn}>
            Ask a Query
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/answerquery`} className={styles1.btn}>
            Answer Query
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/addreview`} className={styles1.btn}>
            Add Review
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/viewreviews`} className={styles1.btn}>
            View Reviews
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/validatedreviews`} className={styles1.btn}>
                Tagged Reviews
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/viewstores/true`} className={styles1.btn}>
                View Stores
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/storelocator`} className={styles1.btn}>
                Retail Stores
          </Link>
                </div>
                {/* <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/revalidatedinfluencerreviews/true/${influencerId}/${influencerId}`} className={styles1.btn}>
                Tagged Reviews
          </Link>
                </div> */}
            </div>
          </div>}
          {height<=width&&<div className={styles1.menu}>
            <img
              className={styles1.burgerMenu}
              src={Cross}
              alt="burger-menu"
              style={{ transform:"rotate(45deg)" }}
              // onClick={() => setIsMenuOpen((prevState) => !prevState)}
            />
            <div
              className={styles1.headerBtns}
              style={{
                transform: "scale(1,1)",
              }}
            >
              <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/askquery`} className={styles1.btn}>
            Ask a Query
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/answerquery`} className={styles1.btn}>
            Answer Query
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/addreview`} className={styles1.btn}>
            Add Post
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/viewreviews`} className={styles1.btn}>
            View Posts
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/validatedreviews`} className={styles1.btn}>
                Tagged Posts
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/viewstores/true`} className={styles1.btn}>
                View Stores
          </Link>
                </div>
                <div className={styles1.addProduct}>
                <div></div>
                <Link to={`/storelocator`} className={styles1.btn}>
                Retail Stores
          </Link>
                </div>
            </div>
          </div>}
        </header>
      </div>

    // <div>
    // <div className={styles1.joinOptns}>
    //       <Link to={`/influenceraskquery/${influencerId?influencerId:""}`} className={styles1.btn}>
    //         Ask a Query
    //       </Link>
    //       <Link to={`/influenceranswerquery/${influencerId?influencerId:""}`} className={styles1.btn}>
    //         Answer Query
    //       </Link>
    //       <Link to={`/addinfluencerreview/${influencerId?influencerId:""}`} className={styles1.btn}>
    //         Add Review
    //       </Link>
          
    //     </div>
    //     <div className={styles1.joinOptns}>
    //       <Link to={`/viewinfluencerreviews/${influencerId}/${influencerDetails.email}`} className={styles1.btn}>
    //         View Services
    //       </Link>
    //       <Link to={`/validatedinfluencerreviews/${influencerId}/${influencerDetails.email}`} className={styles1.btn}>
    //         Service Reviews
    //       </Link>
    //       <Link to={`/revalidatedinfluencerreviews/true/${influencerId}/${influencerDetails.email}`} className={styles1.btn}>
    //         Tagged Reviews
    //       </Link>
    //     </div>
    // </div>
  );
}

export default IndependentHeader;