import React from "react";
import styles from "./index.module.css";

const TermsAndConditions = () => {
    return (
      <div className={styles.main}>
        <h1>Terms and Conditions</h1>
        <p>
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures. This document is published
          in accordance with the provisions of Rule 3 (1) of the Information
          Technology (Intermediaries guidelines) Rules, 2011 that require
          publishing the rules and regulations, privacy policy and Terms of Use
          for access or usage of Feature Ventures, Dealer
        </p>
        <br />
        <h3>1. Registration, User Information, Privacy and Profile Pages.</h3>
        <p>
          A. Registration. In order to access or use some (or potentially all)
          of the features on the Platform, you must first either register
          through our online registration process at www.eyseapp.com or, where
          the option is available, log into your online EYSE / DEALER app
          account using qualifying third-party service log-in credentials. If
          you register for a Platform account, then you will select your own
          password at the time of registration (or we may send you an e-mail
          notification with a randomly generated initial password). You agree
          that: (i) You will not use a username (or e-mail address) that is
          already being used by someone else, may impersonate another person,
          belongs to another person, violates the intellectual property or other
          right of any person or entity, or is offensive; we may reject the use
          of any password, username, or e-mail address for any other reason in
          our sole discretion; (ii) You will provide true, accurate, current,
          and complete information about yourself and your service(s) and
          product(s) in connection with the registration process and your use of
          the Platform and will maintain and update it, including on your
          Profile Page (defined below), continuously and promptly to keep it
          accurate, current, and complete; (iii) You are solely responsible for
          all activities that occur under your account, password, and username –
          whether or not you authorized the activity; (iv) You are solely
          responsible for maintaining the confidentiality of your password and
          for restricting access to your Device so that others may not access
          any password protected portion of the Platform using your name,
          username, e-mail address, or password; (v) You will immediately notify
          us of any unauthorized use of your account, password, or username, or
          any other breach of security; and (vi) You will not sell, transfer, or
          assign your account or any account rights
        </p>
        <br />
        <p>
          B. User Information. You acknowledge and agree that you are solely
          responsible for the accuracy and content of all information you
          provide (including personal information), and you agree to keep it up
          to date. If any information that you provide, or if we have reasonable
          grounds to suspect that any information that you provide, is false,
          inaccurate, outdated, incomplete, or violates these Terms, any
          Additional Terms, or any applicable law, then we may suspend or
          terminate your account or your access to or use of the Platform
          without advance notice or liability. We also reserve the more general
          and broad right to terminate your account or suspend or otherwise deny
          you access to it or its benefits, all in our sole discretion, for any
          reason (including without limitation upon your breach of these Terms),
          and without advance notice or liability. We will not be liable for any
          loss or damage (of any kind and under any legal theory) to you or any
          third party arising from your inability or failure for any reason to
          comply with these Terms.
        </p>
        <br />
        <p>
          C. Data & Privacy. In the course of using the Platform (including
          services or functionalities made available on or through the
          Platform), you may provide certain personal information to us and we
          may otherwise collect information about you or as a result of your use
          of the Platform. Feature Ventures policies for collection, use, and
          disclosure of such information are set forth in our Privacy Policy for
          the Platform (“Privacy Policy”), located at
          https://featureventures.com/privacy-policy/. Our Privacy Policy is
          incorporated herein by this reference. You agree that we may collect
          data related to an end user’s use of the Platform, including
          product(s). Our data collection, use and disclosure practices are
          described more fully in our Privacy Policy, including the use of log
          information and cookies and other tracking technologies that record
          device or other persistent identifiers and usage information about end
          users. When an end user visits a page on the Platform, we may deploy
          tracking technologies on our own behalf or on behalf of one of our
          data partners to record information about how an end user uses the
          web, such as the web search that landed the end user on a particular
          page, categories of the end user’s interests and third-party site
          visitations by the user’s device. We may use that data to target
          actions and advertising and promotional material toward the end user
          or Retailer or authorize others to do the same. Any and all data
          collected by Feature Ventures as a result of your use of or access to
          the Platform (including, without limitation, data concerning the sale
          of products and related transactional and marketplace data), but
          excluding any data consisting of User-Generated Content (as defined
          below) and any personal information, is, to the extent permitted by
          applicable law, owned exclusively by Feature Ventures. Additionally,
          any and all anonymized and aggregated data created based on or using
          User-Generated Content or personal information is owned exclusively by
          Feature Ventures. You acknowledge and understand that Feature Ventures
          may, subject to applicable law and without compensation to you, use
          Feature Ventures-owned data in any manner for its business purposes.
        </p>
        <br />
        <p>
          D. Profiles. After completing the registration process, you may then
          be able to create a profile page to showcase and provide information
          about your product(s) or service(s) and your company information (the
          “Profile Page”). Your Profile Page must not include any form of
          prohibited User-Generated Content, as outlined in Section 3 and in our
          Rules (Section 5). We may offer you the ability to set preferences
          relating to your profile or Platform activities, but settings may not
          become effective immediately or be error free, and options may change
          from time to time. We assume no responsibility or liability for users’
          Profile Page material.
        </p>
        <p>
          Profile Pages may only be set up by an authorized representative of
          the individual that is the subject of the Profile Page. We do not
          review Profile Pages to determine if they were created by an
          appropriate party, and we are not responsible for any unauthorized
          Profile Pages that may appear on the Platform. If there is any dispute
          as to whether a Profile Page has been created or is being maintained
          by an authorized representative of the individual who is the subject
          of that Profile Page, then we shall have the sole right, but are not
          obligated, to resolve such dispute as we determine is appropriate in
          our sole discretion. Such resolution may include, without limitation,
          deleting or disabling access to Profile Pages, or any portion thereof,
          at any time without notice. You are responsible for all use of your
          Profile Pages, or other actions, completed using your account,
          including users that you have invited to collaborate on your account.
        </p>
        <br /> <br />
        <h3>
          2. Platform Content, Ownership, Limited License, and Rights of Others.
        </h3>
        <p>
          A. Content. The Platform contains a variety of: (i) materials and
          other items relating to Feature Ventures and its products and
          services, and similar items from our licensors and other third
          parties, including all layout, information, articles, posts, text,
          data, files, images, scripts, designs, graphics, button icons,
          instructions, illustrations, photographs, audio clips, music, sounds,
          pictures, videos, advertising copy, URLs, technology, software,
          interactive features, the “look and feel” of the Platform, and the
          compilation, assembly, and arrangement of the materials of the
          Platform and any and all copyrightable material (including source and
          object code); (ii) trademarks, logos, trade names, trade dress,
          service marks, and trade identities of various parties, including
          those of Feature Ventures (collectively, “Trademarks”); and (iii)
          other forms of intellectual property (all of the foregoing,
          collectively, “Content”).
        </p>
        <br />
        <p>
          B. Ownership. The Platform (including past, present, and future
          versions), including all Content, are owned by Feature Ventures and,
          as applicable, our licensors and certain other third parties. All
          right, title, and interest in and to the Content is the property of
          Feature Ventures or our licensors or certain other third parties, and
          is protected by India and international copyright, trademark, trade
          dress, patent and/or other intellectual property and unfair
          competition rights and laws to the fullest extent possible. Without
          limiting the foregoing, Feature Ventures owns the copyright in the
          selection, compilation, assembly, arrangement, and enhancement of the
          Content on the Platform.
        </p>
        <br />
        <p>
          C. Limited License. Subject to your strict compliance with these Terms
          and the Additional Terms, Feature Ventures grants you a limited,
          non-exclusive, revocable, non-assignable, non-sublicensable personal,
          and non-transferable license to download (temporary storage only),
          display, view, use, play, and/or print the visible Content (excluding,
          for the avoidance of doubt, all source and object code, in raw form or
          otherwise, other than as made available to access and use to enable
          display and functionality) on a personal computer, mobile phone or
          other wireless device, or other Internet enabled device (each, a
          “Device”) for your personal, non-commercial use only and, in any
          event, in accordance with all related documentation or restrictions
          posted on the Platform or otherwise provided by Feature Ventures. The
          foregoing limited license (i) does not give you any ownership of, or
          any other intellectual property right or interest in, any Content, and
          (ii) may be immediately suspended or terminated for any reason, in
          Feature Ventures’s sole discretion, without advance notice or
          liability. In some instances, we may permit you to have greater access
          to and use of Content, subject to certain Additional Terms. Any
          provisions of these Terms that are intended to survive termination
          (including any provisions regarding indemnification, limitation of our
          liability, or dispute resolution) will continue in effect beyond any
          termination of these Terms or of your access to or use of the
          Platform.
        </p>
        <br />
        <p>
          D. Rights of Others. In using the Platform, you must respect the
          intellectual property and other rights of Feature Ventures and others.
          Your unauthorized use of Content may violate copyright, trademark,
          privacy, publicity, communications, and other laws, and any such use
          may result in your personal liability, including potential criminal
          liability. Any unauthorized use of any Content is prohibited and may
          be prosecuted to the fullest extent of the law. Feature Ventures
          respects the intellectual property rights of others.
        </p>
        <br />
        <br />
        <h3>3. Content You Provide and Community Usage Rules.</h3>
        <p>
          A. Trademark License. In order to use the Platform, you hereby grant
          to Feature Ventures and you agree to grant to Feature Ventures, and
          represent and warrant that you have the right to grant to Feature
          Ventures, a worldwide, irrevocable, perpetual, royalty-free, fully
          paid-up, cost-free, non-exclusive, limited right and license to use,
          reproduce, and display, as applicable, the names, logos, marks,
          nicknames, slogans, artwork, trademarks, trade names, service marks
          that at any time were or are owned, applied to be registered,
          registered, controlled, or cleared for use by or on behalf of, or
          licensed by, you in connection with the Platform (collectively, the
          “User Marks”) and any User-Generated Content provided by you, such as,
          without limitation, any photographs and audiovisual content featuring
          your company and/or your products with your User Marks, in connection
          with the Platform and Feature Ventures’s provision of the Platform and
          related products and services (including, without limitation, Feature
          Ventures’s disclosure, sale, license, and/or other distribution to
          third parties of data and information concerning the Platform or its
          use, such as data reflecting product sales transacted on the
          Platform).
        </p>
        <br />
        <p>
          B. User-Generated Content. Feature Ventures may now or in the future
          offer users of the Platform the opportunity to create, build, post,
          upload, display, publish, distribute, transmit, broadcast, or
          otherwise make available on or submit or provide through the Platform
          (collectively, “provide”) messages, avatars, text, illustrations,
          files, images, graphics, photos, comments, responses, videos,
          information, content, ratings, reviews, data, questions, suggestions,
          or other information or materials and the ideas contained therein
          (collectively, but excluding Content included therein, “User-Generated
          Content”). Feature Ventures may allow you to do this through your
          Profile Page, forums, blogs, message boards, social networking
          environments, content creation tools, social communities, contact us
          tools, e-mail, and other communications functionality. Subject to the
          rights and license you grant in these Terms, you retain whatever
          legally cognizable right, title, and interest that you have in
          User-Generated Content and you remain ultimately responsible for all
          User-Generated Content you provide.
        </p>
        <br />
        <p>C. Non-Confidentiality of Your User-Generated Content.</p>
        <p>
          (i) Except as otherwise described in the Platform’s posted Privacy
          Policy or any Additional Terms, you agree that Feature Ventures does
          not assume any obligation of any kind to you or any third party with
          respect to User-Generated Content. Upon Feature Ventures’s request,
          you will furnish us with any documentation necessary to substantiate
          the rights to such content and to verify your compliance with these
          Terms or any Additional Terms. You acknowledge that the Internet and
          mobile communications may be subject to breaches of security and that
          you are aware that providing User-Generated Content may not be secure,
          and you will consider this before providing any User-Generated
          Content. You provide all User-Generated Content at your own risk.
        </p>
        <br />
        <p>
          (ii) In your communications with Feature Ventures, please keep in mind
          that we do not seek any unsolicited ideas or materials for products or
          services, or even suggested improvements to products or services,
          including, without limitation, ideas, concepts, inventions, or designs
          (collectively, “Unsolicited Ideas and Materials”). Any Unsolicited
          Ideas and Materials you post on, provide through, or send to us via
          the Platform are deemed User-Generated Content and licensed to us as
          set forth below. In addition, Feature Ventures retains all of the
          rights held by members of the general public with regard to your
          Unsolicited Ideas and Materials. Feature Ventures’s receipt of your
          Unsolicited Ideas and Materials is not an admission by Feature
          Ventures of their novelty, priority, or originality, and it does not
          impair Feature Ventures’s right to contest existing or future
          intellectual property rights relating to any Unsolicited Ideas and
          Materials.
        </p>
        <br />
        <p>
          <p>D. License to Feature Ventures of User-Generated Content.</p> You
          hereby grant to Feature Ventures, and you agree to grant to Feature
          Ventures, a non-exclusive, unrestricted, unconditional, unlimited,
          worldwide, irrevocable, perpetual, royalty-free, fully paid-up, and
          cost-free right and license to use, copy, record, distribute,
          reproduce, disclose, sell, re-sell, sublicense (through multiple
          levels), display, publicly perform, transmit, publish, broadcast,
          translate, make derivative works of, and otherwise use and exploit in
          any manner whatsoever, all or any portion of User-Generated Content
          you provide (and derivative works thereof), for any purpose whatsoever
          in all formats, on or through any means or medium now known or
          hereafter developed, and with any technology or devices now known or
          hereafter developed, and to advertise, market, and promote the same.
          Without limitation, the granted rights include the right to: (a)
          configure, host, index, cache, archive, store, digitize, compress,
          optimize, modify, reformat, edit, adapt, publish in searchable format,
          and remove such User-Generated Content and combine same with other
          materials, and (b) use any ideas, concepts, know-how, or techniques
          contained in any User-Generated Content for any purposes whatsoever,
          including developing, producing, marketing, and selling products
          and/or services. You understand that in exercising such rights
          metadata, notices and content may be removed or altered, including
          copyright management information, and you consent thereto and
          represent and warrant you have all necessary authority to do so. In
          order to further effect the rights and license that you grant to
          Feature Ventures to User-Generated Content, you also hereby grant to
          Feature Ventures, and agree to grant to Feature Ventures, the
          unconditional, perpetual, irrevocable right to use and exploit your
          name, persona, and likeness in connection with any User-Generated
          Content, without any obligation or remuneration to you. Except as
          prohibited by law, you hereby waive, and you agree to waive, any moral
          rights (including attribution and integrity) that you may have in any
          User-Generated Content, even if it is altered or changed in a manner
          not agreeable to you. To the extent not waivable, you irrevocably
          agree not to exercise such rights (if any) in a manner that interferes
          with any exercise of the granted rights. You understand that you will
          not receive any fees, sums, consideration, or remuneration for any of
          the rights granted in this Section 3.D.
        </p>
        <br />
        <p>
          E. Exclusive Right to Manage Our Platform. Feature Ventures may, but
          will not have any obligation to, review, monitor, display, post,
          store, maintain, accept, or otherwise make use of, any User-Generated
          Content you provide, and Feature Ventures may, in its sole discretion,
          reject, delete, move, re-format, remove or refuse to post or otherwise
          make use of User-Generated Content without notice or any liability to
          you or any third party in connection with our operation of
          User-Generated Content venues in an appropriate manner. Without
          limitation, we may do so to address content that comes to our
          attention that we believe is offensive, obscene, lewd, lascivious,
          filthy, violent, harassing, threatening, abusive, illegal or otherwise
          objectionable or inappropriate, or to enforce the rights of third
          parties or these Terms or any applicable Additional Terms, including,
          without limitation, the content restrictions set forth below in the
          Rules (defined in Section 5). Such User-Generated Content provided by
          you or others need not be maintained on the Platform by us for any
          period of time and you will not have the right, once provided, to
          access, archive, maintain, or otherwise use such User-Generated
          Content on the Platform or elsewhere.
        </p>
        <br />
        <p>
          F. Enforcement. Feature Ventures has no obligation to monitor or
          enforce your intellectual property rights to User-Generated Content
          you provide, but you grant us the right to protect and enforce our
          rights to User-Generated Content, including by bringing and
          controlling actions in your name and on your behalf (at Feature
          Ventures’s cost and expense, to which you hereby consent and
          irrevocably appoint Feature Ventures as your attorney-in-fact, with
          the power of substitution and delegation, which appointment is coupled
          with an interest).
        </p>
        <br />
        <p>
          G. Feature Ventures CANNOT AND DOES NOT ASSUME ANY LIABILITY FOR
          USER-GENERATED CONTENT, INCLUDING, BUT NOT LIMITED TO, INCOMPLETENESS,
          INACCURACIES, OR INFRINGING MATERIALS, AND, AS BETWEEN YOU AND Feature
          Ventures, YOU HEREBY ASSUME ALL RISK OF HARM OR INJURY RESULTING FROM
          ANY USE OR RELIANCE BY ANY PERSON OR ENTITY ON SUCH USER-GENERATED
          CONTENT.
        </p>
        <br />
        <br />
        <h3>
          4. Representations and Warranties Related to User-Generated Content
          and Products.
        </h3>
        <p>
          A. Each time you provide any User-Generated Content, you represent and
          warrant that (a) you are at least the age of 18 years, (b) you are the
          sole author and owner of the intellectual property and other rights to
          the User-Generated Content, or you have a lawful right to provide the
          User-Generated Content and grant Feature Ventures the rights to it
          that you are granting by these Terms and any Additional Terms, all
          without any Feature Ventures obligation to obtain consent of any third
          party and without creating any obligation or liability of Feature
          Ventures; (c) the User-Generated Content is accurate; (d) the
          User-Generated Content does not and, as to Feature Ventures’s
          permitted uses and exploitation set forth in these Terms, will not
          infringe any intellectual property or other right of any third party;
          and (e) the User-Generated Content will not violate these Terms
          (including the Rules) or any Additional Terms, or cause injury or harm
          to any person.
        </p>
        <br />
        <p>
          B. If you post a product and/or company information, you represent and
          warrant that:
        </p>
        <p>
          (i) User-Generated Content you provide about such product(s) and
          company information is truthful and not misleading, deceptive or
          inaccurate in any way, including in relation to the availability,
          nature, terms or conditions or any other matter relating to the
          product being offered, and is kept up to date by you;
        </p>
        <p>
          (ii) You agree (a) to provide the Retailer with such other information
          as is reasonably necessary to ensure that the Retailer is fully
          informed as to the intended and proper use of the product, including
          any lists of ingredients, components or materials and any applicable
          instruction booklets and/or warning labels concerning the product, and
          (b) to use commercially reasonable efforts to respond to additional
          Retailer product inquiries;
        </p>
        <p>
          (iii) List prices for Products on the Platform are and will be
          accurate and consistent with prices for those products listed on or
          made available through other channels;
        </p>
        <p>
          (iv) You will delist Products for sale on the Platform and communicate
          the following details to Feature Ventures if any Products for sale on
          the Platform have been recalled: brand and product name, recall
          reason, and any other relevant details;
        </p>
        <p>
          (v) Your product has been produced, manufactured, packaged and
          supplied strictly in accordance with the relevant consumer protection
          and product safety regulations and good manufacturing practices and
          that it complies, and you comply, with all applicable laws and codes
          of conduct;
        </p>
        <p>
          (vi) User-Generated Content you provide does not contain links to any
          external website other than specified in the website field in the
          applicable sign-up page, unless we have given our prior written
          consent;
        </p>
        <p>
          (vii) User-Generated Content you provide is posted within the
          appropriate category(ies). If we consider that you have posted a
          product to an incorrect or inappropriate category, we may re-classify
          and re-post that post as we consider appropriate; and
        </p>
        <p>
          (viii) Your product(s) has been produced, designed, manufactured,
          packaged, and supplied in a way that does not infringe any
          intellectual property rights of any third party.
        </p>
        <br />
        <br />
        <h3>5. Community Usage Rules.</h3>
        <p>
          As a user of the Platform, these Community Usage Rules (“Rules”) are
          here to help you understand the conduct that is expected of members of
          the Platform’s online communities (“Communities”).
        </p>
        <br />
        <p>
          A. Nature of Rules. Your participation in the Communities is subject
          to all of the Terms, including these Rules:
        </p>
        <br />
        <p>
          User-Generated Content. All User-Generated Content you provide either
          must be original with you or you must have all necessary rights in it
          from third parties in order to permit you to comply with these Terms
          and any Additional Terms. User-Generated Content must not contain any
          visible logos, phrases, or trademarks that belong to third parties. Do
          not use any User-Generated Content that belongs to other people and
          pass it off as your own; this includes any content that you might have
          found elsewhere on the Internet. If anyone contributes to or has any
          rights to User-Generated Content you provide, or if anyone appears or
          is referred to in the User-Generated Content, then you must also have
          their permission to provide such User-Generated Content to Feature
          Ventures. (For example, if someone has taken a picture of your friend
          and your product, and you provide that photo to Feature Ventures as
          User-Generated Content, then you must obtain your friend’s and the
          photographer’s permission to do so.)
        </p>
        <p>
          Speaking of Photos: No Pictures, Videos, or Images of Anyone Other
          Than You and Your Products. If you choose to provide photos to the
          Platform, link to embedded videos, or include other images of real
          people, then make sure they are of you or of you and someone you know
          – and only if you have their express permission to provide it.
        </p>
        <p>
          Act Appropriately. All of your Platform activities must be venue
          appropriate, as determined by us. Be respectful of others’ opinions
          and comments so we can continue to build Communities for everyone to
          enjoy. If you think User-Generated Content you provide might offend
          someone or be embarrassing to someone, then chances are it probably
          will and it doesn’t belong on the Platform. User-Generated Content
          must not be defamatory, slanderous, indecent, obscene, pornographic,
          or sexually explicit.
        </p>
        <p>
          Be Original. Don’t copy the Platform or use the Platform, or any
          element thereof, to create (or allow third parties to create) any
          software product capable of emulating or competing with the Platform
          (or facilitating the emulation of or competition with the Platform),
          or use any of the Platform’s components, files, modules, audio-visual
          content, or related licensed materials separately from the Platform.
        </p>
        <p>
          Do Not Use for Inappropriate Purposes. User-Generated Content must not
          promote any infringing, illegal, or other similarly inappropriate
          activity.
        </p>
        <p>
          Be Honest and Do Not Misrepresent Yourself, Your Products or
          User-Generated Content. Do not impersonate any other person, user, or
          company, and do not provide User-Generated Content that you believe
          may be false, fraudulent, deceptive, inaccurate, or misleading, or
          that misrepresents your products or identity or affiliation with a
          person or company.
        </p>
        <p>
          Others Can See. We hope that you will use the Communities to exchange
          information and content and have venue appropriate discussions with
          Retailers and Suppliers. However, please remember that the semi-public
          and User-Generated Content that you provide on the Platform within a
          Community may be accessible and viewable by other Platform users,
          including other Retailers. Do not provide personally identifying
          information (e.g., first and last name together, password, phone
          number, address, credit card number, medical information, e-mail
          address, or other personally identifiable information or contact
          information) on Community spaces and take care when disclosing this
          type of information to others.
        </p>
        <p>
          Don’t Share Other Peoples’ Personal Information. User-Generated
          Content must not reveal another person’s address, phone number, e-mail
          address, social security number, credit card number, medical
          information, financial information, or any other information that may
          be used to identify, track, contact, or impersonate that individual,
          unless, and in the form and by the method, specifically requested by
          Feature Ventures.
        </p>
        <p>
          Don’t Damage the Platform or Anyone’s Computers or Other Devices.
          User-Generated Content must not provide or include viruses, Trojan
          horses, spyware, or any other technologies or malicious code that
          could impact the operation of the Platform or any computer or other
          Device.
        </p>
        <p>
          If you provide User-Generated Content that Feature Ventures reasonably
          believes violates these Rules, then we may take any legally available
          action that we deem appropriate, in our sole discretion. However, we
          are not obligated to take any action not required by law. We may
          require, at any time, proof of the permissions referred to above in a
          form acceptable to us. Failure to provide such proof may lead to,
          among other things, the User-Generated Content in question being
          removed from the Platform.
        </p>
        <br />
        <p>
          B. Additional Restrictions / Non-Solicit. In addition to the
          restrictions on use set forth in Sections 3, 5, and 6, use of the
          Platform by a competitor of Feature Ventures is strictly prohibited
          and you represent, warrant, and covenant that you and your agents or
          contractors (including, without limitation, outsourcers) are not, and
          will not become, competitors of Feature Ventures. You further
          represent, warrant, and covenant that, as a Retailer or buyer user of
          the Platform, you will not, for so long as these Terms are in effect
          and for the six (6)-month period after any termination of these Terms,
          directly or indirectly offer, sell, or seek to offer or sell any
          products or services to any Suppliers, nor solicit the business or any
          Supplier. The license granted at Section 2.C. will automatically
          terminate upon a breach of this Section 5.B.
        </p>
        <br />
        <p>
          C. Your Interactions With Other Users; Disputes. You are solely
          responsible for your interaction with and agreements with other users
          of the Platform, whether online or offline. We are not responsible or
          liable for the conduct or content of any user, including but not
          limited to those you may invite to collaborate on your Feature
          Ventures account, nor are we responsible for monitoring compliance
          with or enforcing any agreements entered into among Platform users. We
          reserve the right, but have no obligation, to monitor or become
          involved in disputes between you and other users, including any
          Retailers or Suppliers. Exercise common sense and your best judgment
          in your interactions with others (e.g., when you provide any personal
          or other information) and in all of your other online activities.
        </p>
        <br />
        <p>
          D. Submissions. If you agree to use the Platform for submissions
          (Retailer Submissions is a Premium feature that gives Suppliers the
          ability to submit their brand(s) to participating Retailers directly
          from their Feature Ventures profile): (1) you must exclusively route
          inbound submissions to Feature Ventures, and not any other
          destination; (2) you must manage your inbound submissions at least
          once per month; and (3) you must provide your category review names
          and dates to Feature Ventures (via the Platform, or by sending to your
          Account Manager), and make every effort to keep this category review
          data up to date.
        </p>
        <br />
        <p>
          E. Compliance with Laws. You will perform your obligations and
          exercise your rights under these Terms in compliance with all
          applicable laws and regulations (including any applicable export or
          import laws or regulations and any applicable rules regarding online
          conduct).
        </p>
        <br />
        <p>
          F. Alerting Us of Violations. If you discover any content that
          violates these Terms, then you may report it to us by sending an email
          to hello@eyseapp.com. For alleged infringements of intellectual
          property rights, see Sections 9 and 10, below.
        </p>
        <br />
        <p>
          G. Feature Ventures CANNOT AND DOES NOT ASSURE THAT OTHER USERS ARE OR
          WILL BE COMPLYING WITH THE FOREGOING RULES OR ANY OTHER PROVISIONS OF
          THESE TERMS, AND, AS BETWEEN YOU AND Feature Ventures, YOU HEREBY
          ASSUME ALL RISK OF HARM OR INJURY RESULTING FROM ANY SUCH LACK OF
          COMPLIANCE.
        </p>
        <br />
        <br />
        <h3>6. Platform Use Restrictions.</h3>
        <p>
          A. Platform Use Restrictions. You agree that you will not, nor will
          you permit any person to: (i) use any meta tags or any other “hidden
          text” utilizing any Trademarks; (ii) engage in any activities through
          or in connection with the Platform that seek to attempt to or do harm
          any individuals or entities or are unlawful, offensive, obscene, lewd,
          lascivious, filthy, violent, threatening, harassing, or abusive, or
          that violate any right of any third party, or are otherwise
          objectionable to Feature Ventures; (iii) reverse engineer, decompile,
          disassemble, reverse assemble, or modify any Platform source or object
          code or any software or other products, services, or processes
          accessible through any portion of the Platform; (iv) engage in any
          activity that interferes with a user’s access to the Platform or the
          proper operation of the Platform, or otherwise causes harm to the
          Platform, Feature Ventures, or other users of the Platform; (v)
          interfere with or circumvent any security feature of the Platform or
          any feature that restricts or enforces limitations on use of or access
          to the Platform, the Content, or the User-Generated Content; (vi)
          harvest or otherwise collect or store any information (including
          personally identifiable information about other users of the Platform,
          including e-mail addresses, without the express consent of such
          users); (vii) attempt to gain unauthorized access to the Platform,
          other computer systems or networks connected to the Platform, through
          password mining or any other means; or (vii) otherwise violate these
          Terms or any Additional Terms.
        </p>
        <br />
        <p>
          B. Content Use Restrictions. You also agree that, in using the
          Platform: (i) you will not monitor, gather, copy, or distribute the
          Content (except as may be a result of standard search engine activity
          or use of a standard browser) on the Platform by using any robot,
          rover, “bot”, spider, scraper, crawler, spyware, engine, device,
          software, extraction tool, or any other automatic device, utility, or
          manual process of any kind; (ii) you will not frame or utilize framing
          techniques to enclose any such Content (including any images, text, or
          page layout); (iii) you will keep intact all Trademark, copyright, and
          other intellectual property notices contained in such Content; (iv)
          you will not use such Content in a manner that suggests an
          unauthorized association with any of our or our licensors’ products,
          services, or brands; (v) you will not make any modifications to such
          Content; (vi) you will not copy, modify, reproduce, archive, sell,
          lease, rent, exchange, create derivative works from, publish by hard
          copy or electronic means, publicly perform, display, disseminate,
          distribute, broadcast, retransmit, circulate or transfer to any third
          party or on any third-party application or website, or otherwise use
          or exploit such Content in any way for any purpose except as
          specifically permitted by these Terms or any Additional Terms without
          the prior written consent of an officer of Feature Ventures or, in the
          case of Content from a licensor, the owner of the Content; and (vii)
          you will not insert any code or product to manipulate such Content in
          any way that adversely affects any user experience.
        </p>
        <br />
        <p>
          C. Availability of Platform and Content. Feature Ventures may
          immediately modify, suspend or terminate the availability of the
          Platform and Content (and any elements and features of them), in whole
          or in part, for any reason, in Feature Ventures’s sole discretion, and
          without advance notice or liability.
        </p>
        <br />
        <p>
          D. Reservation of All Rights Not Granted as to Content and Platform.
          These Terms and any Additional Terms include only narrow, limited
          grants of rights to Content and to use and access the Platform. No
          right or license may be construed, under any legal theory, by
          implication, estoppel, industry custom, or otherwise. All rights not
          expressly granted to you are reserved by Feature Ventures and its
          licensors and other third parties. Any unauthorized use of any Content
          or the Platform for any purpose is prohibited.
        </p>
        <br />
        <br />
        <h3>7. Subscriptions for Suppliers.</h3>
        <p>
          We offer a free Platform subscription plan (the “Subscription Plan“)
          for Suppliers to create a profile and load products. Other paid plans
          may come in future and suppliers can chose paid plans as per their
          discretions
        </p>
        <br />
        <p>
          Suppliers may purchase the Premium Plan and Promotion Plan online
          using any major credit card (unless other payment terms are agreed
          upon by you and Feature Ventures). The amounts charged to such credit
          card are shown at the time of the transaction. Premium Plans will auto
          renew at the end of each initial and renewal subscription period
          unless they are suspended by the Supplier or Feature Ventures.
          Promotion Plans will terminate at the end of their period unless an
          auto renew is selected.
        </p>
        <br />
        <p>
          Purchases of usage subscriptions of the Platform are nonrefundable,
          have no monetary value (i.e., are not a cash account or equivalent),
          and are purchases of only a limited, non-exclusive, revocable,
          non-assignable, non-sublicensable, personal, and non-transferable
          right to use, even if such came with a durational term (e.g., a
          monthly subscription). We may change our prices at any time without
          notice to you. However, the rights attaching to a Premium Plan or
          Promotion Plan you have purchased do not change if there is a price
          change after your purchase date. Any further purchases will be at the
          revised price.
        </p>
        <br />
        <p>
          If we suspend or terminate any usage subscriptions, then you will
          forfeit the suspended or terminated subscription or items, except as
          may be set forth in any Additional Terms (such as any refund policies
          that may apply to subscription service fees). Likewise, except as may
          be set forth in any Additional Terms or as required by applicable law,
          you will forfeit any credit or refund or any other sum in the event of
          our modification of any usage subscriptions, or for loss or damage due
          to error, or any other reason.
        </p>
        <br />
        <p>
          You are solely responsible for payment of all sales, use, or other
          taxes assessed against or associated with your product(s),
          subscription or any other services performed for us or a Retailer
          under these Terms.
        </p>
        <br />
        <br />
        <h3>8. Notices, Questions and Customer Platform.</h3>
        <p>
          You agree that: (1) we may give you notices of new, revised or changed
          terms and other important matters by prominently posting notice on the
          home page of the Platform, or in another reasonable manner; and (2) we
          may contact you by mail or e-mail sent to the address provided by you.
          You agree to promptly notify us if you change your e-mail or mailing
          address by updating your account settings. All legal notices to us
          must be sent to FEATURE VENTURES LLP,Jhorehat, Andul Station Road,
          Howrah, West Bengal-711106
        </p>
        <br />
        <p>
          If you have a question regarding using the Platform, you may contact
          Feature Ventures Customer Support by sending an e-mail to
          hello@eyseapp.com. You acknowledge that the provision of customer
          support is at Feature Ventures’s sole discretion and that we have no
          obligation to provide you with customer support of any kind. We may
          provide you with customer support from time to time, at our sole
          discretion, provided that you have created an account and that you
          submit your customer support inquiries using such account.
        </p>
        <br />
        <br />
        <h3>9. Product Specifications; Pricing; Typographical Errors.</h3>
        <p>
          We do our best to describe every product or service offered on this
          Platform as accurately as possible. However, we are human, and
          therefore we do not warrant that product specifications, pricing, or
          other content on the Platform is complete, accurate, reliable,
          current, or error-free. In the event of any errors relating to the
          pricing or product specifications, Feature Ventures shall have the
          right to refuse or cancel your subscription and/or access to the
          Platform in our sole discretion. You shall be liable for and indemnify
          Feature Ventures against any and all losses, claims, costs and other
          liabilities whatsoever and howsoever caused and whether or not
          foreseeable or incurred by Feature Ventures as a result of or in
          connection with any breach of this Section.
        </p>
        <br />
        <br />
        <h3>10. Links By You to the Platform.</h3>
        <p>
          We grant you a limited, non-exclusive, revocable, non-assignable,
          non-sublicensable, personal, and non-transferable license to create
          hyperlinks to the Platform, so long as: (a) the links only incorporate
          text, and do not use any Trademarks, (b) the links and the content on
          your website do not suggest any affiliation with Feature Ventures or
          cause any other confusion, and (c) the links and the content on your
          website do not portray Feature Ventures or its products or services in
          a false, misleading, derogatory, or otherwise offensive matter, and do
          not contain content that is unlawful, offensive, obscene, lewd,
          lascivious, filthy, violent, threatening, harassing, or abusive, or
          that violate any right of any third party or are otherwise
          objectionable to Feature Ventures. Feature Ventures reserves the right
          to suspend or prohibit linking to the Platform for any reason, in its
          sole discretion, without advance notice or any liability of any kind
          to you or any third party.
        </p>
        <br />
        <br />
        <h3>11. Third-Party Sites; Dealings with Third Parties.</h3>
        <p>
          A. Third-Party Content and Sites. The Platform may contain third party
          plug-ins and/or applications, and/or links to third-party websites
          that are not owned, controlled or operated by Feature Ventures, and
          the Platform may also include links to third-party ads on the Platform
          or otherwise, to or from third-party websites (collectively,
          “Third-Party Sites”), including websites operated by Retailers,
          licensors, licensees, and certain other third parties who may have
          business relationships with Feature Ventures. Feature Ventures has no
          control over the content, operations, policies, terms, or other
          elements of Third-Party Sites, and Feature Ventures does not assume
          any obligation to review any Third-Party Sites. Feature Ventures does
          not necessarily endorse, approve, or sponsor any Third-Party Sites, or
          any third-party content, information, materials, products, services,
          or other items. Furthermore, Feature Ventures is not responsible for
          the quality or delivery of the services offered, accessed, obtained by
          or advertised at such Third-Party Sites. Finally, Feature Ventures
          will under no circumstances be liable for any direct, indirect,
          incidental or special loss or other damage, whether arising from
          negligence, breach of contract, defamation, infringement of copyright
          or other intellectual property rights, caused by the exhibition,
          distribution or exploitation of any information or content contained
          within any Third-Party Sites. Any activities you engage in connection
          with any of the same are subject to the privacy and other policies,
          terms and conditions of use and/or sale, and rules issued by the
          operator of the Third-Party Sites. Feature Ventures disclaims all
          liability in connection therewith.
        </p>
        <br />
        <p>
          B. Dealings between Suppliers and Retailers. Feature Ventures
          transacts with Suppliers and Retailers of all shapes and sizes and in
          many forms. This is what makes Feature Ventures unique. We do not act
          exclusively for any one Supplier or Retailer and nor are we affiliated
          with any particular Supplier or Retailer. Any interactions,
          correspondence, transactions, and other dealings that you have with
          any other Platform users and other third parties found on or through
          the Platform (including on or via Third-Party Sites or advertisements)
          are solely between you and the third party (including issues related
          to, as applicable, the content of third-party content (including
          category review names and time periods), advertisements, payments,
          delivery of goods, warranties (including product warranties), privacy
          and data security, and the like). Feature Ventures disclaims all
          liability in connection therewith.
        </p>
        <br />
        <br />
        <h3>12. Disclaimer of Warranties and Liability</h3>
        <p>
          This Website, all the Materials and information/content (including but
          not limited to software) and services, included on or otherwise made
          available to You through this website are provided on “as is” and “as
          available” basis without any representation or warranties, express or
          implied except otherwise specified in writing. Without prejudice to
          the forgoing paragraph, Feature Ventures does not warrant that:
        </p>
        <p>
          • This Website will be constantly available, or available at all; or
        </p>
        <p>
          • The information on this Website is complete, true, accurate or
          non-misleading.
        </p>
        <p>
          Feature Ventures will not be liable to You in any way or in relation
          to the Materials of, or use of, or otherwise in connection with, the
          Website. Feature Ventures does not warrant that this site;
          information, content, Materials, product (including software) or
          services included on or otherwise made available to You through the
          Website; their servers; or electronic communication sent from Us are
          free of viruses or other harmful components.
        </p>
        <br />
        <br />
        <h3>13. Indemnity</h3>
        <p>
          You shall indemnify and hold harmless Feature Ventures, its owner,
          licensee, affiliates, subsidiaries, group companies (as applicable)
          and their respective officers, directors, agents, and employees, from
          any claim or demand, or actions including reasonable attorneys’ fees,
          made by any third party or penalty imposed due to or arising out of
          Your breach of this Terms of Use, privacy Policy and other Policies,
          or Your violation of any law, rules or regulations or the rights
          (including infringement of intellectual property rights) of a third
          party.
        </p>
        <br />
        <br />
        <h3> 14. Limitation of damages</h3>
        <p>
          In no event shall Feature Ventures or any of its subsidiaries or
          affiliates be liable to any entity for any direct, indirect, special,
          consequential or other damages (including, without limitation, any
          lost profits, business interruption, loss of information or programs
          or other data on your information handling system) that are related to
          the use of, or the inability to use, the content, materials, and
          functions of this Website or any linked Website, even if Feature
          Ventures is expressly advised of the possibility of such damages.
        </p>
        <br />
        <br />
        <h3>15. Applicable Law</h3>
        <p>
          Terms of Use shall be governed by and interpreted and construed in
          accordance with the laws of India. The place of jurisdiction shall be
          exclusively in Bangalore.
        </p>
        <br />
        <br />
        <h3>16. Contact Us</h3>
        <p>
          Please contact us at hello@eyseapp.com for any questions or comments
        </p>
      </div>
    );
}

export default TermsAndConditions;