import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { AppSearchQuery, getAllInfluencersPostsWithMentionsByEmail, getAllMentionsWithSourcePostByTaggedStore, getAllUniqueSearcheswithUserInfo, getAllUniqueWithFilterKeySearcheswithUserInfo, getAllValidatedMentionsWithSourcePostForServiceProviders, getSinlgeInfluencerDetailsWithOutMobileAndEmail, getProductDetails, getSingleStoreDetailsByUserIdWithOutMobileAndEmail, getSinlgeInfluencerPostsByEmail, getUniqueSearcheswithUserInfo, registerRetailer, saveOrder, getAllMentionsWithSourcePostByTaggedStoreWithUserId, getAllMentionsWithSourcePostWithOutMobileAndEmail } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import SubmitPopover3 from "../../components/SubmitPopover/SubmitPopover3";
import styles1 from "../Home/index.module.css"
import BrandHeader from "./BrandHeader";
import StoreHeader from "./StoreHeader";
import InfluencerHeader from "./InfluencerHeader";
import IndependentHeader from "./IndependentHeader";

const ValidatedReviews = (props) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError,setApiError] = useState('')
  const [brandDetails, setBrandDetails] = useState({
    quantity:0,
    
    firstName: "",
    lastName: "",
    houseNumber:"",
    brandName: "",
    contactNum: "",
    emailId: "",
    street:"",
    ladnmark: "",
    city: "",
    state:"",
    country:"",
    pincode:""
  });
  const [error, setError] = useState({
    quantity:false,
    
    firstName: false,
    lastName:false,
    houseNumber:false,
    brandName: false,
    contactNum: false,
    emailId: false,
    street:false,
    ladnmark: false,
    city: false,
    state:false,
    country:false,
    pincode:false
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product,setProduct] = useState()
  let {email,storeUserId,brandUserId,influencerId,revalidated}=useParams()
  const [orderLoading,setOrderLoading] = useState(false)
  const [searchKey,setSearchKey] = useState()
  const [queries,setQueries] = useState([])
  const [shopDetails,setShopDetails] = useState()
  const [posts,setPosts] =  useState([])
  const [influencerDetails,setInfluencerDetails] = useState()

  useEffect(()=>{
    if(storeUserId||brandUserId){
      getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId?storeUserId:brandUserId)
        .then(res => {
          // console.log(res.Data.name)
          if (res.status == 200) {
            setShopDetails(res.Data)
            // if(res.Data.user_id && res.Data.user_id.isBrand){
            //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
            // }else{
  
            //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
            // }
          }
        })
    }else if(influencerId){
      getSinlgeInfluencerDetailsWithOutMobileAndEmail(influencerId)
      .then(res => {
        // console.log(res)
        if (res.Data) {
            setInfluencerDetails(res.Data)
        }
          // })
        // }
      })
    }else{
      getAllMentionsWithSourcePostWithOutMobileAndEmail()
        .then(res => {
          // console.log(res.Data)
          if (res.Data && res.Data.length>0) {
            setPosts(res.Data)
            // getQuriesWithKey(res.Data.name)
            setSearchKey(res.Data.name)
          }
        })
    }
  },[])

  useEffect(()=>{
    if(email){
      ((storeUserId||brandUserId)?getAllMentionsWithSourcePostByTaggedStoreWithUserId({storeEmail:email}):revalidated?getAllValidatedMentionsWithSourcePostForServiceProviders({userId:influencerId}):getAllMentionsWithSourcePostWithOutMobileAndEmail({userId:influencerId}))
          .then(res => {
            // console.log(res.Data)
            if (res.Data && res.Data.length>0) {
              setPosts(res.Data)
              // getQuriesWithKey(res.Data.name)
              setSearchKey(res.Data.name)
            }
          })
    }
  },[])


  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

// useEffect(()=>{
//   console.log(productId)
//   getProductDetails(productId)
//       .then(res => {
//         // console.log(res.Data)
//         // let arr= res.Data
//         // if(arr && arr.length>0){
//           // arr.map(i=>{
            
//             // let arr2 = arr.map((item, index) => {
//               // console.log(item)
//               // return { key: index + 1, text: item.prdct_attributes.prdct_name, value: item };
//             // });
//             // setProducts([...[{key:-1,text:'Select Product',value:null}],...arr2]);
//             // arr2.sort( (a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
//             setProduct(res.Data);
//           // })
//         // }
//       })
// },[])

  return (
    <div className={styles.body}>
      <SubmitPopover3
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      {console.log("influencerId",influencerId)}
      {console.log("influencerDetails",influencerDetails)}
      {console.log("brandUserId",brandUserId)}
      {console.log("shopDetails",shopDetails)}
      {!influencerId&&!brandUserId&&!storeUserId&&<IndependentHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
        {influencerId&&influencerDetails&&<InfluencerHeader influencerId={influencerId} influencerDetails={influencerDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
        {brandUserId&&shopDetails&&<BrandHeader storeUserId={brandUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
        {storeUserId&&shopDetails&&<StoreHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>}
      {/* <div className={styles.sectionHead}>
      <div style={{ alignSelf: "center" }}>
        <Link to="/">
          <img
            className={styles1.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
          </Link>
        </div>
        <div className={styles.pageHeadings}>
          <span className={styles.pageHeading} ref={h1}></span>{" "}
        </div>
      </div> */}
      {/* <div className={styles.border}>
        <div></div>
      </div> */}
      {/* <div className={styles.border}>
        <div></div>
      </div> */}
      <div  className={styles1.sectionForm} style={{maxHeight:height,overflowY:"scroll"}}>
          <h1>Tagged Reviews</h1>
          {posts.map(post=>{
            let post1 = post && post.collections && post.collections.savedItems && post.collections.savedItems.pinObj && post.collections.savedItems.pinObj.postData && post.collections.savedItems.pinObj.postData ?
            post && post.collections && post.collections.savedItems && post.collections.savedItems.pinObj && post.collections.savedItems.pinObj.postData && post.collections.savedItems.pinObj.postData:null
            // let post2 = post && post.sourcePost && post.sourcePost.
          console.log("post1",post1)
          return <div>
          <div>
        {post1.map(i=>{
          console.log(i)
          return (
            <div>
              {/* <img
                src={i.type=='VIDEO'?i.data && i.data[0].thumbnail:i.data[0].url}
                style={{height:'100px',width:'100px'}}
              /> */}
              <div style={{ display:"flex", overflowX: "auto" }}>
              {i.data && i.data.length>0 && i.data.map(p => {
                return (
                  <img
                    src={i.type && i.type == 'VIDEO' && p.thumnail? p.thumbnail : p.url}
                    // style={{ height: '100px', width: '100px' }}
                    style={{ maxHeight: height<=width?height/2:height, maxWidth: height<=width?width/2:width, }}
                  />
                )
              })}
            </div>
              {<p>Title: {i.caption&& i.caption[0] &&i.caption[0].value}</p>}
              {<p>Description: {i.desc&& i.desc[0] &&i.desc[0].value}</p>}
              {post1&&<a href={`${APP_URL}post/${post.collections.savedItems.pinObj._id}/${i._id}`} className={styles1.btn}>
                  Explore Details
                  </a>}
              {i.products && i.products.length>0 && i.products.map(p=>{
                return(

              <div>
                {brandUserId&&<div>
                {/* <div style={{ display: "flex", overflowX: "auto" }}>
                      <img
                        src={post.products.productImage}
                        // style={{ height: '100px', width: '100px' }}
                        style={{ maxHeight: height, maxWidth: width }}
                      />
                </div> */}
                {<Link to={`/buyfrombrandreview/${post.userId}/${post.collections.postId}/${brandUserId}/${p.productID}`} className={styles1.btn}>
                  {/* Buy Now */}
                {<h5>Product :{p.name} {p.ordered?'(Verified Purchase)':''}</h5>}
                </Link>}
              </div>}
              {storeUserId&&<div>
                {/* <div style={{ display: "flex", overflowX: "auto" }}>
                      <img
                        src={post.products.productImage}
                        // style={{ height: '100px', width: '100px' }}
                        style={{ maxHeight: height, maxWidth: width }}
                      />
                </div> */}
                  {/* Buy Now */}
                {<h5>Product :{p.name} {p.ordered?'(Verified Purchase)':''}</h5>}
              </div>}
              </div>
                )
              })}
              {post&&<a href={`${APP_URL}post/${post.sourcePost._id}/${post.sourcePost.postData._id}`} className={styles1.btn}>
              {<h5>Mentions {
              post.sourcePost&&  
              post.sourcePost.postData&&post.sourcePost.postData.caption&&
              post.sourcePost.postData.caption[0]&&post.sourcePost.postData.caption[0].value}</h5>}
                  </a>}
              {/* {brandUserId&&<Link to={`/tagbrandreview/${brandUserId}/${query&& query.searchHist&&query.searchHist._id}`} className={styles1.btn}>
                  Answer Now
                </Link>} */}
              {i&&influencerId&&<Link to={`/taginfluencerreview/${post.collections.savedItems.pinObj._id}/${i._id}`} className={styles1.btn}>
              Retag
              </Link>}
              {i&&brandUserId&&<Link to={`/tagbrandreview/${post.collections.savedItems.pinObj._id}/${i._id}/${brandUserId}`} className={styles1.btn}>
              Retag
              </Link>}
              {i&&storeUserId&&<Link to={`/tagstorereview/${post.collections.savedItems.pinObj._id}/${i._id}/${storeUserId}`} className={styles1.btn}>
              Retag
              </Link>}
              {i&&!storeUserId&&!brandUserId&&!influencerId&&<Link to={`/tagreview/${post.collections.savedItems.pinObj._id}/${i._id}`} className={styles1.btn}>
              Retag
              </Link>}
              </div>
          )
        })}
      </div>
        </div>
          })}
          </div>
      {/* <Footer /> */}
    </div>
  );
}

export default ValidatedReviews;