import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import Cross from "../../assets/icons/cross-blue.svg"
import BurgerMenu from "../../assets/icons/burger-menu-blue.svg"
import DollarSign from "../../assets/icons/dollar-sign.svg";
import Speaker from "../../assets/icons/speaker.svg"
import Graph from "../../assets/icons/graph.svg"
import InstagramLogo from "../../assets/icons/instagram-logo-2.svg";
import FacebookLogo from "../../assets/icons/facebook-logo.svg";
import TwitterLogo from "../../assets/icons/twitter-logo.svg"
import Plus from "../../assets/icons/plus.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";
import { Dropdown, FormField, Form, Checkbox } from "semantic-ui-react";

import { allBrands, getAllCities, getAllInflncrCate, getAllParentCategory, getAllStates, getNearbyShopsDetailsWithCategoryAndBrandFilter, registerNewInfluencer } from "../../api/ApiManager";

import styles from "./index.module.css"
import IndependentHeader from "../join-as-brand/IndependentHeader";

const StoreLocator = () => {

  const {height, width} = useWindowDimensions();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [education, setEducation] = useState([
    { id: 1, value: "High School" },
    { id: 2, value: "Junior College" },
    { id: 3, value: "Under Graduate" },
    { id: 4, value: "Post Graduate" },
  ]);
  const [influencerCategories, setInfluencerCategories] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    emailId: "",
    contactNum: "",
    state: "",
    city: "",
    education: "",
    expertise: "",
    yearsOfExp: "",
    instagram: "",
    facebook: "",
    twitter: "",
    category:"",
    brand:"",
  });
  const [otherLinks, setOtherLinks] = useState([]);
  const [error, setError] = useState({
    name: false,
    emailId: false,
    contactNum: false,
    state: false,
    city: false,
    education: false,
    expertise: false,
    yearsOfExp: false,
    category:false,
    brand:false,
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [apiError,setApiError] = useState('')
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [categories,setCategories] = useState()
  const [brands,setBrands] = useState()
  const [stores, setStores] = useState([])
  const [location,setLocation] = useState()
  const [navigationLink,setNavigationLink]=useState()
  const [value,setValue] = useState('this')
  useEffect(() => {
    handleLocationClick()
    getAllParentCategory()
    .then(res=>{
      // console.log(res)
      let arr =[]
      if(res.Data && res.Data.length>0){
        res.Data.map(parentcat=>{
          if(parentcat.childCategorys && parentcat.childCategorys.length>0){
            arr = ([...arr,...parentcat.childCategorys])
          }
        })
      }
      let arr2=[]
      arr.length>0&&arr.map((c,index)=>{
        arr2.push({ key: index + 1, text: c.childCategoryName, value: c })
      })
      setCategories(arr2)
    })
    allBrands()
    .then(res=>{
      // console.log(res.data.brand)
      let arr =[]
      if(res.data && res.data.brand&&res.data.brand.length>0){
        arr=res.data.brand
      }
      let arr2=[]
      arr.length>0&&arr.map((c,index)=>{
        arr2.push({ key: index + 1, text: c, value: c })
      })
      setBrands(arr2)
    })

    getAllStates()
    .then(res => {
      // console.log(res.data);
      let arr= res.data
        arr.sort( (a, b) => a.name > b.name ? 1 : -1)
      setStates(arr);
    })

    getAllCities()
    .then(res => {
      // console.log(JSON.stringify(res.data))
      let arr= res.data
        arr.sort( (a, b) => a.name > b.name ? 1 : -1)
      setCities(arr);
    })

    getAllInflncrCate()
    .then(res => {
      if (res.status === 200) {
        // console.log(res.data);
        let arr= res.data
        arr.sort( (a, b) => a.field > b.field ? 1 : -1)
        setInfluencerCategories(arr);
      }
    })

    // const typedH1 = new Typed(h1.current, {
    //   strings: ["Become an EYSE Expert to 	&#38; "],
    //   typeSpeed: 40,
    //   showCursor: false,
    // });

    // const typedAccentH1 = new Typed(accentH1.current, {
    //   strings: ["start earning immediately for your content"],
    //   startDelay: 1800,
    //   typeSpeed: 40,
    //   showCursor: false,
    // });

    // return () => {
    //   // typedH1.destroy();
    //   // typedAccentH1.destroy();
    // };
  }, []);

  useEffect(() => {
    if (width >= 834) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (userDetails.name && userDetails.emailId && userDetails.contactNum && userDetails.state && userDetails.city && userDetails.education && userDetails.expertise && userDetails.yearsOfExp && (userDetails.instagram || userDetails.facebook || userDetails.twitter)) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  }, [userDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const handleUserDetails = (name, val) => {
    setUserDetails({
      ...userDetails,
      [name]: val,
    });
  };

  const handleOtherLinks = (val, index) => {
    let newArr = otherLinks;
    newArr[index] = val
    setOtherLinks([...newArr])
    console.log(val)
  }

  const handleSubmit = (e) => {
    e.preventDefault();


    setError({
      category: userDetails.category!=""?false:true,
      brand: userDetails.brand!=""?false:true
    });

    if (userDetails.category=="" && userDetails.brand=="") {
      setShowError(true);
      setSubmitBtnDisabled(true);
      return
    } else {
      setShowError(false);
      setSubmitBtnDisabled(false);
    }

    if (
      userDetails.category!="" || userDetails.brand!=""
    ) {
      setSubmitSuccess(true);
      // final submit function here
      let body = value=='this'?{
        lat: location.lat, long: location.long, childCategoryName: userDetails.category.childCategoryName
      } : {
        lat: location.lat, long: location.long,
        brand: userDetails.brand
      }
      getNearbyShopsDetailsWithCategoryAndBrandFilter(body).then(res => {
        console.log(res)
          if(res.status==200&&res.Data && res.Data.length>0){
            setStores(res.Data);
          }else{
            setStores([])
          }
        })
        .catch(error => console.log(error));
    }


  };
  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error1);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    let lt= "https://maps.google.com?q="+ latitude+","+longitude
    setLocation({lat:latitude,long:longitude})    
    setNavigationLink(lt)
  }

  function error1() {
    console.log("Unable to retrieve your location");
  }


  return (
    <div className={styles.body}>
      <SubmitPopover
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      <IndependentHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
      {/* <header className={styles.header}>
        <Link to="/">
          <img
            className={styles.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-dark.png")}
          />
        </Link>
        <img
          className={styles.burgerMenu}
          src={isMenuOpen ? Cross : BurgerMenu}
          alt="burger-menu"
          style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
          onClick={() => setIsMenuOpen((prevState) => !prevState)}
        />
        <a
          href="#expert-form"
          className={styles.headerBtn + " " + styles.btn}
          style={{
            transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
          }}
        >
          <p>Store Locator</p>
        </a>
      </header> */}
      <div className={styles.sectionForm} id="expert-form">
        <h1>Eyse Store Locator</h1>
        <p>
          Explore <br />
          Nearby Store
        </p>
        <form action="" className={styles.expertForm}>
          <Form>
            {/* <FormField>
              Selected value: <b>{value}</b>
            </FormField> */}
            <FormField>
              <Checkbox
                radio
                label='Search By Category'
                name='checkboxRadioGroup'
                value='this'
                checked={value === 'this'}
                onChange={(e, data) => setValue(data.value)}
              />
            </FormField>
            <FormField>
              <Checkbox
                radio
                label='Search By Brand'
                name='checkboxRadioGroup'
                value='that'
                checked={value === 'that'}
                onChange={(e, data) => setValue(data.value)}
              />
            </FormField>
          </Form>
          {value === 'this' && <div>
            <label htmlFor="category">Category</label>
            <Dropdown
              value={userDetails.category ? userDetails.category : "Search Category"}
              placeholder={userDetails.category ? userDetails.category : "Search Category"}
              // disabled={selectedProduct?true:false}
              search
              selection
              options={categories}
              onChange={(event, { value }) => {
                console.log(event)
                handleUserDetails("category", value)
              }
              }
            />
          </div>}
          {value === "that" && <div>
            <label htmlFor="brand">Brand</label>
            <Dropdown
              value={userDetails.brand ? userDetails.brand : "Search Brand"}
              placeholder={userDetails.brand ? userDetails.brand : "Search Brand"}
              // disabled={selectedProduct?true:false}
              search
              selection
              options={brands}
              onChange={(event, { value }) => {
                console.log(event)
                handleUserDetails("brand", value)
              }
              }
            />
          </div>}
            <div>
              {/* <p style={{ display: showError ? "initial" : "none" }}>
                Error detected-{apiError} Please correct and submit again
              </p> */}
              <button
                type="submit"
                style={{
                  backgroundColor: /*submitBtnDisabled*/ (userDetails.category=="" && userDetails.brand=="") || !location? "#677890" : "#011E46",
                  cursor: /*submitBtnDisabled*/(userDetails.category=="" && userDetails.brand=="") || !location? "default" : "pointer",
                }}
                disabled={/*submitBtnDisabled*/(userDetails.category=="" && userDetails.brand=="") || !location}
                className={styles.submitBtn + " " + styles.btn}
                onClick={(e) => handleSubmit(e)}
              >
                Get Nearby Store
              </button>
            </div>
          </form>
          <div  style={{maxHeight:height,overflowY:"scroll"}}>
          <h5>Showing: {stores.length} Stores</h5>
          {stores.map(store=>{
          // console.log(product.value.prdct_attributes)
          return !store.isBrand&&(
            <div style={{display:"flex",overflowX:"scroll"}} >
              <h5>{store.name}</h5>
              <h5>{store.address}</h5>
              {store.location&&store.location.coordinates&&store.location.coordinates[0]&&store.location.coordinates[1]&&
              <a href={"https://maps.google.com?q="+ store.location.coordinates[0]+","+store.location.coordinates[1]}>Get Direction</a>
              }
            </div>
          )})}
          </div>
      </div>
      <div className={styles.border}>
        <div></div>
      </div>
      <Footer />
    </div>
  );
}

export default StoreLocator;