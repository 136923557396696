//var dummy_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmZlNzNjNDVlN2NmZDhhNzI0OWJlMjUiLCJuYW1lIjoiYWRtaW4xIiwiZW1haWwiOiJhZG1pbjFAZ21haWwuY29tIiwibW9iaWxlIjoiOTAyNzM0NjkxNiIsInJvbGUiOlsiQURNSU4iXSwiaWF0IjoxNjYwODc1NzA0LCJleHAiOjE2NjM0Njc3MDR9.zCvAjMynDZsxBh9XgJ2FTgSi94RzVnHr7Z7-J6qKWCw'
//var dummy_token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MmZlNzQxMjVlN2NmZDhhNzI0OWJlMmYiLCJuYW1lIjoic3R1ZGVudDEiLCJlbWFpbCI6InN0dWRlbnQxQGdtYWlsLmNvbSIsIm1vYmlsZSI6IjkwMjcxNDY5MTEiLCJyb2xlIjpbIlNUVURFTlQiXSwiaWF0IjoxNjYxMTczNTMxLCJleHAiOjE2NjM3NjU1MzF9.-gsGRdNNlZ5lRsLMPpqXql0Cd9l5YRGiW-BZsKqXIpw'

const commonFetch = (data) => {
  let auth_token = "";
  try { 
    auth_token = JSON.parse(
      JSON.stringify(localStorage.getItem("auth_token"))
    ).replace(/['"]+/g, "");
  } catch (err) {
    console.log("token not there")
  }
  // console.log("auth_token => ", auth_token);
  return fetch(`${data.url}`, {
    method: data.method,
    headers: {
      Accept: "application/json",
      Authorization: auth_token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data.data),
  });
};
export default commonFetch;
