import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import { getProductDetails, registerRetailer, saveOrder2 } from "../../api/ApiManager";
import styles1 from "../Home/index.module.css"

const ThankYou = ({type}) => {
  const { height, width } = useWindowDimensions();
  let {storeUserId, productId, mentionInfId, mentionPostId}=useParams()
  const [showPopover, setShowPopover] = useState(true);
  return (
    <div className={styles.body}>
      <SubmitPopover
      message={"Your order has been placed"}
        showPopover={showPopover}
        success={true}
        closePopover={() => setShowPopover(false)}
      />
      <div className={styles.signedUp}>
          <img src={CheckCircle} alt="check-mark" />
          <h3>{type=='b2b'?"Download Dealer app to for more details!":"Download Eyse app to explore similar reviews!"}</h3>
          <p>(Default password for new account:12345)</p>
          <a href={type=="b2b"?`https://play.google.com/store/apps/details?id=com.eyse.dealer`:`https://play.google.com/store/apps/details?id=com.eyse`}>
          <div className={styles1.storeLogos}>
      <img
          src={type=="b2b"?require("../../assets/logo/web_dealer_logo.png"):require("../../assets/icons/eyse-logo.jpg")}
          alt="playstore-icon"
        />
        <img
          src={require("../../assets/icons/playstore.png")}
          alt="playstore-icon"
        />
        {/* <img
          src={require("../../assets/icons/appstore.png")}
          alt="appstore-icon"
        /> */}
      </div>
      </a>
          {mentionInfId && mentionPostId && storeUserId && productId ?
            <div className={styles1.signUpBeta + " " + styles1.btn}>
              <Link to={"/tagbrandreview/" + mentionInfId + "/" + mentionPostId+ "/" + storeUserId + "/" + productId} className={styles.btn}>
              Share your experience of the brand, if it helps others to buy then you win more cashback!
                  </Link>
              {/* <a
                className={styles.signUpBeta + " " + styles.btn}
                href={window.location.hostname+"/tagbrandreview/" + mentionInfId + "/" + mentionPostId+ "/" + storeUserId + "/" + productId}
              >
                Share your experience of the brand, if it helps others to buy then you win more cashback!
              </a> */}
              
            </div>

            : null}
        </div>
      <div className={styles.border}>
        <div></div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default ThankYou;