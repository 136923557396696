import React from "react"
import { Link } from "react-router-dom"

import PhoneIcon from "../../assets/icons/phone.svg"
import EmailIcon from "../../assets/icons/email.svg"
import InstagramLogo from "../../assets/icons/instagram-logo.svg"
import LinkedInLogo from "../../assets/icons/linkedin-logo.svg"

import styles from "./index.module.css";

const Footer = () => {

  const currentYear = new Date().getFullYear(); 

    return (
      <>
        <footer>
          <div>
            <Link to="/">
              <img
                className={styles.logo}
                alt="website-logo"
                src={require("../../assets/logo/logo-dark.png")}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              />
            </Link>
            <div className={styles.contactInfo}>
              <div>
                <img src={PhoneIcon} alt="phone-icon" />
                <p>
                  <a href="tel:9632391200">96323 91200</a>
                </p>
              </div>
              <div>
                <img src={EmailIcon} alt="email-icon" />
                <p>
                  <a href="mailto:hello@eyseapp.com">hello@eyseapp.com</a>
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className={styles.socialMediaLinks}>
              <a
                href="https://www.linkedin.com/company/eyse-app/"
                target={"_blank"}
              >
                <img src={LinkedInLogo} alt="linkedin-logo" />
              </a>
              <a href="https://www.instagram.com/eyse.app/" target={"_blank"}>
                <img src={InstagramLogo} alt="instagram-logo" />
              </a>
            </div>
            <p className={styles.links}>
              <Link to="/privacypolicy">Privacy Policy</Link>
              <Link to="/termsandconditions">Terms & Conditions</Link>
            </p>
          </div>
        </footer>
        <p className={styles.copyrightText}>
          Copyright © {currentYear}, All Right Reserved
        </p>
      </>
    );
}

export default Footer;