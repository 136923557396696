import React, { useState, useEffect, useRef } from "react";
import Typed from "typed.js";
import { Link, useParams } from "react-router-dom";

import useWindowDimensions from "../../custom-hooks/useWindowDimensions";
import SubmitPopover from "../../components/SubmitPopover/SubmitPopover";
import Footer from "../../components/Footer/Footer";

import BurgerMenu from "../../assets/icons/burger-menu.svg";
import Cross from "../../assets/icons/cross.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";

import styles from "./index.module.css"
import styles1 from "../Home/index.module.css"
import styles2 from "../join-as-expert/index.module.css"
import { addReview, directs3upload, getAllInflncrCate, getAllProductsOnUserId, getSinlgeInfluencerDetailsWithOutMobileAndEmail, getInfluencerPosts, getProductDetails, getProductsOnBrandName, getProductsOnBrandNameWithoutToken, getSingleStoreDetailsByUserIdWithOutMobileAndEmail, getSpecificPost, registerRetailer, searchBrandNames, searchStoreOrBrand, searchTag } from "../../api/ApiManager";
import { APP_URL } from "../../api/ApiConstants";
import { Dropdown, FormField, Form, Checkbox } from "semantic-ui-react";
import imageCompression from 'browser-image-compression';
import SubmitPopover2 from "../../components/SubmitPopover/SubmitPopover2";
import {WhatsappShareButton,WhatsappIcon } from "react-share";
import QRCode from "react-qr-code";
import BrandHeader from "./BrandHeader";
import StoreHeader from "./StoreHeader";
import InfluencerHeader from "./InfluencerHeader";
import IndependentHeader from "./IndependentHeader";
import Plus from "../../assets/icons/plus.svg";
import Ticker, { FinancialTicker, NewsTicker } from 'nice-react-ticker';
// import styles1 from "../../pages/Home/index.module.css"

const AddReview2 = (props) => {
  const { height, width } = useWindowDimensions();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessEmailError, setBusinessEmailError] = useState(false);
  const [apiError, setApiError] = useState('')
  const [reviewDetails, setReviewDetails] = useState({
    quantity: 0,

    firstName: "",
    lastName: "",
    houseNumber: "",
    brandName: "",
    contactNum: "",
    emailId: "",
    street: "",
    ladnmark: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    store: '',
    brand: '',
    images: [],
    videos: [],
    products: [],
    invoice:[],
    infleuncerCategories: [],
    tags: [],
    title: '',
    description: '',
    name: '',
    location: ''
  });
  const [error, setError] = useState({
    quantity: false,

    firstName: false,
    lastName: false,
    houseNumber: false,
    brandName: false,
    contactNum: false,
    emailId: false,
    street: false,
    ladnmark: false,
    city: false,
    state: false,
    country: false,
    pincode: false,
    store: false,
    brand: false,
    images: false,
    videos: false,
    products: false,
    infleuncerCategories: false,
    tags: false,
    title: false,
    description: false,
    name: false,
    location: false
  });
  const [titlePlaceholder, setTitlePlaceholder] = useState({
    serviceprovideraddservice:"Enter Service Name",
    contentcreatorshareskill:"How I Used [Tool/Skill] to Make [Outcome/Result] Happen!",
    contentcreatorservice:"Enter Service Title",
    contentcreatorconversation:"Conversation Heading",
    customeraddreview:"My experience of using [product/service]!",
    customeraddreviewthread:"I need your opinion on [Product/Service]",
    recruiter:"Job Requirement Overview"
  });
  const [descriptionPlaceholder, setDescriiptionPlaceholder] = useState({
    serviceprovideraddservice:"Describe the Service",
    contentcreatorshareskill:"What was the situation?\n What was the challenge?\n What action did you take? \n What were the outcomes ?",
    contentcreatorservice:"Describe the service.\n For Whom ? \n What outcomes they can expect ?",
    contentcreatorconversation:"Describe the topic.\n What is the need ?\n Why now?",
    customeraddreview:"Introduction:Begin by introducing the product and providing some context about its purpose or use.\nAppearance/Design:Describe the visual aspects of the product, including its design, colors, and overall aesthetic appeal.\nPerformance:Evaluate how well the product performs its intended function.\n Consider factors such as speed, accuracy, durability, and ease of use.\nFeatures:Discuss any notable features or functionalities of the product.\n Highlight unique selling points or aspects that set it apart from similar products on the market.\nPros:List the advantages or strengths of the product.\n What aspects do you particularly like or find beneficial?\nCons:Identify any drawbacks or weaknesses of the product.\n Are there areas where it could be improved or features that are lacking?\nUser Experience:Share your overall experience using the product. Consider factors such as comfort, convenience, and satisfaction.\nValue for Money:Assess whether the product offers good value for its price. Is it reasonably priced considering its quality and features?\nConclusion:Summarize your thoughts on the product and whether you would recommend it to others. Offer any final thoughts or recommendations.",
    customeraddreviewthread:"Describe the topic.\n What is the need ?\n Why now?",
    recruiter:"Key highlights of the requirement including skillset."
  });
  const [showError, setShowError] = useState(false);
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [postId,setPostId] = useState(null)
  const [infId,setInfId] = useState(null)
  const [infEmail,setInfEmail] = useState(null)
  const [postDetails,setPostDetails] = useState(null)
  const [toMentionPostDetails,setToMentionPostDetails] = useState(null)
  const h1 = useRef(null);
  const accentH1 = useRef(null);
  const colors = {
    blue: "#B3CEF2",
    red: "#CE0F0F",
  };
  const [product, setProduct] = useState()
  let { storeUserId,/* brandUserId, productId*/ mentionPostId,mentionInfId,shareFeedback,queryId,influencerId,isServiceProvider} = useParams()
  const [orderLoading, setOrderLoading] = useState(false)
  const [searchStores, setSearchStores] = useState([])
  const [searchTags, setSearchTags] = useState([])
  const [searchBrands, setSearchBrands] = useState([])
  const [loading, setLoading] = useState(false)
  const [productsOnBrand, setProductsOnBrand] = useState([])
  const [infleuncerCategories, setInfluencerCategories] = useState([])
  const [copied, setCopied] = useState(false);
  const [influencerPosts,setInfluencerPosts] = useState([])
  const [selectedService,setSelectedService] = useState()
  const [influencerDetails,setInfluencerDetails] = useState()
  const [mentionedInfluencerDetails,setMentionedInfluencerDetails] = useState()
  const [location,setLocation] = useState()
  const [otherLinks, setOtherLinks] = useState([]);
  const [otherLinkNames, setOtherLinkNames] = useState([]);
  const [value,setValue] = useState('notset')
  const [isPrivate,setIsPrivate] = useState(false)
  const [value2,setValue2] = useState()
  const [heading,setHeading] = useState('')
  const [hideBrand,setHideBrand] = useState(false)
  const [hideStore,setHideStore] = useState(false)
  const handleOtherLinkNames = (val, index) => {
    let newArr = otherLinks;
    newArr[index] = val
    setOtherLinkNames([...newArr])
    console.log(val)
  }
  const handleOtherLinks = (val, index) => {
    let newArr = otherLinks;
    newArr[index] = val
    setOtherLinks([...newArr])
    console.log(val)
  }
  useEffect(() => {
    getSinlgeInfluencerDetailsWithOutMobileAndEmail(influencerId)
      .then(res => {
        // console.log(res)
        if (res.Data) {
          setInfluencerDetails(res.Data)
          getInfluencerPosts(influencerId)
          .then(res1=>{
            if(res1.Data&&res1.Data.length>0){
              let arr2 = res1.Data.map((item, index) => {
                // console.log(item)
                return { key: index + 1, text: item&&item.caption&&item.caption[0]&&item.caption[0].value?item.caption[0].value:"", value: item };
              });
              setInfluencerPosts(arr2)
            }
          })
        }
      })
  }, [])
  useEffect(()=>{
    if(isServiceProvider){
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error1);
      } else {
        alert("Geolocation not supported")
        console.log("Geolocation not supported");
      }
    }
  },[])
  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
    let lt= "https://maps.google.com?q="+ latitude+","+longitude
    setLocation({lat:latitude,long:longitude})    
    // setNavigationLink(lt)
  }

  function error1() {
    console.log("Unable to retrieve your location");
  }
  useEffect(() => {
    getSinlgeInfluencerDetailsWithOutMobileAndEmail(mentionInfId)
      .then(res => {
        // console.log(res)
        if (res.Data) {
          setMentionedInfluencerDetails(res.Data)
          // getInfluencerPosts(influencerId)
          // .then(res1=>{
          //   if(res1.Data&&res1.Data.length>0){
          //     let arr2 = res1.Data.map((item, index) => {
          //       // console.log(item)
          //       return { key: index + 1, text: item&&item.caption&&item.caption[0]&&item.caption[0].value?item.caption[0].value:"", value: item };
          //     });
          //     setInfluencerPosts(arr2)
          //   }
          // })
        }
      })
  }, [])
  
  function copy() {
    const el = document.createElement("input");
    el.value = influencerId?window.location.host + "/taginfluencerreview/" + infId + "/"+ postId + "/" + influencerId:(storeUserId && !props.brand)?window.location.host + "/tagstorereview/" + infId + "/" + postId+ "/" + storeUserId
    :(!storeUserId && props.brand)?window.location.host + "/buyfrombrandreview/" + infId + "/" + postId+ "/" + props.brand.value.user_id._id
    :shareFeedback?window.location /*.host + "/sharereview/true/" */+ "/"+ infId + "/" + postId
    :window.location /*.host + "/sharereview/true/" */ + "/"+ infId + "/" + postId;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const [shopDetails,setShopDetails] = useState()

  useEffect(()=>{
    if(storeUserId){
      getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId)
        .then(res => {
          console.log(res.Data.name)
          if (res.status == 200) {
            setShopDetails(res.Data)
            // if(res.Data.user_id && res.Data.user_id.isBrand){
            //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
            // }else{
  
            //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
            // }
          }
        })
    }
  },[])

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://sdk.cashfree.com/js/v3/cashfree.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    if (width >= 834) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (reviewDetails.images != []
      && reviewDetails.store != '' && reviewDetails.store
      && reviewDetails.contactNum && reviewDetails.contactNum != ''
      && reviewDetails.brand && reviewDetails.brand != ''
      && reviewDetails.emailId && reviewDetails.emailId != ''
      && reviewDetails.name && reviewDetails.name != '') {
      setSubmitBtnDisabled(false);
    }
  }, [reviewDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(storeUserId){

      getShop(storeUserId)
    } 
    // else if(productId){
    //   getProductInfo(productId)
    // }
    // else if (brandUserId){
    //   getBrand(brandUserId)
    // }
  }, [])
  useEffect(()=>{
    getSpecificPost(mentionInfId,mentionPostId)
        .then(res => {
          if(res.status==200){
            // console.log("toMentionPostDetails",res)
            setToMentionPostDetails(res.Data);
          }
            // })
          // }
        })
  },[])
  const getShop = (storeUserId) => {
    getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId)
      .then(res => {
        if (res.status == 200) {
          handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
          // if(res.Data.user_id && res.Data.user_id.isBrand){
          //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
          // }else{

          //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
          // }
        }
      })
  }

  const getBrand = (storeUserId) => {
    getSingleStoreDetailsByUserIdWithOutMobileAndEmail(storeUserId)
      .then(res => {
        if (res.status == 200) {
          handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
          // if(res.Data.user_id && res.Data.user_id.isBrand){
          //   handleReviewDetails("brand", { key: 1, text: res.Data.name, value: res.Data })
          // }else{

          //   handleReviewDetails("store", { key: 1, text: res.Data.name, value: res.Data })
          // }
        }
      })
  }
  const getProductInfo=(productId)=>{
    getProductDetails(productId)
      .then(res => {
        if (res.Data) {
          handleReviewDetails("products", { key: 1, text: res.Data.prdct_attributes.tagName, value: res.Data })
          // console.log("asdasdsdasdasdsd----------------", res.Data.prdct_attributes.brand)
          // handleReviewDetails("brand", { key: 1, text: res.Data.prdct_attributes.brand, value: res.Data.prdct_attributes.tagName })
          // getSingleStoreDetailsByUserIdWithOutMobileAndEmail(res.Data.userId._id)
          // .then(res1 => {
          //     // console.log("asdasdsdasdasdsd----------------", res1.Data.name)
          //     if (res1.Data.user_id) {
          //       handleReviewDetails("brand", { key: 1, text: res1.Data.name, value: res1.Data })
          //     }
          //   })
        }
      })
  }
  useEffect(() => {
    getAllInflncrCate()
      .then(res => {
        let arr = res.data
        if (arr && arr.length > 0) {

          let arr2 = arr.map((item, index) => {
            // console.log(item)
            return { key: index + 1, text: item.field, value: item };
          });
          setInfluencerCategories(arr2);
          // setFilterProducts(arr2)
        }
      })
  }, [])

  const handleReviewDetails = (name, val) => {
    setReviewDetails({
      ...reviewDetails,
      [name]: val,
    });
  };

  const handleEmailSubmit = (e) => {
    // e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(businessEmail);

    testEmailId ? setBusinessEmailError(false) : setBusinessEmailError(true);

    if (testEmailId) {
      handleReviewDetails("emailId", businessEmail);
      window.location.replace("/joinasbrand#brand-form");
    };
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    // email validation
    const regexEmail = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
    const testEmailId = regexEmail.test(reviewDetails.emailId);

    // name validation
    const regexName = /^[a-zA-Z ]+$/;
    // const testFirstName = regexName.test(reviewDetails.firstName);
    // const testLasttName = regexName.test(reviewDetails.lastName);
    const testName = regexName.test(reviewDetails.name);

    const regexContactNum = /^\d{10}$/;
    const testContactNum = regexContactNum.test(
      parseInt(reviewDetails.contactNum)
    );
    const testPincode = /^\d{6}$/.test(
      parseInt(reviewDetails.pincode)
    );

    //TODO: products and link validation remaining

    setError({
      // quantity:reviewDetails.quantity>0?false:true,
      emailId: !testEmailId,
      name: !testName,
      // firstName: !testFirstName,
      // lastName:!testLasttName,
      // brandName: !testBrandName,
      // houseNumber:reviewDetails.houseNumber==''?true:false,
      // brandName: "",
      // street:reviewDetails.street==''?true:false,
      // ladnmark: reviewDetails.ladnmark==''?true:false,
      // city: reviewDetails.city==''?true:false,
      // state:reviewDetails.state==''?true:false,
      // country:reviewDetails.country==''?true:false,
      // pincode:!testPincode,
      contactNum: !testContactNum,
    });

    if (!testEmailId || !testName || !testContactNum) {
      setShowError(true);
      setSubmitBtnDisabled(true);
    } else {
      setShowError(false);
      getContentURL()
    }
  };

  const getContentURL = async () => {
    setLoading(true)
    let imagesArr = [];
    let compressedImages = [];
    let compressedInvoices = []
    let videosArr = [];
    let s3Images = [];
    let s3Invoices= []
    let s3Videos = [];
    let s3FileArray = [];
    let invoiceArr = [];
    for (let image of reviewDetails.images) {
      imagesArr.push(image);
    }
    for (let video of reviewDetails.videos) {
      videosArr.push(video);
    }
    for (let invoice of reviewDetails.invoice){
      invoiceArr.push(invoice)
    }
    //compress images first
    await Promise.all(
      imagesArr.map(async (imageFile) => {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          console.log(JSON.stringify(compressedFile));
          return compressedFile
          //await uploadToServer(compressedFile); // write your own logic
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      })
    ).then((res) => {
      compressedImages.push(...res);
    });
    await Promise.all(
      invoiceArr.map(async (imageFile) => {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          console.log(JSON.stringify(compressedFile));
          return compressedFile
          //await uploadToServer(compressedFile); // write your own logic
        } catch (error) {
          console.log(JSON.stringify(error));
        }
      })
    ).then((res) => {
      compressedInvoices.push(...res);
    });
    await Promise.all(
      compressedImages.map((element) => directs3upload(element, "IMAGE"))
    ).then((res) => {
      s3Images.push(...res);
    });
    await Promise.all(
      compressedInvoices.map((element) => directs3upload(element, "IMAGE"))
    ).then((res) => {
      s3Invoices.push(...res);
    });
    await Promise.all(
      videosArr.map((element) => directs3upload(element, "VIDEO"))
    ).then((res) => {
      s3Videos.push(...res)
      // console.log(
      //   'video s3 array.....asdasda............ :' +
      //     JSON.stringify(s3FileArray),
      // );
      s3FileArray = [...s3Images, ...s3Videos];
      // handleLocationClick(s3FileArray)
      addPost(s3FileArray,s3Invoices);
    });
    setLoading(false)
  };
  function handleLocationClick(s3FileArray) {
    if (navigator.geolocation) {
      var location_timeout = setTimeout("geolocFail()", 10000);
  
      navigator.geolocation.getCurrentPosition(function(position) {
          clearTimeout(location_timeout);
  
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;
  
          addPost(s3FileArray,lat, lng);
          console.log("assssssssssssssssss",lat)
      }, function(error) {
          clearTimeout(location_timeout);
          // geolocFail();
      });
  } else {
      // Fallback for no geolocation
      // geolocFail();
  }
}

  const addPost = async (s3FileArray,s3Invoices) => {
      let arr3 =[]
      let arr4=[]
      let hashCategory =[] 
      // console.log("--reviewDetails.infleuncerCategories->",JSON.stringify(reviewDetails.infleuncerCategories))
      // console.log("--reviewDetails.tags->",JSON.stringify(reviewDetails.tags))
      // return
      reviewDetails.infleuncerCategories && reviewDetails.infleuncerCategories.forEach((item)=>{
        hashCategory.push(item.field)
      })
      console.log("--hashcategogy->",JSON.stringify(hashCategory))
      let hashListwithCat=[]
      hashCategory&&hashCategory.map(i=>{
        if(i=='health and fitness'){
          hashListwithCat.push(`#health-and-fitness`)
        }else if(i=='animals & pets'){
          hashListwithCat.push(`#animals-and-pets`)
        }else if(i=='crafts & decor'){
          hashListwithCat.push(`#crafts-and-decor`)
        }else {
          hashListwithCat.push(`#${i}`)
        }
      })
      let hashArr = []
      reviewDetails.tags && reviewDetails.tags.forEach(r=>{
        hashArr.push(r)
      })
      console.log("--hastags->",JSON.stringify(hashArr))
      let locationArr = []
      let descriptionArr = []
      let titleArr = []
      console.log("--hashListwithCat->",JSON.stringify(hashListwithCat))
      if(s3FileArray!=null && s3FileArray.length==0){
        if(reviewDetails.description){

          descriptionArr.push({index:0,value:reviewDetails.description})
        }
        if(reviewDetails.title){

          titleArr.push({index:0,value:reviewDetails.title})
        }
      }
      s3FileArray.forEach((item,index)=>{
        console.log("item?.uploadImage............."+item.url)
        if(item.url){
          locationArr.push(reviewDetails.location)
          if(reviewDetails.description){

            descriptionArr.push({index:index,value:reviewDetails.description})
          }
          if(reviewDetails.title){

            titleArr.push({index:index,value:reviewDetails.title})
          }
          arr3.push({name:item.url,url:item.url})
        }
        else{
          console.log("item?.uploadImage............."+item.url)
          alert("Error While uploading, retry!!")
          return
        }
      })
      s3Invoices.forEach((item,index)=>{
        console.log("item?.iNVOCIE............."+item.url)
        if(item.url){
          arr4.push({name:item.url,url:item.url})
        }
        else{
          console.log("item?.uploadImage............."+item.url)
          alert("Error While uploading, retry!!")
          return
        }
      })
      console.log("Post API call----");
      console.log("location arrary ....."+JSON.stringify(locationArr))
      console.log("tags ....."+JSON.stringify(reviewDetails.tags))
      let productsArr = []
      reviewDetails.products.forEach((element,index)=>{
        productsArr.push({
          "locationX": 48.16876305474175,
          "locationY": 28.021753186745464,
          "name": element.prdct_attributes.prdct_name,
          "id": element._id,
          "userId": element.userId._id,
          "index": index,
          "brandName": element.prdct_attributes.brand,
          originalPrice: element.prdct_attributes.retail_original_price?element.prdct_attributes.retail_original_price:element.prdct_attributes.original_price,
            discountedPrice: element.prdct_attributes.retail_dsct_price?element.prdct_attributes.retail_dsct_price:element.prdct_attributes.dsct_price,
            productImage: element.prdct_attributes.media[0].url?element.prdct_attributes.media[0].url:'',
          "slideIndex": index
      })
      })
      console.log("products ....."+JSON.stringify(productsArr))
      //----for testing-------
      // this.setState({loading: false});
      //     this.props.updatePostUploadStatus("reset")
      //    this.props.navigation.navigate('HomeTabNavigator');
      //    return
      // console.log("token ....."+JSON.stringify(this.state.token))
      let storeUserId1= storeUserId?storeUserId:shareFeedback?null:props.brand&&props.brand.value.user_id._id?props.brand.value.user_id._id:null 
      console.log("postStoreUserId ....."+JSON.stringify(storeUserId1))
      console.log("selectedService ....."+JSON.stringify(selectedService))
      let query = queryId?queryId:null
      let linkArray =[] 
      if(otherLinks.length>0){
        // otherLinks.map((l,index)=>{
        //   linkArray.push({
        //     data:l,
        //     index:0
        //   })
        // })
        linkArray[0] = {
        ...linkArray[0],
        data: otherLinks,
        index: 0,
      };
      }
      // console.log(JSON.stringify(linkArray))
      // return
      let body = storeUserId1!==''?
      {
        slug:true,
        type: "IMAGE",
        queryId:query,
        description: descriptionArr,
        caption:  titleArr,
        data: [...arr3],
        invoice: [...arr4],
        products: productsArr,
        links: linkArray,
        hashtags: hashArr,//this.state.hashList,
        location: locationArr,
        hashCategory:hashListwithCat,
        storeUserId:storeUserId1,
        mentionPostId:selectedService?selectedService._id:mentionPostId,
        mentionInfId:mentionInfId?mentionInfId:influencerId,
        isPrivate:isPrivate,
        userData : {
          profilePic: {
            name: 'My pICTURE',
            url: 'https://xm-customer.s3.ap-south-1.amazonaws.com/images/16139913872243qF9NbThu.jpg',
          },
          name: reviewDetails.name,
          email: reviewDetails.emailId,
          mobile: reviewDetails.contactNum,
          password: '12345',
          gender: 'Male',
          dob: '1994-08-28',
          address: {
            firstName: 'Balavan',
            lastName: 'Patil',
            mobile: '8197830443',
            line1: 'Patil Nivas',
            line2: 'Basavkalyan',
            landmark: 'Basavkalyan',
            houseNumber: '26/7',
            street: 'Talhbhog',
            city: 'Basavkalyan',
            pinCode: 585327,
            state: 'Karnataka',
            country: 'INDIA',
          },
          isService:shareFeedback?true:false
        },
        influencerData:{
          name: reviewDetails.name,
          shifts_available:["Morning"],
          if_expert: true,//this.state.selectedShifts.length==0?false:true,
          //email: jsonUser.email,
          //password: '123',
          //mobile: jsonUser.mobile,
          latitude: location&&location.lat?location.lat.toString():12.909186,//position.coords.latitude.toString(),
          longitude: location&&location.long?location.long.toString():77.6523536,//position.coords.longitude.toString(),
          category_id: "62ca70388eb8c83476209cc8",
          highest_degree: 'Bachelors',
          description: 'I am a creator',
          years_Active: 1,
          expert_charge: '95000',
          rate_per_hour: 10,
          isService:shareFeedback?true:false
          // instagram_link: {
          //   name: this.state.instagramLink,
          //   url: this.state.instagramLink,
          // },
          // facebook_link: {
          //   name: this.state.facebookLink,
          //   url: this.state.facebookLink,
          // },
          // twitter_link: {
          //   name: this.state.twitterLink,
          //   url: this.state.twitterLink,
          // },
          // linkedin_link: {
          //   name: this.state.twitterLink,
          //   url: this.state.twitterLink,
          // },
          // booking_slots: {
          //   date:
          //     this.state.selectedShifts.map(item =>
          //       this.convertToDateTime(item),
          //     ),
          // },
        }
      }
      :
      {
        slug:true,
        type: "IMAGE",
        queryId:query,
        description: descriptionArr,
        caption:  titleArr,
        data: [...arr3],
        invoice: [...arr4],
        products: productsArr,
        links: linkArray,
        hashtags: hashArr,//this.state.hashList,
        location: locationArr,
        hashCategory:hashListwithCat,
        mentionPostId:mentionPostId?mentionPostId:selectedService._id,
        mentionInfId:mentionInfId?mentionInfId:influencerId,
        isPrivate:isPrivate,
        userData : {
          profilePic: {
            name: 'My pICTURE',
            url: 'https://xm-customer.s3.ap-south-1.amazonaws.com/images/16139913872243qF9NbThu.jpg',
          },
          name: reviewDetails.name,
          email: reviewDetails.emailId,
          mobile: reviewDetails.contactNum,
          password: '12345',
          gender: 'Male',
          dob: '1994-08-28',
          address: {
            firstName: 'Balavan',
            lastName: 'Patil',
            mobile: '8197830443',
            line1: 'Patil Nivas',
            line2: 'Basavkalyan',
            landmark: 'Basavkalyan',
            houseNumber: '26/7',
            street: 'Talhbhog',
            city: 'Basavkalyan',
            pinCode: 585327,
            state: 'Karnataka',
            country: 'INDIA',
          },
          isService:shareFeedback?true:false
        },
        influencerData:{
          name: reviewDetails.name,
          shifts_available:["Morning"],
          if_expert: true,//this.state.selectedShifts.length==0?false:true,
          //email: jsonUser.email,
          //password: '123',
          //mobile: jsonUser.mobile,
          latitude: location&&location.lat?location.lat.toString():12.909186,//position.coords.latitude.toString(),
          longitude: location&&location.long?location.long.toString():77.6523536,//position.coords.longitude.toString(),
          category_id: "62ca70388eb8c83476209cc8",
          highest_degree: 'Bachelors',
          description: 'I am a creator',
          years_Active: 1,
          expert_charge: '95000',
          rate_per_hour: 10,
          isService:shareFeedback?true:false
          // instagram_link: {
          //   name: this.state.instagramLink,
          //   url: this.state.instagramLink,
          // },
          // facebook_link: {
          //   name: this.state.facebookLink,
          //   url: this.state.facebookLink,
          // },
          // twitter_link: {
          //   name: this.state.twitterLink,
          //   url: this.state.twitterLink,
          // },
          // linkedin_link: {
          //   name: this.state.twitterLink,
          //   url: this.state.twitterLink,
          // },
          // booking_slots: {
          //   date:
          //     this.state.selectedShifts.map(item =>
          //       this.convertToDateTime(item),
          //     ),
          // },
        }
      }


      console.log("body..................",body)
      // return
      setLoading(true)
    addReview(body)
      .then(response => {
        setLoading(false)
        if (response.status == 200 || response.status == 201) {
          setShowPopover(true)
          setSubmitSuccess(true)
          setPostId(response.postId)
          setInfEmail(response.postDetails.Data.email)
          setInfId(response.infId)
          console.log("response.postDetails..............",response.postDetails)
          setPostDetails(response.postDetails.Data.postData)
        }else{
          alert(response.message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        // this.setState({addingPost: false, loading: false,uploadingThumbnail:false,uploadingVideo:false});
        console.log(JSON.stringify(error));
        alert(error.message);
        // this.setState({loading: false});
        //this.setState({contExecution:false})
      });
    // this.setState({ addingPost: false, loading: false, uploadingThumbnail: false, uploadingVideo: false });
  }

  const onSearchStore = async (searchKey, flag) => {
    const res = await searchStoreOrBrand(searchKey);
    // console.log(res.Data);
    let arr = [];
    arr.push({ key: 0, text: searchKey, value: searchKey });

    if (res.status === 200 && res.Data) {
      let data = []
      res.Data.map(item => {
        if (flag == 'store' && item.users && item.users.length > 0 && !item.users[0].isBrand) {
          data.push(item)
        }
      })
      //  console.log("stores=>", res.Data);
      const stores = data.map((item, index) => {
        console.log("stores=>", item.users[0].isBrand);
        return { key: index + 1, text: item.name, value: item };
      });
      if (stores.length > 0) {

        setSearchStores([...arr, ...stores]);
      }
    }
    // else {
    //   setSearchStores(arr);
    // }
  };
  const onSearchTag = async (searchKey) => {
    const res = await searchTag(searchKey);
    // console.log(res);
    let arr = [];
    arr.push({ key: 0, text: "#" + searchKey, value: "#" + searchKey });

    if (res.status === 200 && res.Data && res.Data.length > 0) {
      //  console.log("brands=>", res.data.brand);
      const tags = res.Data.map((item, index) => {
        return { key: index + 1, text: item.tag, value: item };
      });
      setSearchTags([...arr, ...tags]);
    } else {
      setSearchTags(arr);
    }
  };
  const onSearchBrand = async (searchKey, flag) => {
    const res = await searchBrandNames(searchKey);
    console.log(res);
    let arr = [];
    arr.push({ key: 0, text: searchKey, value: searchKey });

    if (res.status === 200 && res.data && res.data.brand) {

      const brandNames = res.data.brand.map((item, index) => {
        return { key: index + 1, text: item, value: item };
      });
      setSearchBrands([...arr, ...brandNames]);
    }
    // else {
    //   setSearchStores(arr);
    // }
  };
  const onSearchProducts = async (searchKey) => {
    const res = await getProductsOnBrandNameWithoutToken(searchKey,1000);
    // console.log(res);
    let arr = [];
    // arr.push({ key: 0, text: "#" + searchKey, value: "#" + searchKey });

    if (res.Data && res.Data.length > 0) {
      //  console.log("brands=>", res.Data);
      const products = res.Data.map((item, index) => {
        return { key: index + 1, text: item.prdct_attributes.tagName, value: item };
      });
      products.sort((a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
      setProductsOnBrand([...arr, ...products]);
    } 
    // else {
    //   setProductsOnBrand(arr);
    // }
  };
  const getProductsonBrand = async(name) => {
    console.log(name)
    // return
    setLoading(true)
    const res = await getProductsOnBrandName(name,100)
    setLoading(false)
        console.log(JSON.stringify(res.Data))
        const data = res.Data;
        if (data && data.length > 0) {
          let arr = res.Data
          if (arr && arr.length > 0) {
            arr.map(i => {

              let arr2 = arr.map((item, index) => {
                // console.log(item)
                return { key: index + 1, text: item.prdct_attributes.tagName, value: item };
              });
              // setProducts([...[{key:-1,text:'Select Product',value:null}],...arr2]);
              arr2.sort((a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
              // setProducts(arr2);
              setProductsOnBrand(arr2);
              // setFilterProducts(arr2)
            })
          }
        }
        return
    getProductsOnBrandName(name)
      .then((res) => {
        setLoading(false)
        console.log(JSON.stringify(res.Data))
        const data = res.Data;
        if (data && data.length > 0) {
          let arr = res.Data
          if (arr && arr.length > 0) {
            arr.map(i => {

              let arr2 = arr.map((item, index) => {
                // console.log(item)
                return { key: index + 1, text: item.prdct_attributes.tagName, value: item };
              });
              // setProducts([...[{key:-1,text:'Select Product',value:null}],...arr2]);
              arr2.sort((a, b) => a.value.prdct_attributes.tagName > b.value.prdct_attributes.tagName ? 1 : -1)
              // setProducts(arr2);
              setProductsOnBrand(arr2);
              // setFilterProducts(arr2)
            })
          }
        }
      })
      .catch((err) => console.log("getProductsonBrand  err", err));
  };

  const addLinkAddressName = (text, type, index)=> {
    console.log('Link ---- : ', text, type, index);

    let textInputArr = []
    Object.assign(textInputArr,otherLinks);

    if (type == 'name') {
      textInputArr[index].name = text;
      setOtherLinks(textInputArr)
      // this.setState({textInputArr});
    } else {
      textInputArr[index].url = text;
      setOtherLinks(textInputArr)
      // this.setState({textInputArr});
    }
    console.log(JSON.stringify(otherLinks))
    // let linkArray = [...this.state.linkArray];
    // linkArray[this.state.activeSlide] = {
    //   ...linkArray[this.state.activeSlide],
    //   data: this.state.textInputArr,
    //   index: this.state.activeSlide,
    // };
    // this.setState({linkArray});

    // setTimeout(() => {
    //   console.log('linkArray >> ', linkArray);
    // }, 1000);
  }
  return loading?<div>Procecssing</div>:((isServiceProvider&&location)||!isServiceProvider)?(
    <div className={styles.body}>
      <SubmitPopover2
        showPopover={showPopover}
        success={submitSuccess}
        closePopover={() => setShowPopover(false)}
      />
      {console.log("influencerId",influencerId)}
      {console.log("influencerDetails",influencerDetails)}
      {/* {false&&influencerId&&influencerDetails?<InfluencerHeader influencerId={influencerId} influencerDetails={influencerDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
      :props.brand&&props.shopDetails?<BrandHeader storeUserId={props.brand.value.user_id._id} shopDetails={props.shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
      :storeUserId&&shopDetails?<StoreHeader storeUserId={storeUserId} shopDetails={shopDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
      :mentionInfId&&mentionedInfluencerDetails?<InfluencerHeader influencerId={mentionInfId} influencerDetails={mentionedInfluencerDetails} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
      :infId&&infEmail?<InfluencerHeader influencerId={infId} influencerDetails={{email:infEmail}} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>
      :<IndependentHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>} */}
      {/* {props.brand&&props.shopDetails&&<BrandHeader storeUserId={props.brand.value.user_id._id} shopDetails={props.shopDetails}/>} */}
      {/* {!influencerId&&!props.brand&&!storeUserId&&
      !mentionInfId&&!infId&&<IndependentHeader isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>} */}

      {/* <div className={styles.header}>
        <Link to="/">
          <img
            className={styles.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
        </Link>
        <img
          className={styles.burgerMenu}
          src={isMenuOpen ? Cross : BurgerMenu}
          alt="burger-menu"
          style={{ transform: isMenuOpen ? "rotate(45deg)" : null }}
          onClick={() => setIsMenuOpen((prevState) => !prevState)}
        />
        <div
          className={styles.headerBtn + " " + styles.btn}
          style={{
            transform: isMenuOpen ? "scale(1,1)" : "scale(1,0)",
          }}
          // onClick={}
        >
          <a href="#brand-form">Register Now</a>
        </div>
      </div> */}
      {/* <div className={styles.sectionHead}>
        <div style={{ alignSelf: "center" }}>
        <Link to="/">
          <img
            className={styles1.websiteLogo}
            alt="website-logo"
            src={require("../../assets/logo/logo-light.png")}
          />
          </Link>
        </div>
        <div className={styles.pageHeadings}>
          <span className={styles.pageHeading} ref={h1}></span>{" "}
        </div>
      </div> */}
      {/* <div className={styles.border}>
        <div></div>
      </div> */}
      <Ticker>
      <NewsTicker id="1" title="Download Eyse app and register to avail this facility!" url=" https://play.google.com/store/apps/details?id=com.eyse"/>        </Ticker>
      <div className={styles.sectionForm} id="brand-form">
        <h1>{heading?heading:shareFeedback&&!mentionInfId?"Start a Conversation":shareFeedback&&mentionInfId?"Add Post":"Add Post"}</h1>
        {/* <p>
         Test <span className={styles.accent}>Test</span>Test
        </p> */}
        {submitSuccess ? (
          <div className={styles.signedUp}>
            <img src={CheckCircle} alt="check-mark" />
            <h3>Download Eyse app to explore similar reviews!</h3>
            <a href="https://play.google.com/store/apps/details?id=com.eyse">
            <div className={styles1.storeLogos}>
        <img
            src={require("../../assets/icons/eyse-logo.jpg")}
            alt="playstore-icon"
          />
          <img
            src={require("../../assets/icons/playstore.png")}
            alt="playstore-icon"
          />
          {/* <img
            src={require("../../assets/icons/appstore.png")}
            alt="appstore-icon"
          /> */}
        </div>
        </a>
            {postId ?
              <div className={styles.App}>
                <p>{!shareFeedback?"Guide your friends and family with this review for their next purchase and win rewards!":"Guide your friends and family with this review  and win rewards!"}</p>
                <button onClick={copy}>{!copied ? "Copy link" : "Copied!"}</button>
                {(shareFeedback||(storeUserId && !props.brand))&&<p>Scan QR Code to fetch the link</p>}
                {(shareFeedback||(storeUserId && !props.brand))&&<div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={window.location.host + "/sharereview/true/" + infId + "/" + postId}
                    viewBox={`0 0 256 256`}
                  />
                </div>}
                <div className="Demo__some-network">
                  <p>Share the link on whatsapp</p>
                  {storeUserId?
                  <WhatsappShareButton
                    url={window.location.host + "/tagstorereview/" + infId + "/" + postId+ "/" + storeUserId}
                    title={postDetails && postDetails.caption&&postDetails.caption.length>0?postDetails.caption[0].value:""}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  :null}
                  {/* {!props.brand?
                  <WhatsappShareButton
                    url={window.location.host + "/tagbrandreview/" + infId + "/" + postId+ "/" +  props.brand.value.user_id._id}
                    title={postDetails.caption[0].value}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  :null} */}
                  {/* {console.log("!storeUserId && props.brand .....",!storeUserId && props.brand)}
                  {console.log("postStoreUserId ....."+JSON.stringify(props.brand.value.user_id._id))} */}
                  {!storeUserId && props.brand?
                  <WhatsappShareButton
                    url={window.location.host + "/buyfrombrandreview/" + infId + "/" + postId+ "/" + props.brand.value.user_id._id}
                    title={postDetails && postDetails.caption&&postDetails.caption.length>0?postDetails.caption[0].value:""}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  :null}
                  {shareFeedback?
                  <WhatsappShareButton
                    url={window.location.host + "/sharereview/true/" + infId + "/" + postId}
                    title={postDetails && postDetails.caption&&postDetails.caption.length>0?postDetails.caption[0].value:""}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  :null}
                  {influencerId?
                  <WhatsappShareButton
                    url={window.location.host + "/taginfluencerreview/" + infId + "/"+ postId + "/" + influencerId}
                    title={postDetails && postDetails.caption&&postDetails.caption.length>0?postDetails.caption[0].value:""}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  :null}
                  <p>Share the post details</p>
                  <p>(Default password for new account:12345)</p>
                  <WhatsappShareButton
                    url={APP_URL + "post/" + infId + "/" + postId}
                    title={postDetails && postDetails.caption&&postDetails.caption.length>0?postDetails.caption[0].value:""}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div>

              : null}
          </div>
        ) : (
          <form action="" className={styles.brandForm}>
            <div>
            {value!=null&&!props.brand&&!storeUserId&&!influencerId&&<i>Click on below options to filter form fields</i>}
            </div>
            {value!=null&&!props.brand&&!storeUserId&&!influencerId&&<i>You are a :</i>}
            {!props.brand&&!storeUserId&&!influencerId&&<Form>
            {/* <FormField>
              Selected value: <b>{value}</b>
            </FormField> */}
            {false&&<FormField>
              <Checkbox
                radio
                label='Service Provider'
                name='checkboxRadioGroup'
                value='serviceprovider'
                checked={value === 'serviceprovider'}
                onChange={(e, data) => (setValue(data.value),setValue2(null),setHeading('Add Service'),setHideStore(true))}
              />
            </FormField>}
            <FormField>
              <Checkbox
                radio
                label='Creator/Skill-sharer'
                name='checkboxRadioGroup'
                value='contentcreator'
                checked={value === 'contentcreator'}
                onChange={(e, data) => (setValue(data.value),setValue2(null),setHeading('Add Post'),setHideBrand(true),setHideStore(true))}
              />
            </FormField>
            {false&&<FormField>
              <Checkbox
                radio
                label='Brand Customer'
                name='checkboxRadioGroup'
                value='customer'
                checked={value === 'customer'}
                onChange={(e, data) => (setValue(data.value),setValue2(null),setHeading('Add Review'),setHideBrand(false),setHideStore(false))}
              />
            </FormField>}
            {false&&<FormField>
              <Checkbox
                radio
                label='Hiring Agency'
                name='checkboxRadioGroup'
                value='recruiter'
                checked={value === 'recruiter'}
                onChange={(e, data) => (setValue(data.value),setValue2(null),setHeading('Add Requirement'),setHideBrand(true),setHideStore(true))}
              />
            </FormField>}
          </Form>}
          {value!=null&&!props.brand&&!storeUserId&&!influencerId&&<i>Select an activity :</i>}
          {value=='serviceprovider'&&<Form>
          <FormField>
              <Checkbox
                radio
                label='Add a Service'
                name='checkboxRadioGroup'
                value='addservice'
                checked={value2 === 'addservice'}
                onChange={(e, data) => (setValue2(data.value),setHeading('Add Service'))}
              />
              <Checkbox
                radio
                label='Add a Service with Partner Brand/Store'
                name='checkboxRadioGroup'
                value='addservicewithpartner'
                checked={value2 === 'addservicewithpartner'}
                onChange={(e, data) => (setValue2(data.value),setHeading('Add Service'),setHideBrand(false),setHideStore(false))}
              />
            </FormField>
          </Form>}
          {value=='contentcreator'&&!props.brand&&!storeUserId&&!influencerId&&<Form>
          <FormField>
              <Checkbox
                radio
                label='Share a Skill or Experience'
                name='checkboxRadioGroup'
                value='addexperience'
                checked={value2 === 'addexperience'}
                onChange={(e, data) => (setValue2(data.value),setHeading('Add Experience'))}
              />
              <Checkbox
                radio
                label='Offer a Service'
                name='checkboxRadioGroup'
                value='addcreatorservice'
                checked={value2 === 'addcreatorservice'}
                onChange={(e, data) => (setValue2(data.value),setHeading('Add Service'))}
              />
              <Checkbox
                radio
                label='Start a Conversation'
                name='checkboxRadioGroup'
                value='startcreatorconversation'
                checked={value2 === 'startcreatorconversation'}
                onChange={(e, data) => (setValue2(data.value),setHeading('Start a Thread'))}
              />
            </FormField>
          </Form>}
          {value=='customer'&&<Form>
          <FormField>
              <Checkbox
                radio
                label='Add a Review'
                name='checkboxRadioGroup'
                value='addreview'
                checked={value2 === 'addreview'}
                onChange={(e, data) => (setValue2(data.value),setHeading('Add Review'),setHideBrand(false),setHideStore(false))}
              />
              <Checkbox
                radio
                label='Start a Review Thread'
                name='checkboxRadioGroup'
                value='startreviewthread'
                checked={value2 === 'startreviewthread'}
                onChange={(e, data) => (setValue2(data.value),setHeading('Start a Thread'),setHideBrand(false),setHideStore(false))}
              />
              <Checkbox
                radio
                label='Start a Review Thread without Partner Brand/Store'
                name='checkboxRadioGroup'
                value='startreviewthreadwithpartner'
                checked={value2 === 'startreviewthreadwithpartner'}
                onChange={(e, data) => (setValue2(data.value),setHeading('Start a Thread'),setHideBrand(true),setHideStore(true))}
              />
            </FormField>
          </Form>}
          {value=='recruiter'&&<Form>
          <FormField>
              <Checkbox
                radio
                label='Add a Recruting Position'
                name='checkboxRadioGroup'
                value='addrecruitingposition'
                checked={value2 === 'addrecruitingposition'}
                onChange={(e, data) => (setValue2(data.value),setHeading('Add Requirement'))}
              />
            </FormField>
          </Form>}

          {value2&&<>
            <div>
            <div>
            {toMentionPostDetails && height>width?<div><i htmlFor="">Source Images :</i></div>:null}
            {toMentionPostDetails && height <= width &&
                    <div>
                      <label htmlFor="">Source Images :</label>
                    </div>}
            <div style={{ display:"flex", overflowX: "auto" }}>
            {toMentionPostDetails && toMentionPostDetails.postData && toMentionPostDetails.postData.data && toMentionPostDetails.postData.data.length>0 && toMentionPostDetails.postData.data.map(i=>{
              return (
                // <div className={styles.socialMediaLinks}>
                //   <a
                //   href={i.type=='VIDEO'?i.thumbnail:i.url}
                //   target={"_blank"}
                // ></a>
                // </div>
                // <a href={i.type=='VIDEO'?i.thumbnail:i.url} target="_blank" rel="noopener noreferrer">
                  <img
                    // className={styles.socialMediaLinks}
                    // src={require("../../assets/images/iphone-mockup-sec1-1.png")}
                    src={toMentionPostDetails.postData.type=='VIDEO'?i.thumbnail:i.url}
                    // style={{height:'100px',width:'100px'}}
                    // style={{ maxHeight: height, maxWidth: width }}
                    style={{ maxHeight: height<=width?height/2:height, maxWidth: height<=width?width/2:width, }}
                    // alt="app-homescreen-mockup"
                    // data-aos="fade-up"
                    // data-aos-duration="2000"
                  />
                 //</a>
              )
            })}
            </div>
            <div>
              
            <div>
                  {toMentionPostDetails && height > width ? <div><i htmlFor="">Title :{toMentionPostDetails && toMentionPostDetails.postData && toMentionPostDetails.postData.caption&& toMentionPostDetails.postData.caption.length>0 && toMentionPostDetails.postData.caption[0].value}</i></div> : null}
                  {toMentionPostDetails && height <= width &&
                    <div>
                      <label htmlFor="">Title :{toMentionPostDetails && toMentionPostDetails.postData && toMentionPostDetails.postData.caption && toMentionPostDetails.postData.caption.length>0 && toMentionPostDetails.postData.caption[0].value}</label>
                    </div>}
                </div>
                <div>
                  {toMentionPostDetails && height > width ? <div><i htmlFor="">Description :{toMentionPostDetails && toMentionPostDetails.postData && toMentionPostDetails.postData.desc  && toMentionPostDetails.postData.desc.length>0 && toMentionPostDetails.postData.desc[0].value}</i></div> : null}
                  {toMentionPostDetails && height <= width &&
                    <div>
                      <label htmlFor="">Description :{toMentionPostDetails && toMentionPostDetails.postData && toMentionPostDetails.postData.desc && toMentionPostDetails.postData.desc.length > 0 && toMentionPostDetails.postData.desc[0].value && toMentionPostDetails.postData.desc[0].value}</label>
                    </div>}
                </div>
            </div>
            </div>
              <h3>{product && product.prdct_attributes.prdct_name}</h3>
              <div>
                {false && reviewDetails && reviewDetails.images && reviewDetails.images.map(i => {
                  return (
                    // <div className={styles.socialMediaLinks}>
                    //   <a
                    //   href={i.type=='VIDEO'?i.thumbnail:i.url}
                    //   target={"_blank"}
                    // ></a>
                    // </div>
                    // <a href={i.type=='VIDEO'?i.thumbnail:i.url} target="_blank" rel="noopener noreferrer">
                    <img
                      // className={styles.socialMediaLinks}
                      // src={require("../../assets/images/iphone-mockup-sec1-1.png")}
                      src={i.type == 'VIDEO' ? i.thumbnail : i.url}
                      style={{ height: '100px', width: '100px' }}
                    // alt="app-homescreen-mockup"
                    // data-aos="fade-up"
                    // data-aos-duration="2000"
                    />
                    //</a>
                  )
                })}
              </div>
            </div>
            {influencerPosts.length > 0 && !toMentionPostDetails ? <div>
              <label htmlFor="">Select Review/Service</label>
              <Dropdown
                value={selectedService}
                placeholder={"Select a Review/Service"}
                // disabled={selectedProduct?true:false}
                search
                fluid
                // multiple
                selection
                options={influencerPosts}
                onChange={(event, { value }) =>
                // console.log(value)
                setSelectedService(value)
                  // handleReviewDetails("products", value)
                }
                // onSearchChange={(e) => getProductsonBrand(e.target.value)}
              />
            </div> : null}
            <div>
              <div>
              <div style={{ display:"flex", overflowX: "auto" }}>
            {selectedService && selectedService.data && selectedService.data.map(i=>{
              return i.type!='VIDEO'?(
                // <div className={styles.socialMediaLinks}>
                //   <a
                //   href={i.type=='VIDEO'?i.thumbnail:i.url}
                //   target={"_blank"}
                // ></a>
                // </div>
                // <a href={i.type=='VIDEO'?i.thumbnail:i.url} target="_blank" rel="noopener noreferrer">
                  <img
                    // className={styles.socialMediaLinks}
                    // src={require("../../assets/images/iphone-mockup-sec1-1.png")}
                    src={i.type=='VIDEO'?i.thumbnail:i.url}
                    // style={{height:'100px',width:'100px'}}
                    style={{ maxHeight: height, maxWidth: width }}
                    // alt="app-homescreen-mockup"
                    // data-aos="fade-up"
                    // data-aos-duration="2000"
                  />
                 //</a>
              ):null
            })}
            </div>
          </div>
              {selectedService&&<h3>Selected Review/Sevice: {selectedService.caption[0].value?selectedService.caption[0].value:null}</h3>}
              {selectedService&&<h3>{selectedService.desc[0]?selectedService.desc[0].value:null}</h3>}
            </div>
            {height>width?<div><i htmlFor="images">Add Thumbnail or Images</i></div>:null}
            <div>
              <label htmlFor="images">Add Thumbnail or Images</label>
              {/* <div>
            {selectedProduct && selectedProduct.prdct_attributes.media && selectedProduct.prdct_attributes.media.map(i=>{
              return i.type!='VIDEO'?(
                // <div className={styles.socialMediaLinks}>
                //   <a
                //   href={i.type=='VIDEO'?i.thumbnail:i.url}
                //   target={"_blank"}
                // ></a>
                // </div>
                <a href={i.type=='VIDEO'?i.thumbnail:i.url} target="_blank" rel="noopener noreferrer">
                  <img
                    className={styles.socialMediaLinks}
                    // src={require("../../assets/images/iphone-mockup-sec1-1.png")}
                    src={i.type=='VIDEO'?i.thumbnail:i.url}
                    style={{height:'39px',width:'39px'}}
                    // alt="app-homescreen-mockup"
                    // data-aos="fade-up"
                    // data-aos-duration="2000"
                  />
                </a>
              ):null
            })}
          </div> */}
              <input
                // disabled={selectedProduct?true:false}
                type="file"
                name="images"
                accept="image/*"
                multiple={true}
                onChange={(e) =>
                  handleReviewDetails(e.target.name, e.target.files)
                }
              />
            </div>
            {false && <div>
              <label htmlFor="videos">Add/Edit Product Videos &#40;Optional&#41;</label>
              {/* <div>
            {selectedProduct && selectedProduct.prdct_attributes.media && selectedProduct.prdct_attributes.media.map(i=>{
              return i.type=='VIDEO'?(
                // <div className={styles.socialMediaLinks}>
                //   <a
                //   href={i.type=='VIDEO'?i.thumbnail:i.url}
                //   target={"_blank"}
                // ></a>
                // </div>
                <a href={i.url} target="_blank" rel="noopener noreferrer">
                  <img
                    className={styles.socialMediaLinks}
                    // src={require("../../assets/images/iphone-mockup-sec1-1.png")}
                    src={i.thumbnail}
                    style={{height:'39px',width:'39px'}}
                    // alt="app-homescreen-mockup"
                    // data-aos="fade-up"
                    // data-aos-duration="2000"
                  />
                </a>
              ):null
            })}
          </div> */}
              <input
                disabled
                type="file"
                name="videos"
                accept="video/mp4,video/x-m4v,video/*"
                multiple={true}
                onChange={(e) =>
                  handleReviewDetails(e.target.name, e.target.files)
                }
              />
            </div>}
            {storeUserId ? !shareFeedback&&<div>
                {height > width ?
                  <Dropdown
                    // value={"Store :" +reviewDetails.store.text}
                    placeholder={"Store :" + reviewDetails.store.text}
                    disabled
                  /> :
                  <label htmlFor="store">Store : {reviewDetails.store.text}</label>
                }
            </div> :
              !shareFeedback&&!(hideStore||hideBrand)&&<div>
                <label htmlFor="store">Tag the Eyse partner store if {`${influencerId?"any ":"the "}`}product purchased offline</label>
                {height>width?<div><i htmlFor="store">Tag the Eyse partner store if if {`${influencerId?"any ":"the "}`} product purchased offline</i></div>:null}
                <Dropdown
                  value={reviewDetails.store ? reviewDetails.store : "Search Store"}
                  placeholder={reviewDetails.store ? reviewDetails.store : "Search Store"}
                  // disabled={selectedProduct?true:false}
                  search
                  selection
                  options={searchStores}
                  onChange={(event, { value }) =>
                    handleReviewDetails("store", value)
                  }
                  onSearchChange={(e) => onSearchStore(e.target.value, "store")}
                />
              </div>}
            {false && !props.productId && !props.brand ? <div>
              <label htmlFor="brand">Brand Name</label>
              <Dropdown
                value={reviewDetails.brand ? reviewDetails.brand : "Search Brand"}
                placeholder={reviewDetails.brand ? reviewDetails.brand : "Search Brand"}
                // disabled={selectedProduct?true:false}
                search
                selection
                options={searchBrands}
                onChange={(event, { value }) => {
                  console.log(event)
                  handleReviewDetails("brand", value)
                  getProductsonBrand(value)
                }
                }
                onSearchChange={(e) => onSearchBrand(e.target.value, "brand")}
              />
            </div> : null}
              {!shareFeedback&& !props.productId && props.brand ? <div>
                {height > width ?
                  <Dropdown
                    // value={"Store :" +reviewDetails.store.text}
                    placeholder={"Brand :" + props.brand.text}
                    disabled
                  />
                  :
                  <label htmlFor="brand">Brand : {props.brand.text}</label>}
              </div>
                : null}
              {/* {console.log("productId----------------", productId)} */}
              {/* {console.log("asdasdsdasdasdsd----------------", reviewDetails.products.text)} */}
            {/* {props.productId && !props.brand? <div>
              <label htmlFor="products">Tagged Product : {pro.text}</label>
              <label htmlFor="brand">Brand : {reviewDetails.brand.text}</label>
            </div> : null} */}
            {!props.productId && !props.brand && !props.productsOnBrand &&!(hideStore||hideBrand)  /*&& productsOnBrand && productsOnBrand.length>0*/ ? <div>
              <label htmlFor="products">Search and Tag Brand Product(s)</label>
              <Dropdown
                value={reviewDetails.products}
                placeholder={height>width?"Search and Tag Brand Product(s)":"Tag a Product"}
                // disabled={selectedProduct?true:false}
                search
                fluid
                multiple
                selection
                options={productsOnBrand}
                onChange={(event, { value }) =>
                  handleReviewDetails("products", value)
                }
                onSearchChange={(e) => onSearchProducts(e.target.value)}
              />
            </div> : null}
            {!shareFeedback&& !props.productId && props.productsOnBrand && props.productsOnBrand.length > 0 ? <div>
              <label htmlFor="products">Tag Product(s)</label>
              <Dropdown
                value={reviewDetails.products}
                placeholder={"Tag a Product"}
                // disabled={selectedProduct?true:false}
                search
                fluid
                multiple
                selection
                options={props.productsOnBrand}
                onChange={(event, { value }) =>
                  handleReviewDetails("products", value)
                }
                // onSearchChange={(e) => getProductsonBrand(e.target.value)}
              />
            </div> : null}
            {(!shareFeedback&& (height>width)&& !(hideStore||hideBrand) &&value!='serviceprovider'&&value!='contentcreator'&&value!='recruiter')?<div><i htmlFor="invoice">Add Invoice(for offline purchase from Eyse partner store)</i></div>:null}
            {(!shareFeedback&&(height<=width)) && !(hideStore||hideBrand)&&value!='serviceprovider'&&<div>
              <label htmlFor="invoice">Add Invoice(for offline purchase from Eyse partner store)</label>
              <input
                type="file"
                name="invoice"
                accept="images/*"
                multiple={true}
                onChange={(e) =>
                  handleReviewDetails(e.target.name, e.target.files)
                }
              />
            </div>}
            <div>
              <label htmlFor="title">Title</label>
              <input
                type="text"
                name="title"
                placeholder={value=="serviceprovider"?titlePlaceholder.serviceprovideraddservice
                :value=="contentcreator"&&value2=='addexperience'?titlePlaceholder.contentcreatorshareskill
                :value=="contentcreator"&&value2=='addcreatorservice'?titlePlaceholder.contentcreatorservice
                :value=="contentcreator"&&value2=='startcreatorconversation'?titlePlaceholder.contentcreatorconversation
                :value=="customer"&&value2=='addreview'?titlePlaceholder.customeraddreview
                :value=="customer"&&value2=='startreviewthread'?titlePlaceholder.customeraddreviewthread
                :value=="customer"&&value2=='startreviewthreadwithpartner'?titlePlaceholder.customeraddreviewthread
                :titlePlaceholder.recruiter}//"Enter Title"
                style={{ borderColor: error.title ? colors.red : colors.blue }}
                value={reviewDetails.title}
                onChange={(e) =>
                  handleReviewDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="description">Description</label>
              <textarea
                type="text"
                name="description"
                placeholder={value=="serviceprovider"?descriptionPlaceholder.serviceprovideraddservice
                :value=="contentcreator"&&value2=='addexperience'?descriptionPlaceholder.contentcreatorshareskill
                :value=="contentcreator"&&value2=='addcreatorservice'?descriptionPlaceholder.contentcreatorservice
                :value=="contentcreator"&&value2=='startcreatorconversation'?descriptionPlaceholder.contentcreatorconversation
                :value=="customer"&&value2=='addreview'?descriptionPlaceholder.customeraddreview
                :value=="customer"&&value2=='startreviewthread'?descriptionPlaceholder.customeraddreviewthread
                :value=="customer"&&value2=='startreviewthreadwithpartner'?descriptionPlaceholder.customeraddreviewthread
                :descriptionPlaceholder.recruiter}//"Enter Description"
                style={{ borderColor: error.description ? colors.red : colors.blue ,width:width*.85, height:height/10}}
                value={reviewDetails.description}
                onChange={(e) =>
                  handleReviewDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="">Enter location or landmark of review</label>
              {height>width?<div><i htmlFor="">Enter location or landmark of review</i></div>:null}
              <input
                type="text"
                name="location"
                placeholder="Type Location"
                style={{
                  borderColor: error.location ? colors.red : colors.blue,
                }}
                value={reviewDetails.location}
                onChange={(e) =>
                  handleReviewDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter Name"
                value={reviewDetails.name}
                onChange={(e) =>
                  handleReviewDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.name ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="">Contact Number</label>
              <input
                type="number"
                name="contactNum"
                placeholder="Enter contact number"
                value={reviewDetails.contactNum}
                onChange={(e) =>
                  handleReviewDetails(e.target.name, e.target.value)
                }
                style={{
                  borderColor: error.contactNum ? colors.red : colors.blue,
                }}
              />
            </div>
            <div>
              <label htmlFor="emailId">Email ID</label>
              <input
                type="text"
                name="emailId"
                placeholder="Enter Email ID"
                style={{
                  borderColor: error.emailId ? colors.red : colors.blue,
                }}
                value={reviewDetails.emailId}
                onChange={(e) =>
                  handleReviewDetails(e.target.name, e.target.value)
                }
              />
            </div>
            <div>
              <label htmlFor="infleuncerCategories">Add Category</label>
              <Dropdown
                value={reviewDetails.infleuncerCategories}
                placeholder={"Tag Category"}
                // disabled={selectedProduct?true:false}
                // search
                search
                fluid
                multiple
                selection
                options={infleuncerCategories}
                onChange={(event, { value }) =>
                  handleReviewDetails("infleuncerCategories", value)
                }
              // onSearchChange={(e) => onSearchStore(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="tags">Add hashtag(s)</label>
              {height>width?<div><i htmlFor="tags">Add hashtag(s)</i></div>:null}
              <Dropdown
                // value={reviewDetails.tags}
                placeholder={"Add Tag(s)"}
                // disabled={selectedProduct?true:false}
                fluid
                multiple
                search
                selection
                options={searchTags}
                onChange={(event, { value }) =>
                  handleReviewDetails("tags", value)
                }
                onSearchChange={(e) => onSearchTag(e.target.value)}
              />
            </div>
            {otherLinks.map((link, index) => (
                <div key={index}>
                  <img
                    src={require("../../assets/images/blank_image.png")}
                    alt="blank"
                  />
                  <input
                    placeholder="Enter link name"
                    value={otherLinks[index].name}
                    onChange={(e) => addLinkAddressName(e.target.value,"name", index)}
                    // style={{marginBottom: 16}}
                  />
                  <input
                    placeholder="Enter link address"
                    value={otherLinks[index].url}
                    onChange={(e) => addLinkAddressName(e.target.value,"link", index)}
                    // style={{marginBottom: 16}}
                  />
                </div>
              ))}
              {console.log(JSON.stringify(otherLinks))}
              <div
                onClick={() => {
                  if (otherLinks.length < 5)
                    setOtherLinks((prevState) => [...prevState, {name:"",url:""}]);
                }}
                className={styles2.addLink + " " + styles2.btn}
              >
                <img src={Plus} alt="plus-sign" />
                <p>Add external links</p>
              </div>
              {console.log(JSON.stringify(otherLinks))}
              {<FormField>
              <Checkbox
                toggle
                label='Respond Privately'
                name='isPrivate'
                value={isPrivate}
                checked={isPrivate}
                onChange={(e, data) => (setIsPrivate(i=>!i))}
              />
            </FormField>}
            <div>
              <p style={{ display: showError ? "initial" : "none" }}>
                Error detected-{apiError} Please correct and submit again
              </p>
              <button
                type="submit"
                disabled={loading?true:false}
                style={{
                  backgroundColor: submitBtnDisabled ? "#677890" : "#001E46",
                  cursor: submitBtnDisabled ? "default" : "pointer",
                }}
                // disabled={submitBtnDisabled}
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </div>
            </>}
          </form>
        )}
      </div>
      <div className={styles.border}>
        <div></div>
      </div>

      <Footer />
    </div>
  ):(isServiceProvider&&!location)?<div>Fetching Location!</div>:null;
}

export default AddReview2;